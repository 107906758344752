
import {ALL_ORGANISATION,GET_ORGANISATION_PROFILE, ALL_CATEGORY, TOP_LOADER, ADD_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY, ALL_SUB_CATEGORY, ADD_SUB_CATEGORY, DELETE_SUB_CATEGORY, DELETE_MULTIPLE_SUB_CATEGORY, UPDATE_SUB_CATEGORY, ADD_MANAGE_CODE, ALL_MANAGE_CODE, ALL_TABLE, ADD_TABLE, DELETE_TABLE, UPDATE_TABLE, ALL_ACTIVE_ORDER } from '../constants/content_types';
import cookie from 'js-cookie';
import { notify_error, notify_success } from '../../components/Notify';
import { serverAddress } from '../../constant';
const headers = {
    "Accept": "application/json",
    // "content-type": "multipart/form-data; boundary=----WebKitFormBoundary0SpTFnoX4KjmFVdK",
    'Authorization': `Bearer ${cookie.get('ajwt')}`
}
const OrigHeaders = {
    "Content-Type": "application/json;charset=utf-8",
    'Authorization': `Bearer ${cookie.get('ajwt')}`
}

/*========== Admin Login with email and password ===========*/
export const admin_login = (email, password) => {

    return async (dispatch) => {

        const bodyContent = JSON.stringify({ email: email, password: password });
        const response = await fetch('http://localhost:8000/api/admin/login/', {
            method: "POST",
            headers: headers,
            body: bodyContent
        })
        const json = await response.json();
        dispatch({
            type: "ADMIN_LOGIN",
            payload: { ...json, password: password, email: email }
        });
    }
}

/*========== Admin Logout with valid token ===========*/
export const admin_logout = (token) => {
    return async (dispatch) => {

        const response = await fetch('http://localhost:8000/api/admin/logout/', {
            method: "POST",
            headers: { ...headers, "auth-token": token },
        })
        const json = await response.json();
        dispatch({
            type: "ADMIN_LOGOUT",
            payload: json
        });

    }
}


/*==============TOP BAR LOADING=====================*/
export const top_loader = (flag, progress) => {
    return (dispatch) => {
        dispatch({
            type: TOP_LOADER,
            payload: {
                isActive: flag,
                progress: progress
            }
        })
    }
}


/*==============FETCH ALL THE CATEGORY=====================*/
export const allCategory = () => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const url = "http://localhost:8000/api/admin/category";
        const response = await fetch(url, {
            method: "GET",
            headers: OrigHeaders,
        });
        dispatch(top_loader(true, 70));
        const json = await response.json();
        dispatch({
            type: ALL_CATEGORY,
            payload: json
        });
        dispatch(top_loader(false, 100));
    }
}

/*==============ADD THE CATEGORY=====================*/
export const addCategory = (cate_name) => {

    return async (dispatch) => {
        const bodyContent = JSON.stringify(cate_name);
        const url = "http://localhost:8000/api/admin/category";
        const response = await fetch(url, {
            method: 'POST',
            headers: OrigHeaders,
            body: bodyContent
        });
        const json = await response.json();
        dispatch({
            type: ADD_CATEGORY,
            payload: { ...json, cate_name: cate_name.cate_name }
        })
    }
}

/*==============DELETE THE CATEGORY=====================*/
export const deleteCategory = (cate_code) => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const url = "http://localhost:8000/api/admin/category/" + cate_code;
        const response = await fetch(url, {
            method: "DELETE",
            headers: OrigHeaders,
        });
        dispatch(top_loader(true, 70));
        await response.json();
        dispatch({
            type: DELETE_CATEGORY,
            payload: cate_code
        });
        dispatch(top_loader(false, 100));
    }
}

/*==============UPDATE THE CATEGORY=====================*/
export const updateCategory = (cate_obj) => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const bodyContent = JSON.stringify({ cate_code: cate_obj.cate_code, cate_name: cate_obj.cate_name, status: cate_obj.status });
        const url = "http://localhost:8000/api/admin/category/";
        const response = await fetch(url, {
            method: "PATCH",
            headers: OrigHeaders,
            body: bodyContent
        });
        dispatch(top_loader(true, 70));
        await response.json();
        dispatch({
            type: UPDATE_CATEGORY,
            payload: { cate_code: cate_obj.cate_code, cate_name: cate_obj.cate_name, status: cate_obj.status }
        });
        dispatch(top_loader(false, 100));
    }
}


/*==============ADD THE SUBCATEGORY=====================*/
export const addSubCategory = (subCateObj) => {
    return async (dispatch) => {
        const url = "http://localhost:8000/api/admin/subcategory/";
        const { cate_code, sub_cate_name, sub_cate_price, sub_cate_img, is_diff_qty } = subCateObj;
        const formData = new FormData();
        formData.append('cate_code', cate_code);
        formData.append('sub_cate_name', sub_cate_name);
        formData.append('sub_cate_price', sub_cate_price);
        formData.append('sub_cate_img', sub_cate_img, sub_cate_img.name);
        formData.append('is_diff_qty', is_diff_qty);
        formData.append('sub_status', 'active');

        const response = await fetch(url, {
            method: "POST",
            headers: headers,
            body: formData
        });
        const json = await response.json();
        if (json.status === 'success') {
            notify_success(json.msg);
        } else {
            notify_error(json.msg);
            return false;
        }

        dispatch({
            type: ADD_SUB_CATEGORY,
            payload: { ...json, sub_cate_name, sub_cate_price, sub_cate_img, is_diff_qty }
        })
    }
}

/*==============FETCH ALL THE SUBCATEGORY=====================*/
export const getAllSubCategory = () => {

    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const url = "http://localhost:8000/api/admin/subcategory/";
        const response = await fetch(url, {
            method: "GET",
            headers: OrigHeaders,
        });
        dispatch(top_loader(true, 70));
        const json = await response.json();

        dispatch({
            type: ALL_SUB_CATEGORY,
            payload: json
        })
        dispatch(top_loader(false, 100));
    }
}

/*==============DELETE ALL THE SUBCATEGORY=====================*/
export const deleteSubCategory = (sub_cate_code) => {
    return async (dispatch) => {
        dispatch(top_loader(true, 30));
        const url = "http://localhost:8000/api/admin/subcategory/";
        const contentBody = JSON.stringify({ sub_cate_code });
        dispatch(top_loader(true, 70));
        const response = await fetch(url, {
            method: 'DELETE',
            headers: OrigHeaders,
            body: contentBody,
        });
        dispatch(top_loader(true, 90));
        const json = await response.json();

        dispatch({
            type: DELETE_SUB_CATEGORY,
            payload: json
        });
        dispatch(top_loader(false, 100));
    }
}

/*==============DELETE MULTIPLE ALL THE SUBCATEGORY=====================*/
export const deleteMultipleSubCategory = (cate_code) => {
    return (dispatch) => {

        dispatch({
            type: DELETE_MULTIPLE_SUB_CATEGORY,
            payload: cate_code
        });
    }
}

/*==============UPDATE THE SUBCATEGORY=====================*/
export const updateSubCategory = (subCateObj) => {
    return async (dispatch) => {
        dispatch(top_loader(true, 30));
        const url = "http://localhost:8000/api/admin/subcategory/";
        const contentBody = JSON.stringify(subCateObj);
        dispatch(top_loader(true, 70));
        // console.log(contentBody);
        const response = await fetch(url, {
            method: "PATCH",
            headers: OrigHeaders,
            body: contentBody
        });
        dispatch(top_loader(true, 80));
        const json = await response.json();
        dispatch(top_loader(true, 90));
        if (json.status !== 'success') {
            notify_error(json.msg);
        } else {
            notify_success(json.msg);
            return false;
        }
        dispatch(top_loader(true, 100));

        dispatch({
            type: UPDATE_SUB_CATEGORY,
            payload: subCateObj
        });
    }
}

export const allManageCode = () => {
    return async (dispatch) => {
        const url = "http://localhost:8000/api/admin/managecode/";
        dispatch(top_loader(true, 70));
        const response = await fetch(url, {
            method: "GET",
            headers: OrigHeaders
        });
        dispatch(top_loader(true, 80));
        const json = await response.json();
        dispatch(top_loader(true, 100));
        dispatch({
            type: ALL_MANAGE_CODE,
            payload: json
        })
    }
}

export const addManageCode = (manageCode) => {
    return async (dispatch) => {
        top_loader(true, 30);
        const url = "http://localhost:8000/api/admin/managecode/";
        const contentBody = JSON.stringify(manageCode);
        top_loader(true, 50);
        const response = await fetch(url, {
            method: "POST",
            headers: OrigHeaders,
            body: contentBody
        })
        const json = await response.json();
        top_loader(true, 90);
        dispatch({
            type: ADD_MANAGE_CODE,
            payload: { ...json, manageCode }
        })
        top_loader(false, 100);
    }
}




/*==============FETCH ALL THE TABLE=====================*/
export const allTable = () => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const url = "http://localhost:8000/api/admin/table/";
        const response = await fetch(url, {
            method: "GET",
            headers: OrigHeaders,
        });
        dispatch(top_loader(true, 70));
        const json = await response.json();
        dispatch({
            type: ALL_TABLE,
            payload: json
        });
        dispatch(top_loader(false, 100));
    }
}

/*==============ADD THE TABLE=====================*/
export const addTable = (tblObj) => {

    return async (dispatch) => {
        const bodyContent = JSON.stringify(tblObj);
        const url = "http://localhost:8000/api/admin/table";
        const response = await fetch(url, {
            method: 'POST',
            headers: OrigHeaders,
            body: bodyContent
        });
        const json = await response.json();
        dispatch({
            type: ADD_TABLE,
            payload: { ...json, tblObj }
        })
    }
}

/*==============DELETE THE TABLE=====================*/
export const deleteTable = (table_code) => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const url = "http://localhost:8000/api/admin/table/";
        const contentBody = JSON.stringify({ table_code });
        const response = await fetch(url, {
            method: "DELETE",
            headers: OrigHeaders,
            body: contentBody,
        });
        dispatch(top_loader(true, 70));
        const json = await response.json();
        dispatch({
            type: DELETE_TABLE,
            payload: { table_code, json }
        });
        dispatch(top_loader(false, 100));
    }
}

/*==============UPDATE THE TABLE=====================*/
export const updateTable = (tblObj) => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const bodyContent = JSON.stringify(tblObj);
        const url = "http://localhost:8000/api/admin/table/";
        const response = await fetch(url, {
            method: "PATCH",
            headers: OrigHeaders,
            body: bodyContent
        });
        dispatch(top_loader(true, 70));
        const json = await response.json();
        dispatch({
            type: UPDATE_TABLE,
            payload: { tblObj, json }
        });
        dispatch(top_loader(false, 100));
    }
}


export const allActiveOrder = (table_code) => {
    return async (dispatch) => {

        const url = "http://localhost:8000/api/admin/show/active/order/";
        const contentBody = JSON.stringify({ Table: table_code });
        const response = await fetch(url, {
            method: "POST",
            headers: OrigHeaders,
            body: contentBody
        });
        const json = await response.json();
        dispatch({
            type: ALL_ACTIVE_ORDER,
            payload: json
        })
    }
}



/*==============FETCH ALL ORGANISATIONS=====================*/
export const allOrganisation = () => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const url = serverAddress+"/api/admin/v2/get-all-org";
        const response = await fetch(url, {
            method: "GET",
            headers: OrigHeaders,
        });
        dispatch(top_loader(true, 70));
        const json = await response.json();
        dispatch({
            type: ALL_ORGANISATION,
            payload: json
        });
        dispatch(top_loader(false, 100));
    }
}

/*==============Get Organisation Profile=====================*/
export const getOrganisationProfile = (org_id) => {
    return async (dispatch) => {
        dispatch(top_loader(true, 20));
        const url = serverAddress+"/api/admin/v2/get-org-profile";
        const bodyContent = JSON.stringify({ org_id: org_id});
        const response = await fetch(url, {
            method: "POST",
            headers: OrigHeaders,
            body:bodyContent
        });
        dispatch(top_loader(true, 70));
        const json = await response.json();
        dispatch({
            type: GET_ORGANISATION_PROFILE,
            payload: json
        });
        dispatch(top_loader(false, 100));
    }
}