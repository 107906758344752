import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/index";
import PageHeader from "./PageHeader";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import ActionStatus from "./enquiry/ActionStatus";
import { notify_error, notify_success } from "./Notify";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import "./enquiry/EnquiryTable.css";
import { serverAddress } from "../constant";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import UserAPI from "../utils/api/UserAPI";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import {
  Badge,
  Button,
  Modal,
  Input,
  Table,
  Typography,
  TextField,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import { Select } from "antd";
import Duplicates from "./Duplicates";

const StudentProfile = ({ data, handleRefresh }) => {
  const [key, setKey] = useState("");
  const [handlers, setHandlers] = useState("");
  const { TextArea } = Input;
  const { Title } = Typography;
  const [selectedOrigin, setSelectedOrigin] = useState(
    data?.form_location || ""
  );

  const [activeTransfer, setActiveTransfer] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [goBackToTable, setGoBackToTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userRole, setUserRole] = useState("");

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
    // console.log("42 check");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const originOptions = [
    { value: "get-admission-walk-in", label: "get-admission-walk-in" },
    { value: "college-dekho-walk-in", label: "college-dekho-walk-in" },
    { value: "other-consultant-walk-in", label: "other-consultant-walk-in" },
    { value: "website-pop-up", label: "website-pop-up" },
    { value: "online-query", label: "online-query" },
    { value: "walk-in", label: "walk-in" },
    { value: "ad-page", label: "ad-page" },
    { value: "telephonic", label: "telephonic" },
    { value: "college-dekho", label: "college-dekho" },
    { value: "existing-student", label: "existing-student" },
  ];

  const handleOriginUpdate = async () => {
    try {
      const params = {
        _id: data._id,
        origin: selectedOrigin,
      };

      const response = await OnlineEnquiryAPI.uploadOrigin(params);
      if (response.status) {
        notify_success("Origin Updated successfully");
        setIsModalVisible(false);
        handleRefresh();
      }
    } catch (error) {
      notify_success("Error updating origin:");
    }
  };

  const handleReassign = async (param) => {
    let role;
    if (data.form_location == "walk-in") {
      role = "counsellor";
      if (param.role != "counsellor") {
        notify_error("Only Counsellor can handle these enquiry");
        return;
      }
    } else if (data.form_location != "walk-in") {
      role = "admission";
    }

    const params = {
      _id: data._id,
      type: "one",
      newAssigned: `${param.firstname} ${param.lastname}`,
      user: cookie?.get("name"),
      role: role,
    };

    OnlineEnquiryAPI.assignHandlerOne(params)
      .then((res) => {
        notify_success("Enquiry Successfully Updated");
        setActiveTransfer(false);
      })
      .catch((err) => {
        notify_error(err.message);
      });
  };

  function handleTransfer() {
    const handleTransferLogic = async () => {
      const params = { page: 1, limit: 100 };

      try {
        const result = await UserAPI.getAllUsers(params);
        // console.log("---------data--------", result);

        // Filter users based on "counsellor" or "admission" roles
        const filteredUsers = result.data?.data?.filter(
          (user) =>
            user.role.includes("counsellor") || user.role.includes("admission")
        );

        setHandlers(filteredUsers);
      } catch (error) {
        // console.error("Error fetching users:", error);
      }
    };

    setActiveTransfer((prev) => !prev);
    handleTransferLogic();
  }

  useEffect(async () => {
    const params = { page: 1, limit: 100 };

    await UserAPI.getAllUsers(params)
      .then((result) => {
        const retrievedHandlers = result.data?.data.filter(
          (item) => item.role === "counsellor" || item.role === "admission"
        );
        setHandlers(retrievedHandlers);
        // console.log(retrievedHandlers); // Log the retrieved handlers
      })
      .catch((error) => {
        // console.error("Error fetching handlers:", error);
      });
  }, []);

  useEffect(() => {
    // console.log(handlers)
  }, [handlers]);

  useEffect(() => {
    const role = cookie.get("role"); // Assuming the role is stored in a cookie named "role"
    setUserRole(role);
  }, []);

  const handlePrint = (params) => {
    EnquiryAPI.updateStatus({ _id: params }).then((result) => {
      if (result.data.status) {
        notify_success(result.data.message);
        //   getAllEnquiry({ page: activepage, limit: recperpage });
        // window.open(result?.data?.downloadLink);
        let x = result?.data?.downloadLink;
        const url = new URL(x);
        const path = url.pathname.slice(1);
        setKey(path);
        window.open(
          `${serverAddress}/job_application/preview?file_path=${path}`
        );
      } else {
        notify_error("Failed to Print");
      }
    });
  };

  const handleAssignment = (event, _id) => {
    event.stopPropagation();
    event.preventDefault();

    const userRoles = cookie.get("role") ? cookie.get("role").split(",") : [];

    if (!userRoles.includes("counsellor")) {
      notify_error("Only Counsellors have access to walk-in Enquiries");
      return;
    }

    if (userRoles.includes("counsellor")) {
      EnquiryAPI.updateAssignment({
        _id: _id,
        username: cookie.get("name"),
      })
        .then((result) => {
          // console.log(result);
          if (result.data.status) {
            handleRefresh();
            notify_success(result.data.message);
            // getAllEnquiry({ page: activepage, limit: recperpage });
          } else {
            notify_error("Failed to update");
          }
        })
        .catch((err) =>
          notify_error("Unauthorized Access. Only For Counsellors.")
        );
    }
  };

  function handleStatus(event, id) {
    event.stopPropagation();
    event.preventDefault();
    OnlineEnquiryAPI.updateStatus({
      _id: id,
      username: cookie.get("name"),
    }).then((result) => {
      if (result.data.status) {
        handleRefresh();
        notify_success(result.data.message);
        // getAllEnquiry({ page: activepage, limit: recperpage });
      } else {
        notify_error("Failed to update");
      }
    });
  }

  useEffect(() => {
    // console.log(data);
    // console.log(key);
  }, [key]);

  function getFirstWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords;
  }
  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const handleRowSelection = (e, rowId) => {
    // Check if the clicked row is already selected
    if (selectedRowId === rowId) {
      // If the clicked row is the same as the selected one, navigate back to the table
      setGoBackToTable(true);
    } else {
      // If a different row is clicked, update the selectedRowId state
      setSelectedRowId(rowId);
      setGoBackToTable(false);
      // Your existing logic for handling row selection here
    }
  };

  const handleBackButton = () => {
    if (goBackToTable) {
      // If the flag is set to true, navigate back to the table on the same row
      navigate(`/dashboard/enquiry-table#${selectedRowId}`);
    } else {
      // If no row is selected, navigate back to the lead page
      navigate("/dashboard/enquiry-table");
    }
  };

  // const handlePrint = (params) => {
  //     EnquiryAPI.updateStatus({ _id: params }).then((result) => {
  //       if (result.data.status) {
  //         notify_success(result.data.message);
  //         getAllEnquiry({ page: activepage, limit: recperpage });
  //         // window.open(result?.data?.downloadLink);
  //         let x = result?.data?.downloadLink;
  //         const url = new URL(x);
  //         const path = url.pathname.slice(1);

  //         window.open(
  //           `${serverAddress}/ipem/job_application/preview?file_path=${path}`
  //         );
  //       } else {
  //         notify_error("Failed to Print");
  //       }
  //     });
  //   };

  // const downloadPdf = (params) => {
  //     let x = params;
  //     const url = new URL(x);
  //     const path = url.pathname.slice(1);

  //     window.open(
  //       `${serverAddress}/ipem/job_application/preview?file_path=${path}`
  //     );
  //   };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    const dataSent = {
      mobileNumber: data.mobile_number,
      message: message,
    };
    try {
      await axios.post(
        "https://api-dev.asserit.io/ipem/website/enquiry/whatsapp",
        dataSent
      );
      alert("Message sent successfully!");
      setMessage("");
      handleClose();
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    }
  };

  return (
    <>
      {data && data._id && (
        <div className="spWrapper" style={{ height: "535px" }}>
          <div className="spContent">
            <div className="spContent01">{getFirstWords(data.name)}</div>

            <div className="spContent02">{capitalizeWords(data.name)}</div>
            <div className="spContent03">+91{data.mobile_number}</div>
          </div>

          <div className="spContent1">
            <img
              height={"20px"}
              src="/assets/images/dashboard/calender.png"
            ></img>
            <div className="spContent11">Created at:</div>
            <div className="spContent12">
              {new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(data.created_at))}
            </div>
          </div>

          <div className="spContent2"></div>

          <div className="spContent3">
            <div className="spContent31 mx-3">
              <div className="spContent32">Program :</div>
              <div className="spContent33">{data?.program.toUpperCase()}</div>
            </div>

            {data && data.email && (
              <div className="spContent31 mx-3">
                <div className="spContent32">E-mail :</div>
                <div className="spContent33">{data.email}</div>
              </div>
            )}
            <div className="spContent31 mx-3">
              <div className="spContent32">Mobile Number :</div>
              <div className="spContent33">{data?.mobile_number}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="spContent32">Origin :</div>
              <div className="spContent33">
                {data?.form_location.toUpperCase() || "N/A"}
              </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="spContent32">Enquiry Type :</div>
              <div className="spContent33">{data?.enquiry_type}</div>
            </div>
            {data.enquiry_type !== "walk-in" && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Enquiry Location :</div>
                <div className="spContent33">
                  {" "}
                  {` ${data?.state || "N/A"} ${data?.city || ""}`}
                </div>
              </div>
            )}

            {activeTransfer && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Transfer Enquiry :</div>
                <div className="spContent33">
                  {/* <DropdownButton id="dropdown-basic-button" variant="info" size="medium"  title="Dropdown button">
                    {handlers && handlers.map((user) => (
                      <Dropdown.Item onClick={()=>handleReassign(user)} key={user.id}>{`${user.firstname} ${user.lastname}`}</Dropdown.Item>
                    ))}
                  </DropdownButton> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="info"
                      size="xs"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        backgroundColor: "white",
                        color: "#2B73FF",
                        borderColor: "#2B73FF",
                        border: "2px solid",
                      }}
                    >
                      Transfer To
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {handlers &&
                        handlers.map((user) => (
                          <Dropdown.Item
                            onClick={() => handleReassign(user)}
                            key={user.id}
                            className="transferTo"
                            style={{ backgroundColor: "#fffff", color: "#000" }}
                          >
                            {`${user.firstname
                              .charAt(0)
                              .toUpperCase()}${user.firstname.slice(1)} ${
                              user.lastname
                            }`}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}

            {!activeTransfer &&
              data.form_location == "walk-in" &&
              (data.assignedTo ? (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Assigned To :</div>
                  <div className="spContent33">
                    {" "}
                    {` ${data?.assignedTo.toUpperCase()}  `}
                  </div>
                </div>
              ) : (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Assigned To :</div>
                  <div className="spContent33">
                    {!data.assignedTo && (
                      <>
                        <a
                          className="bg-transparent border-0"
                          style={{
                            color: "#2B73FF",
                            fontWeight: "600",
                            borderBottom: "1px solid #2B73FF",
                          }}
                          onClick={(e) => handleAssignment(e, data._id)}
                        >
                          Assign To Me
                        </a>
                      </>
                    )}
                  </div>
                </div>
              ))}

            {!activeTransfer &&
              data.form_location !== "walk-in" &&
              (data.handled_by ? (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Handled By :</div>
                  <div className="spContent33">
                    {" "}
                    {` ${data?.handled_by.toUpperCase()}  `}
                  </div>
                </div>
              ) : (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Handled By :</div>
                  <div className="spContent33">
                    {data.form_location !== "walk-in" && !data.handled_by && (
                      <>
                        <a
                          className="bg-transparent border-0"
                          style={{
                            color: "#2B73FF",
                            fontWeight: "600",
                            borderBottom: "1px solid #2B73FF",
                          }}
                          onClick={(e) => handleStatus(e, data._id)}
                        >
                          Assign To Me
                        </a>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="spContent4">
            <div className="spContent41">
              {/* onClick={handleOpen} */}
              <a>
                <WhatsAppIcon sx={{ color: "#AAACAD" }} fontSize="large" />
              </a>
            </div>

            <div className="spContent42"></div>

            <div className="spContent43">
              <button
                className="spButton1 py-1 px-2 me-2"
                style={{ border: "none" }}
                onClick={() => handlePrint(data._id)}
                disabled={userRole === "College Dekho"}
              >
                Print Now
              </button>
              <button
                className="spButton1 py-1 px-2 me-2"
                style={{ border: "none" }}
                onClick={handleTransfer}
                disabled={userRole === "College Dekho"}
              >
                Transfer
              </button>
              <button
                className="spButton1 py-1 px-2 me-2"
                style={{ border: "none" }}
                onClick={showModal}
                disabled={userRole === "College Dekho"}
              >
                Update Origin
              </button>
            </div>
          </div>

          {/* Modal component */}
          <Modal
            // title="Update Origin"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <button
                key="cancel"
                className="spButton1 py-1 px-2 me-2"
                style={{ border: "none" }}
                onClick={handleCancel}
              >
                Cancel
              </button>,
              <button
                key="update"
                className="spButton1 py-1 px-2 me-2"
                style={{ border: "none" }}
                onClick={handleOriginUpdate}
              >
                Update
              </button>,
            ]}
          >
            {/* Dropdown for selecting origin */}
            <Select
              defaultValue={selectedOrigin}
              onChange={(value) => setSelectedOrigin(value)}
              style={{ width: "100%" }}
            >
              {originOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Modal>
        </div>
      )}

      <Modal visible={open} onCancel={handleClose} footer={null} centered>
        <div style={{ padding: 24 }}>
          <Title level={4}>Send a Message</Title>
          <TextArea
            placeholder="Message"
            autoSize={{ minRows: 4 }}
            value={message}
            onChange={handleMessageChange}
            style={{ marginTop: 16, marginBottom: 16 }}
          />
          <Button type="primary" onClick={handleSendMessage}>
            Send
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default StudentProfile;
