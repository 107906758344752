import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import EnquiryAPI from '../../utils/api/EnquiryAPI';
import { notify_error, notify_success } from '../Notify';

function FilterModal ({ onOk, onCancel, open }) {
  const [status, setStatus] = useState('');

  return (
    <>

        <Modal
          title='Filter By Status'
          centered
          open={open}
          onCancel={onCancel}
          onOk={() => onOk(status)}
        >

     
          <div className='form-group d-flex flex-column'>
            <label htmlFor='status'>Filter BY Admission Status</label>
            <select id='status' className='status-control' onChange={(e) =>setStatus(e.target.value)}>
              <option value='none'>None</option>
              <option value='admission-taken'>Admission Taken</option>
              <option value='in-process'>In Process</option>
              <option value='not-interested'>Not Interested</option>
            </select>
          </div>

        </Modal>
    </>
  );
}

export default FilterModal;
