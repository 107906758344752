import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CareerStudentProfile from "./CareerProfileInfo1";
import CareerProfileInfo from "./CareerProfileInfo2";
import { notify_error, notify_success } from "../Notify";
import cookie from "js-cookie";
import CareerAPI from "../../utils/api/CareersAPI";

const CareerLeadProfile = () => {

    const { id } = useParams();
  const [data, setData] = useState({});


useEffect(()=>{
  // console.log('hi');
},[])


  useEffect(() => {
    // console.log(id);
    CareerAPI.getOneEnquiry(id).then((res) => {
      // console.log(res);
        setData(res.data.data);
        // console.log(res.data.data);
      })
     .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
    // console.log(id);
  }, []);

    return(

        <div className="container-fluid page-body-wrapper" style={{fontFamily:"Inter"}}>
            <NavBar/>
            <CareerStudentProfile data={data}/>
            <CareerProfileInfo data={data}/>
        </div>

    )
}

export default CareerLeadProfile;