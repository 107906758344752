import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import {
  Container,
  Row,
  Col,
  Image,
  Badge,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";

export default function EnquiryChart(props) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [countOrganic, setCountOrganic] = useState(0);
  const [countWalkIn, setCountWalkIn] = useState(0);
  const [countAdPage, setCountAdPage] = useState(0);
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [countTotalOrganic, setCountTotalOrganic] = useState(0);
  const [countTotalWalkIn, setCountTotalWalkIn] = useState(0);
  const [countTotalAdPage, setCountTotalAdPage] = useState(0);
  const [countTotalEnquiry, setCountTotalEnquiry] = useState(0);

  const [program, setProgram] = useState("total");
  //  const program=props.program || 'ba.llb';
  useEffect(() => {
    // API call for Program Data
    if (program != "total") {
      OnlineEnquiryAPI.dynamicFilter(
        [
          { field: "program", selected: [program] },
          { field: "form_location", selected: ["walk-in"] },
        ],
        1,
        25
      ).then((res) => {
        setCountWalkIn(res.data.total);
        // console.log(res);
      });

      OnlineEnquiryAPI.dynamicFilter(
        [
          { field: "program", selected: [program] },
          { field: "form_location", selected: ["ad-page", "college-dekho"] },
        ],
        1,
        25
      ).then((res) => {
        setCountAdPage(res.data.total);
        // console.log(res);
      });

      OnlineEnquiryAPI.dynamicFilter(
        [
          { field: "program", selected: [program] },
          {
            field: "form_location",
            selected: ["website-pop-up", "online-query", "telephonic"],
          },
        ],
        1,
        25
      ).then((res) => {
        setCountOrganic(res.data.total);
        // console.log(res);
      });
      OnlineEnquiryAPI.dynamicFilter(
        [{ field: "program", selected: [program] }],
        1,
        25
      ).then((res) => {
        setTotalEnquiry(res.data.total);
        // console.log(res);
      });
    } else {
      OnlineEnquiryAPI.dynamicFilter(
        [{ field: "form_location", selected: ["walk-in"] }],
        1,
        25
      ).then((res) => {
        setCountTotalWalkIn(res.data.total);
        // console.log(res);
      });

      OnlineEnquiryAPI.dynamicFilter(
        [{ field: "form_location", selected: ["ad-page", "college-dekho"] }],
        1,
        25
      ).then((res) => {
        setCountTotalAdPage(res.data.total);
        // console.log(res);
      });

      OnlineEnquiryAPI.dynamicFilter(
        [
          {
            field: "form_location",
            selected: ["website-pop-up", "online-query", "telephonic"],
          },
        ],
        1,
        25
      ).then((res) => {
        setCountTotalOrganic(res.data.total);
        // console.log(res);
      });

      OnlineEnquiryAPI.dynamicFilter(
        [
          {
            field: "form_location",
            selected: [
              "website-pop-up",
              "online-query",
              "walk-in",
              "ad-page",
              "telephonic",
              "college-dekho",
            ],
          },
        ],
        1,
        25
      ).then((res) => {
        setCountTotalEnquiry(res.data.total);
        // console.log(res);
      });
    }
  }, [program]);

  useEffect(() => {
    if (program !== "total") {
      const dataValues = [countWalkIn, countAdPage, countOrganic];
      const data = {
        labels: ["Walk-In", "Ad Page", "Organic"],
        datasets: [
          {
            label: "Program Wise data",
            data: dataValues,
            backgroundColor: ["#F5CE85", "#B0C1D4", "#C2E8FF"],
            borderColor: ["#F5CE85", "#B0C1D4", "#C2E8FF"],
            borderWidth: 1,
          },
        ],
      };
      const chartLabels = data.labels.map((label, index) => {
        return `${label} (${dataValues[index]})`;
      });
      data.labels = chartLabels;
      const options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };

      setChartData(data);
      setChartOptions(options);
    } else {
      const dataValues = [
        // countTotalEnquiry,
        countTotalWalkIn,
        countTotalAdPage,
        countTotalOrganic,
      ];
      const data = {
        labels: ["Walk-In", "Ad Page", "Organic"],
        datasets: [
          {
            label: "Total Data",
            data: dataValues,
            backgroundColor: [
              // "rgb(217,217,217)",
              "#F5CE85",
              "#B0C1D4",
              "#C2E8FF",
            ],
            borderColor: [
              // "rgb(217,217,217)",
              "#F5CE85",
              "#B0C1D4",
              "#C2E8FF",
            ],
            borderWidth: 1,
          },
        ],
      };
      const chartLabels = data.labels.map((label, index) => {
        return `${label} (${dataValues[index]})`;
      });
      data.labels = chartLabels;
      const options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };

      setChartData(data);
      setChartOptions(options);
    }
  }, [
    countWalkIn,
    countAdPage,
    countOrganic,
    // countTotalEnquiry,
    countTotalWalkIn,
    countTotalAdPage,
    countTotalOrganic,
    program,
  ]);

  return (
    <>
      <Container
        className="mt-1 pt-3"
        style={{
          marginTop: "200px",
          backgroundColor: "white",
          paddingBottom: "19px",
          boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
        }}
      >
        <Row>
          <Col>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "28px",
                fontWeight: "500",
              }}
            >
              Enquiry Count
            </p>
          </Col>
          <Col>
            <Form.Select
              className="me-4"
              style={{ width: "200px", float: "right" }}
              name="program"
              onChange={(e) => setProgram(e.target.value)}
              value={program}
              aria-label="Default select example"
            >
              <option value="total">Total</option>
              <option value="bba">BBA</option>
              <option value="bca">BCA</option>
              <option value="b.com(h)">B.COM</option>
              <option value="mba">MBA</option>
              <option value="b.ed">B.ED</option>
              <option value="mca">MCA</option>
              <option value="ba.llb">B.A.LLB</option>
              <option value="llb">LLB</option>
              <option value="b.com.llb">B.COM.LLB</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <div className="card  ">
              <Chart type="doughnut" data={chartData} options={chartOptions} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
