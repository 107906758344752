import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Badge,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";
import cookie from "js-cookie";
import { CareerContext } from "../../state/context/CareerProvider";
import CareerAPI from "../../utils/api/CareersAPI";
import CareerCalendar from "./CareerCalendar";

export default function FilterCareerTable() {
  const {
    filter,
    setFilter,
    search,
    SetSearch,
    recperpage,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
  } = React.useContext(CareerContext);
  const [clearDate, setClearDate] = useState(false);

  useEffect(() => {
    // console.log(filter);
    handleApplyFilter();
  }, [filter]);

  const handleClearFilter = () => {
    if (!clearDate) {
      setClearDate(true);
    }
  };

  const handleFilterExport = () => {
    const newData = filterEmptySelected(filter);
    // console.log(newData);

    CareerAPI.downloadDynamicFilter(newData, activepage, recperpage)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link element to trigger the file download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "results.csv");
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove the temporary link element and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error while downloading CSV:", error);
      });
  };

  const filterEmptySelected = (arr) => {
    return arr.filter((obj) => {
      if (
        obj.field === "date" &&
        obj.selected &&
        obj.selected.start !== "" &&
        obj.selected.end !== ""
      ) {
        return true; // Retain the "date" object if start and end values are not empty
      }

      const hasSelectedValues =
        obj.selected &&
        obj.selected.length > 0 &&
        (!Array.isArray(obj.selected) ||
          (Array.isArray(obj.selected) &&
            obj.selected.length > 0 &&
            obj.selected[0] !== null));
      return hasSelectedValues;
    });
  };

  const handleApplyFilter = () => {
    const newData = filterEmptySelected(filter);
    // console.log(newData);

    CareerAPI.dynamicFilter(newData, activepage, recperpage).then((res) => {
      setDate(res.data.data);
      setTotalData(res.data.total);
    });
  };
  useEffect(() => {
    // console.log(data);
  }, [data]);

  const handleSearch = () => {
    CareerAPI.searchQuery({ search: search }, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res.data)
        return;
      }
    );
  };

  return (
    <>
      <Container
        className="py-3 mx-auto"
        style={{
          width: "100%",
          marginTop: "80px",
          backgroundColor: "white",
          // marginBottom: "15px",
          boxShadow: "0px 20px 10px 0px rgba(0, 31, 51, 0.10)",
          paddingLeft: "30px",
          fontFamily: "Inter",
          paddingRight: "40px",
        }}
      >
        {/* <Row>
    <Col className="mx-0">
    <InputGroup className="mb-1">
       <Form.Control
         placeholder="Search ...."
         aria-label="Recipient's username"
         aria-describedby="basic-addon2"
         name="search"
         onChange={(e)=>SetSearch(e.target.value)}
         value={search}
       />
       <Button
         variant="outline-light"
         style={{ backgroundColor: "#2B73FF" }}
         id="button-addon2"
         onClick={handleSearch}
       >
         Go
       </Button>
     </InputGroup>
   </Col>
    </Row> */}

        <Row className="team-member-1 text-start my-2 ">
          <Col className="team-member-1">Filter By Date :</Col>
        </Row>
        
            <CareerCalendar
              filter={filter}
              setFilter={setFilter}
              clearDate={clearDate}
              setClearDate={setClearDate}
            />
          
        <Row className="my-3">
          <Col className="d-flex align-items-center justify-content-center">
            <button
              className="rounded-1 me-2 px-3 py-2"
              style={{
                backgroundColor: "#2B73FF",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
              }}
              onClick={handleClearFilter}
            >
              Clear Filters{" "}
            </button>

            <button
              className="rounded-1 px-3 py-2"
              style={{
                backgroundColor: "#2B73FF",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
              }}
              onClick={handleFilterExport}
            >
              Export{" "}
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
