import React, { useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";

const DELEDFilter = ({ isOpen, handleFilterChange }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleButtonClickProgramCategory = async (category) => {
    if (selectedCategory === category) {
      // Unselect category and call the API for unselection
      //   console.log("check 1");
      setSelectedCategory(null);
      handleFilterChange({ params: {} }); // Assuming empty params to unselect
    } else {
      // Select the new category and call the API for selection
      setSelectedCategory(category);
      //   console.log("check 2");
      const apiParams = {
        program: "DELED",
        batch: category,
      };
      handleFilterChange({ params: apiParams });
    }
  };

  return (
    <Container
      className="py-3 mx-auto"
      style={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "white",
        boxShadow: "0px 20px 10px 0px rgba(0, 31, 51, 0.10)",
        paddingLeft: "30px",
        fontFamily: "Inter",
        paddingRight: "40px",
      }}
    >
      <Row className="team-member-1 text-start my-2">
        <Col>Filter By Batch :</Col>
      </Row>
      <Row className="d-flex row-cols-3">
        {[
          "2013-15",
          "2014-16",
          "2015-17",
          "2016-18",
          "2017-19",
          "2018-20",
          "2019-21",
          "2020-22",
          "2021-23",
        ].map((category) => (
          <Col className="my-1" key={category}>
            <Badge
              bg=""
              onClick={() => handleButtonClickProgramCategory(category)}
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor:
                  selectedCategory === category ? "#2B73FF" : "#F2F2F2",
                color: selectedCategory === category ? "#FFFFFF" : "#2B73FF",
              }}
            >
              {category}
            </Badge>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DELEDFilter;
