import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TextEditor from "./QuillEditor";
import { notify_error, notify_success } from "./Notify";
import cookie from "js-cookie";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import { format } from "date-fns";
import { Button } from "antd";
import CsvUploader from "./CsvUploader";

const LeadRemarks = ({ data }) => {
  const [remarkData, setRemarkData] = useState("");
  const [allData, setAllData] = useState(data);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
      const role = cookie.get("role");  
      setUserRole(role);
   
    setAllData(data);

  }, [data]);

  console.log(userRole)



 

  const handleRemarks = (params) => {
    // // console.log(params);
    if (allData && allData._id) {
      if (params === "") {
        // // console.log("none");
        return;
      }
      const data = {
        _id: allData?._id,
        remark: params,
        handler: cookie.get("name"),
      };
      // // console.log(data);
      // // console.log("click");
      EnquiryAPI.updateAdmissionRemarks(data)
       .then((result) => {
          // // console.log(result);
          if (result.status) {
            notify_success("admission remarks updated");
            setTimeout(()=>{

              window.location.reload();
            },500)
          } else {
            notify_error("admission remarks Could not be updated");
          }
        })
       .catch((e) => {
          notify_error(e.message);
        });
    } else {
      return;
    }
  };

  return (
    <div className="lead-remarks-new mx-3"  >
    
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
        style={{maxWidth:"100%"}}
      >
        <Tab 
        
          eventKey="home"
          title={
            <>
              <img
                className="px-2"
                src="/assets/images/dashboard/remark.png"
                alt="Tab Title"
              />
              <span className="tab-heading">Remarks</span>
            </>
          }
        >
          <div className="mx-3" style={{ maxWidth: "100%" }}>
            {userRole !== "College Dekho" && (
              <TextEditor
                style={{ maxWidth: "100%" }}
                remarkData={remarkData}
                setRemarkData={setRemarkData}
                handleRemarks={handleRemarks}
              />
            )}
          </div>

          {/*  */}
          <div>
            <section class="gradient-custom-5" style={{fontFamily:"Inter", overflowY:"scroll" , height:"380px" , maxWidth:"100%"}} id="table-container">
              <div class=" py-5">
                <div class="main-timeline-5 mx-5">
                  {data &&
                    data._id &&
                    data.remarks.slice().reverse().map((remark, index) => {
                      return (
                        <div key={index} class="timeline-5 right-5">
                          <div class="card">
                            <div class="card-body p-4">
                              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                <h5>Enquiry updated</h5>
                                <h5 style={{textTransform: "capitalize"}}>Handled by: {remark.handler}</h5>
                              </div>
                              <span class="small text-muted">
                                <i class="fas fa-clock me-1"></i>
                                {format(new Date(remark.createdAt), "dd/MM/yyyy")}
                              </span>
                              <p class="mt-2">{remark.remark}</p>

                            </div>
                          </div>
                          
                        </div>
                      );
                    })}
                </div>
                </div>
                </section>
                {userRole !== "College Dekho" && <CsvUploader fileId={data._id} />}
          </div>
        </Tab>
      </Tabs>


    </div>
  );
};

export default LeadRemarks;