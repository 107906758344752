import { data } from "autoprefixer";
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import LawAPI from "../../utils/api/LawAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
// import { EnquiryContext } from "../../state/context/EnquiryProvider";
// import {  Pagination} from "antd";
// import {
//   PlusCircleOutlined,
//   RetweetOutlined,
//   MailOutlined,
// } from "@ant-design/icons";​
import moment from "moment";
import { serverAddress } from "../../constant";
import { notify_error, notify_success } from "../Notify";
import { verify } from "crypto";
import UserAPI from "../../utils/api/UserAPI";
import { useNavigate } from "react-router-dom";
import { LawContext } from "../../state/context/LawProvider";
import FeedbackAPI from "../../utils/api/FeedbackAPI";
import { FeedbackContext } from "../../state/context/FeedbackProvider";
import FilterFeedbackTable from "./FilterFeedbackTable";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const FeedbackTable = () => {
  const navigate = useNavigate();

  const {
    applyFilter,
    filter,
    setFilter,
    recperpage,
    handlePageChange,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
    activeFeedback,
    setActiveFeedback,
    search,
    SetSearch,
  } = React.useContext(FeedbackContext);
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  //   const [recperpage, SetRecPerPage] = useState(25);
  //   const [activepage, SetActivePage] = useState(1);
  //   const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  //   const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const filterPanelRef = useRef(null);
  useEffect(() => {
    top_loader(true, 45);
    getAllFeedback({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);
  //   function applyFilter(event) {
  //     SetRecPerPage(event.target.value);
  //     getAllLaw({ page: 1, limit: event.target.value });
  //     SetActivePage(1);
  //   }

  const getAllFeedback = (params) => {
    setLoading(true);
    FeedbackAPI.getAllStudentFeedback(params).then((result) => {
      // console.log(result);
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleRefresh = () => {
    // Clear search
    // SetSearch("");
    // setIsSearch(false);

    // Clear filters
    setFilter([]);
    // setIsFilter(false);
    // filterEmptySelected();

    // Clear Checkbox
    // setSelectedRows([]);

    // Fetch data again
    getAllFeedback({ page: activepage, limit: recperpage });
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };
  //   function handlePageChange(page) {
  //     SetActivePage(page);
  //     getAllLaw({ page: page, limit: recperpage });
  // }

  const handleSearch = () => {
    const currentSearch = search; // Store the current value of search
    FeedbackAPI.searchQuery({ search: currentSearch }, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
      }
    );
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);
  
    // If the search field is emptied, reload all records
    if (value.trim() === '') {
      getAllFeedback({ page: activepage, limit: recperpage });
    }
  };
  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" , display:"block" }}>
                  <InputGroup className="my-2" style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In Law Conference Table"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0 "
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    height:"100%",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <FilterAltOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-2"
                        cursor="pointer"
                        onClick={toggleFilterPanel}
                      />
                      <div
                        style={{
                          width: "1px",
                          height: "60px",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-2"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="maintable "
                id="table-container"
                style={{ height: "900px" }}
              >
                <table className="col-md-12 enquiryTable px-3">
                  {activeFeedback === "student_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>COURSE</th>
                        <th>BATCH</th>
                        <th>CREATED AT</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "faculty_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Department</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "employer_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name </th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "alumni_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Batch</th>
                        <th>Course</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}

                  {activeFeedback === "parent_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name(Parent)</th>
                        <th>Name(Ward)</th>
                        <th>Course</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "student_satisfaction" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name(Parent)</th>
                        <th>Name(Ward)</th>
                        <th>Course</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                            {item.feedbackType === "student_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=student_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item?.name}`}</td>
                                <td>{item?.course?.toUpperCase()}</td>
                                <td>{item?.batch}</td>
                                <td className="d-flex flex-column">
                                  {item?.created_at.slice(0, 10)}
                                </td>
                              </tr>
                            )}
                            {item.feedbackType == "faculty_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=faculty_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item.name}`}</td>
                                <td>{item.email}</td>
                                <td>{item.department}</td>
                                <td>{item.created_at.slice(0, 10)}</td>
                              </tr>
                            )}
                            {item.feedbackType == "employer_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=employer_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${
                                  item.name_of_organization || item.name
                                }`}</td>
                                <td>{item.designation}</td>
                                <td>{item.email}</td>
                                <td>{item.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}

                            {item.feedbackType == "alumni_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=alumni_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item.name}`}</td>
                                <td>{item.email}</td>
                                <td>{item.batch}</td>
                                <td>{item.course}</td>
                                <td>{item.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}

                            {item.feedbackType == "parent_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=parent_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item.parent_name}`}</td>
                                <td>{item.name}</td>
                                <td>{item.course}</td>
                                <td>{item.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}
                            {item.feedbackType == "student_satisfaction" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=student_satisfaction`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item?.parent_name || 'N/A'}`}</td>
                                <td>{item?.name ||   'N/A'}</td>
                                <td>{item?.course  || 'N/A'}</td>
                                <td>{item?.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}
                          </>
                        );
                      })}
                      

                    {data.length == "0" && (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <h1 className="text-muted">NO DATA</h1>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="tableEnd"></div>
              <div className="col-md-12 form-inline py-2 tableFooter">
                <div className="row align-items-center  pageSet">
                  <div className="col-md-4 d-flex gap-2 align-baseline">
                    <label className="control-label col-md-4" style={{fontWeight:"700", paddingTop:"5px", paddingLeft:"25px"}}>
                      Rows per page :
                    </label>
                    <Form.Select
                      className="form-control "
                      onChange={(event) => SetRecPerPage(event.target.value)}
                      //   onChange={(event) => applyFilter(event)}
                      value={recperpage}
                      style={{border:"solid", borderWidth:"thin", color:"#000"}}
                    >
                      {/* <option value="5">5</option> */}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-7 text-end">
                    <Pagination
                      total={totalData}
                      showSizeChanger={false}
                      size="small"
                      // showQuickJumper
                      pageSize={recperpage}
                      onChange={(page, pageSize) => {
                        SetActivePage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "0",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "100vh",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 0.3s ease-in-out",
        }}
      >
        <FilterFeedbackTable />
      </div>
    </>
  );
};
export default FeedbackTable;
