import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { notify_error, notify_success } from "../Notify";
import FeedbackAPI from "../../utils/api/FeedbackAPI";
import { Col, Container, Row } from "react-bootstrap";
import LeadFeedbackProfile from "./LeadFeedbackProfile";
import FeedbackInfoProfile from "./FeedbackInfoProfile";
function DetailProfile() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get('feedbackType');
    const {id}=useParams();
    const [data,setData]=useState();



    useEffect(() => {
        if(value=='student_feedback'){
            FeedbackAPI.getOneStudentFeedback(id).then((res) => {
                // console.log(res);
                  setData(res.data.data[0]);
                  // console.log(res.data.data[0]);
                })
               .catch((err) => {
                  notify_error(err.message || "Something Went Wrong");
                });
              // console.log(id);
        }
        if(value=='faculty_feedback'){
            FeedbackAPI.getOneFacultyFeedback(id).then((res) => {
                // console.log(res);
                setData(res.data.data[0]);
                // console.log(res.data.data[0]);
                })
               .catch((err) => {
                  notify_error(err.message || "Something Went Wrong");
                });
              // console.log(id);
        }
        if(value=='employer_feedback'){
            FeedbackAPI.getOneEmployerFeedback(id).then((res) => {
                // console.log(res);
                setData(res.data.data[0]);
                // console.log(res.data.data[0]);
                })
               .catch((err) => {
                  notify_error(err.message || "Something Went Wrong");
                });
              // console.log(id);
        }
        if(value=='alumni_feedback'){
          FeedbackAPI.getOneAlumniFeedback(id).then((res) => {
              // console.log(res);
              setData(res.data.data[0]);
              // console.log(res.data.data[0]);
              })
             .catch((err) => {
                notify_error(err.message || "Something Went Wrong");
              });
            // console.log(id);
      }
      if(value=='parent_feedback'){
        FeedbackAPI.getOneParentFeedback(id).then((res) => {
            // console.log(res);
            setData(res.data.data[0]);
            // console.log(res.data.data[0]);
            })
           .catch((err) => {
              notify_error(err.message || "Something Went Wrong");
            });
          // console.log(id);
    }
    if(value=='student_satisfaction'){
      FeedbackAPI.getOneStudentSatisfaction(id).then((res) => {
          // console.log(res);
          setData(res.data.data[0]);
          // console.log(res.data.data[0]);
          })
         .catch((err) => {
            notify_error(err.message || "Something Went Wrong");
          });
        // console.log(id);
  }
    
      }, []);


    return (
      <>
      <div className="container-fluid page-body-wrapper">
      <NavBar/>

      <LeadFeedbackProfile data={data} />
      <FeedbackInfoProfile data={data}/>
      </div>
      </>
  )
}

export default DetailProfile
