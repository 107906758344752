// Main Server CMS Dashboard
//  export const serverAddress = "https://api.assertit.io/ipem";
export const serverAddress = "https://server.assertit.io/ipem";

// Dev Server CMS Dashboard
// export const serverAddress = "https://api-dev.assertit.io/ipem";

// Local Server

// export const serverAddress = "http://localhost:4001/ipem";

// export const serverAddress = "https://api-dev.assertit.io/ipem";

export const publicAddress =
  "https://api.assertit.io/ipem/organisation/v2/preview?file_path=";
export const ALL_CATEGORY = "ALL_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ALL_SUB_CATEGORY = "ALL_SUB_CATEGORY";
export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY";
export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";
export const DELETE_MULTIPLE_SUB_CATEGORY = "DELETE_MULTIPLE_SUB_CATEGORY";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
export const ALL_MANAGE_CODE = "ALL_MANAGE_CODE";
export const ADD_MANAGE_CODE = "ADD_MANAGE_CODE";
export const ALL_TABLE = "ALL_TABLE";
export const ADD_TABLE = "ADD_TABLE";
export const DELETE_TABLE = "DELETE_TABLE";
export const UPDATE_TABLE = "UPDATE_TABLE";
export const ALL_ACTIVE_ORDER = "ALL_ACTIVE_ORDER";
export const TOP_LOADER = "TOP_LOADER";
/* -----Organisation------- */
export const ALL_ORGANISATION = "ALL_ORGANISATION";
export const GET_ORGANISATION_PROFILE = "GET_ORGANISATION_PROFILE";
