import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { notify_error, notify_success } from "../Notify";
import cookie from "js-cookie";
import IecegnProfile from "./IecegnProfile";
import IecegnInfo from "./IecegnInfoProfile";
import IecegnAPI from "../../utils/api/IecegnAPI";

const IecegnLeadProfile = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();





  useEffect(() => {
    // console.log(id);
    IecegnAPI.sendLinkIecegn(id).then((res) => {
      // console.log(res);
        setData(res.data.data);
        // console.log(res.data.data);
      })
     .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
    // console.log(id);
  }, []);



  return (
    <div className="container-fluid page-body-wrapper" style={{ fontFamily: "Inter", position: "relative" }}>
      <NavBar />
      {/* {data && data._id} */}
      {/* <StudentProfile data={data} />
      <div className="page-body-wrapper1">
        <ProgressBar data={data}  />
        <LeadRemarks data={data} />
      </div> 
           */}
           <div className="" style={{ top: "128px", position: "absolute", left: "-27px", zIndex: "90" }}>
           <button className="" style={{ border: "none", background: "transparent", borderRadius: "50%" }}
             onClick={(e) => {
               e.preventDefault();
               e.stopPropagation();
               navigate('/dashboard/iecegn-table')
               // setIsBack(true);
               // handlePageChange(activepage);
             }}
           >
             <img width={"36px"} height={"36px"} src="/assets/images/dashboard/back-button.png" alt="Back" />
           </button>
         </div>
      <IecegnProfile data={data}/>
      <IecegnInfo data={data}/>
 
    </div>
  );
};

export default IecegnLeadProfile;




