import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';
const JobFairApi = {

    // get company-registration details
    getallCompanyRegistration: (params) => {
        // let url = serverAddress + `/api/job_application/get-all?page=${params?.page}&limit=${params?.limit}`;
        let url = serverAddress + `/jobfair/registration/company/get-all?page=${params?.page}&limit=${params?.limit}`;

        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },


    getAllStudentRegistration : (params)=>{
        let url = serverAddress + `/jobfair/registration/ipem-student/get-all?page=${params?.page}&limit=${params?.limit}`;

        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },


    getExternalStudentRegistration : (params)=>{
        let url =  serverAddress +`/jobfair/registration/external-student/get-all?page=${params?.page}&limit=${params?.limit}` ;

        return axios.get(url,{
            header:{
                'Content-Type':'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            }
        })
    },


    SearchIpemStudent : (params,page,limit)=>{

        let url = serverAddress + `/jobfair/registration/ipem-student/search?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${cookie.get("ajwt")}`,
            },
        });
    },
    SearchExternalStudent : (params,page,limit)=>{

        let url = serverAddress + `/jobfair/registration/external-student/search?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${cookie.get("ajwt")}`,
            },
        });
    },
    SearchJobfairCompany : (params,page,limit)=>{

        let url = serverAddress + `/jobfair/registration/company/search?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${cookie.get("ajwt")}`,
            },
        });
    },

    
    ExportexternalStudent:()=>{
    let url = serverAddress + `/jobfair/registration/external-student/export/data`;
    return axios.get(url,{
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
    });

},
ExportIpemStudent:()=>{
    let url = serverAddress + `/jobfair/registration/ipem-student/export/data`;
    return axios.get(url,{
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
    });

},

ExportCompany:()=>{
    let url = serverAddress + `/jobfair/registration/company/export`;
    return axios.get(url,{
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
    });

},



confirmPaymentLaw:(candidateId,transactionId)=>{
    // let url= `${serverAddress}/api/law/success/?_id=${params}`;
    let url = `${serverAddress}/jobfair/registration/external-student/updatePaymentStatus/${candidateId}`;
    return axios.put(
        url,
        {
            // Include the transactionId in the request body
            transactionId: transactionId
        },
        {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${cookie.get('ajwt')}`
            },
        }
    );
}, 

    
};

export default JobFairApi;
