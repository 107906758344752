import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  DatePicker,
  Tooltip,
  Select
} from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import { notify_error, notify_success } from './Notify';
import AlumniApi from '../utils/api/AlumniAPI';

const { Option } = Select;

const CreateAlumni = ({ open, setOpen, course }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isFormValidate, setFormValidate] = useState(false);

  const handleDateChange = (date, dateString) => {
    setnewAlumni({ ...newAlumni, dob: dateString });
  };

  const [newAlumni, setnewAlumni] = useState({
    name: "",
    email_address: "",
    mobile_number: "",
    profile_photo: "",
    program: course,
    dob: "",
    company: "",
    designation: "",
    city: "",
    state: "",
    batch: "",
  });

  const handleEnquiryCancel = () => {
    setOpen(false);
  };

  const handleEnquiryOk = () => {
    setConfirmLoading(true);
    validateEnquiryFormData();
    AlumniApi.createAlumni(newAlumni).then((result) => {
      if (result.data.status) {
        notify_success(result.data.message);
        setOpen(false);
        setConfirmLoading(false);
        resetEnquiryFormData();
        window.location.reload();
      } else {
        notify_error(result.data.message); // Show an error message using notify_error
        setConfirmLoading(false);
      }
    });
  };

  const resetEnquiryFormData = () => {
    setnewAlumni({
      name: "",
      email_address: "",
      mobile_number: "",
      profile_photo: "",
      program: "",
      dob: "",
      company: "",
      designation: "",
      city: "",
      state: "",
      batch: "",
    });
  };

  const handleOnEnquiryChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile_number') {
      if (/^\d*$/.test(value)) {
        setnewAlumni({ ...newAlumni, [name]: value });
      }
    } else {
      setnewAlumni({ ...newAlumni, [name]: value });
    }
  };

  const validateEnquiryMobileNumber = (mobileNumber) => {
    const indianRegex = /^[6-9]\d{9}$/;
    if (indianRegex.test(mobileNumber)) {
      return true;
    } else {
      return false;
    }
  };

  const validateEnquiryFormData = () => {
    let check = 0;

    if (check !== 1) {
      if (validateEnquiryMobileNumber(newAlumni.mobile_number)) {
        setFormValidate(true);
      } else {
        notify_error("Please enter a valid mobile number");
      }
    }
  };

  const handleBatchChange = (value) => {
    setnewAlumni({ ...newAlumni, batch: String(value) });
  };

  return (
    <Modal
      visible={open}
      footer={null}
      onCancel={handleEnquiryCancel}
      style={{
        marginBlock: "auto",
        paddingTop: "20px",
        borderRadius: "44px",
        fontFamily: "Inter",
      }}
    >
      <div className="row">
        <h2
          style={{
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "32px",
            lineHeight: "38px",
            fontWeight: "700",
          }}
        >
          New Alumni Form
        </h2>
      </div>
      <div className="row">
        <div className="mb-3 col-md-6 mx-auto bg-transparent">
          <div className="form-group">
            <label htmlFor="name">Name<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              className="form-control border-secondary"
              id="name"
              name="name"
              placeholder="Enter name"
              onChange={handleOnEnquiryChange}
              value={newAlumni.name}
            />
            <span id="show_name" className="err">
              Please Enter Name
            </span>
          </div>

          <div className="form-group">
            <label htmlFor="email">Email<span style={{color:"red"}}>*</span></label>
            <input
              type="email"
              className="form-control border-secondary"
              id="email"
              name="email_address"
              placeholder="Enter email"
              onChange={handleOnEnquiryChange}
              value={newAlumni.email_address}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              title="Please enter a valid email address"
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Mobile<span style={{color:"red"}}>*</span></label>
            <input
              type="tel"
              className="form-control border-secondary"
              id="mobile_number"
              name="mobile_number"
              placeholder="Mobile number"
              onChange={handleOnEnquiryChange}
              value={newAlumni.mobile_number}
              pattern="[0-9]*"  // Allow only numeric characters
              maxLength={10}    // Set the maximum length to 10 characters
              title="Please enter a valid 10-digit phone number"
            />
            <span id="show_mobile_number" className="err">
              Please Enter Mobile Number
            </span>
          </div>

          <div className="form-group">
            <label htmlFor="program">Program<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              className="form-control border-secondary"
              id="program"
              name="program"
              placeholder="Enter program"
              onChange={handleOnEnquiryChange}
              value={newAlumni.program}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth<span style={{color:"red"}}>*</span></label>
            <DatePicker
              className="form-control border-secondary"
              id="dob"
              name="dob"
              value={newAlumni.dob ? moment(newAlumni.dob) : null}
              onChange={handleDateChange}
            />
          </div>
        </div>

        <div className="mb-3 col-md-6 mx-auto bg-transparent">
          <div className="form-group">
            <label htmlFor="company">Company<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              className="form-control border-secondary"
              id="company"
              name="company"
              placeholder="Enter company"
              onChange={handleOnEnquiryChange}
              value={newAlumni.company}
            />
          </div>

          <div className="form-group">
            <label htmlFor="designation">Designation<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              className="form-control border-secondary"
              id="designation"
              name="designation"
              placeholder="Enter designation"
              onChange={handleOnEnquiryChange}
              value={newAlumni.designation}
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">City<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              className="form-control border-secondary"
              id="city"
              name="city"
              placeholder="Enter city"
              onChange={handleOnEnquiryChange}
              value={newAlumni.city}
            />
          </div>

          <div className="form-group">
            <label htmlFor="state">State<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              className="form-control border-secondary"
              id="state"
              name="state"
              placeholder="Enter state"
              onChange={handleOnEnquiryChange}
              value={newAlumni.state}
            />
          </div>

          <label htmlFor="batch">Batch<span style={{ color: "red" }}>*</span></label>
          <Select
            className="form-control border-secondary"
            id="batch"
            name="batch"
            placeholder="Select year"
            onChange={handleBatchChange}
            value={newAlumni.batch}
          >
            {Array.from({ length: 50 }, (_, index) => (
              <Option key={index} value={moment().year() - index}>
                {moment().year() - index}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="row">
        <div className="d-flex gap-3 align-items-center justify-content-center">
          <div className="">
            <button
              onClick={handleEnquiryCancel}
              style={{
                backgroundColor: "white",
                borderRadius: "3px",
                color: "black",
                fontFamily: "Inter",
                border: "2px solid",
                borderRadius: "12px",
                borderColor: "#C3BDBD",
                fontSize: "15px",
                padding: "6px 40px",
              }}
            >
              Cancel
            </button>
          </div>

          <div className="">
            <button
              className="py-2"
              onClick={handleEnquiryOk}
              style={{
                backgroundColor: "#2B73FF",
                borderRadius: "3px",
                color: "white",
                fontFamily: "Inter",
                border: "none",
                borderRadius: "12px",
                fontSize: "15px",
                fontWeight: "700",
                paddingInline: "40px",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAlumni;
