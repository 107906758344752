import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import UserAPI from "../../utils/api/UserAPI";
import LawAPI from "../../utils/api/LawAPI";
import { format } from "date-fns";
import { notify_error, notify_success } from "../Notify";
import { Pagination, Modal } from "antd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { tr } from "date-fns/locale";
import cookie from "js-cookie";
import Select from "react-select";
// import makeAnimated from "react-select/animated";

const NewCreateUser = () => {
  const dispatch = useDispatch();
  // const {filter,setFilter,search,SetSearch,recperpage,SetRecPerPage,activepage,SetActivePage,totalData,setTotalData,data,setDate} =React.useContext(LawContext);

  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [search, SetSearch] = useState();
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [changeRoleOpen, setChangeRoleOpen] = useState(false);
  const [isFormValidate, setFormValidate] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [updatedUserRole, setUpdatedUserRole] = useState({
    role: [],
    feature_access: [],
  });

  const [allRoles, setallRoles] = useState();
  const [enquiry, setEnquiry] = useState({
    role: [],
    username: "",
    firstname: "",
    lastname: "",
    password: "",
    feature_access: [], // Initialize feature_access as an empty array
  });

  const [showAddRoleopen, setshowAddRoleopen] = useState(false);
  const [newRole, setNewRole] = useState({
    roleName: "",
    feature_access: [],
  });

  // let userRoles,userFeatures;

  // if (cookie.get("role") !== undefined) {
  //   // Split the string and join with ', ' if it's not undefined
  //   userRoles = cookie.get("role").split(',').join(', ');
  // } else {
  //   // Handle the case where the "role" cookie is not set
  //   userRoles = "Default Role";
  // }

  // if(cookie.get("feature") !== undefined){
  //   userFeatures = cookie.get("feature").split(',').join(',');
  // }else {
  //   userFeatures = "default feature";
  // }

  useEffect(() => {
    top_loader(true, 45);
    getAllUsers({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllUsers({ page: activepage, limit: recperpage });
    getAllRoles();
  }, [recperpage]);

  const validateFormData = () => {
    let check = 0;
    Object.entries(enquiry).forEach(([key, value]) => {
      if ((key && value === undefined) || value === null || value === "") {
        notify_error("Please enter " + key.split("_").join(" "));
        check = 1;
      }
    });
    if (check !== 1) {
      if (isEmail(enquiry.username)) {
        setFormValidate(true);
      } else {
        notify_error("Please enter valid email ");
      }
    }
  };

  const validateNewRollFormData = () => {
    if (!newRole.roleName.trim()) {
      notify_error("Role name is required");
      return false;
    }

    return true;
  };

  const resetFormData = () => {
    setEnquiry({
      role: [],
      username: "",
      firstname: "",
      lastname: "",
      password: "",
      feature_access: [], // Initialize feature_access as an empty array
    });
  };

  const resetNewRollFormData = () => {
    setNewRole({
      roleName: "",
      feature_access: [],
    });
  };

  const isEmail = (email) => {
    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const showChangeRoleModal = (rowId) => {
    setSelectedRowId(rowId);
    setChangeRoleOpen(true);
  };

  // console.log(selectedRowId);
  const showAddRoleModal = () => {
    setshowAddRoleopen(true);
  };

  const getAllRoles = () => {
    setLoading(true);
    UserAPI.getallRoles().then((result) => {
      if (result.data.status) {
        setallRoles(result?.data?.roles);
        // console.log(result?.data?.data);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setallRoles([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const getAllUsers = (params) => {
    setLoading(true);
    UserAPI.getAllUsers(params).then((result) => {
      if (result.data.status) {
        console.log(result);
        setDate(result?.data?.data);
        // console.log(result?.data?.data);
        setTotalData(result?.data?.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };



  const handleSearch = () => {
    UserAPI.searchQuery({ search: search }, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res.data);
        return;
      }
    );
  };

  const handleOk = () => {
    setConfirmLoading(true);
    validateFormData();
    if (isFormValidate) {
      UserAPI.createUser(enquiry).then((result) => {
        if (result.data.status) {
          notify_success(result.data.message);
          setOpen(false);
          setConfirmLoading(false);
          setFormValidate(false);
          resetFormData();
          getAllUsers({ page: activepage, limit: recperpage });
          // window.location.reload();
        }
      });
    } else {
      setConfirmLoading(false);
    }
  };

  // const changeRoleOk = () => {};

  const handleChangeRole = (event) => {
    setUpdatedUserRole(event.target.value);
  };

  // const handleChangeRole = async(event) => {

  //   const data={
  //     id:cookie.get('user'),
  //     role:event.target.value
  //   }

  //   let params=JSON.stringify(data)

  //     UserAPI.updateRole(params).then((res)=>{
  //       notify_success("User Role Updated SuccessFully")
  //       getAllUsers({ page: activepage, limit: recperpage });
  //         window.location.reload();
  //     }).catch((err)=>{
  //       notify_error("User Role Couldn't be updated")
  //     })

  // }

  const changeRoleOk = () => {
    // Check if the updated role is not empty
    if (updatedUserRole && updatedUserRole.role) {
      const data = {
        id: selectedRowId,
        role: updatedUserRole.role, // Extracting the first role from the array
        feature_access: updatedUserRole.feature_access || [],
      };

      let params = JSON.stringify(data);

      UserAPI.updateRole(params)
        .then((res) => {
          notify_success("User Role Updated Successfully");
          // Step 4: Fetch the updated data after role update
          getAllUsers({ page: activepage, limit: recperpage });
          // Close the modal
          setUpdatedUserRole({
            role: [],
            feature_access: [],
          });
          setChangeRoleOpen(false);
        })
        .catch((err) => {
          notify_error("User Role Couldn't be updated");
        });
    } else {
      // If the updated role is empty or doesn't have the necessary properties, show an error message or take appropriate action
      notify_error("Please select a new role with valid properties");
    }
  };

  const handleCreateRollOk = () => {
    setConfirmLoading(true);
    if (validateNewRollFormData()) {
      UserAPI.createRole(newRole)
        .then((result) => {
          if (result?.data?.status) {
            notify_success(result.data.message);
            setshowAddRoleopen(false);
            setConfirmLoading(false);
            getAllRoles();
            setFormValidate(false);
            resetNewRollFormData();
            getAllUsers({ page: activepage, limit: recperpage });
          }
        })
        .catch((err) => {
          notify_error("Role name already exists");
        });
    } else {
      setConfirmLoading(false);
    }
  };

  const addRoleOk = () => {};

  const handleCancel = () => {
    setOpen(false);
    setEnquiry({
      role: [],
      username: "",
      firstname: "",
      lastname: "",
      password: "",
      feature_access: [], // Initialize feature_access as an empty array
    });
  };

  const cancelChangeRole = () => {
    setUpdatedUserRole({
      role: [],
      feature_access: [], // Initialize feature_access as an empty array
    });

    setChangeRoleOpen(false);
  };

  const cancelAddRole = () => {
    setshowAddRoleopen(false);
    setNewRole({
      roleName: "",
      feature_access: [],
    });
  };

  const handleOnChange = (e) => {
    const { name, value, options } = e.target;
    if (name === "role") {
      // If the field is "role", update role in the state
      setEnquiry({
        ...enquiry,
        role: value,
        feature_access: [], // Clear feature_access when role changes
      });
    } else if (name === "feature_access") {
      // If the field is "feature_access", update the selected features in the state
      const selectedFeatures = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setEnquiry({
        ...enquiry,
        feature_access: selectedFeatures,
      });
    } else {
      // For other fields, update as usual
      setEnquiry({ ...enquiry, [name]: value });
    }
  };

  // add new role feature access functionality

  const handleNewRoleChange = (e) => {
    const { name, value } = e.target;
    setNewRole({ ...newRole, [name]: value });
  };

  const handleFeatureAccessChange = (index, e) => {
    const { value } = e.target;
    const updatedFeatureAccess = [...newRole.feature_access];
    updatedFeatureAccess[index] = value;
    setNewRole({ ...newRole, feature_access: updatedFeatureAccess });
  };

  const addFeatureAccessCard = () => {
    setNewRole({
      ...newRole,
      feature_access: [...newRole.feature_access, ""],
    });
  };

  const removeFeatureAccessCard = (index) => {
    const updatedFeatureAccess = [...newRole.feature_access];
    updatedFeatureAccess.splice(index, 1);
    setNewRole({ ...newRole, feature_access: updatedFeatureAccess });
  };

  ////

  const handleDelete = (params) => {
    const body = JSON.stringify({ _id: params });
    if (window.confirm("Do you want to Delete this user?")) {
      UserAPI.deleteUser(body)
        .then((result) => {
          // console.log("confirm");
          top_loader(true, 45);
          getAllUsers({ page: activepage, limit: recperpage });
          notify_success(result?.message || "User Successfully Deleted");
        })
        .catch((err) => {
          notify_error(err?.message || err);
        });
    } else {
      return;
    }
  };

  function capitalizeWords(str) {

    if (typeof str !== 'string') {
      // If the input is not a string, return it as is
      return str;
    }
  
    const words = str?.replace(/_/g, " ").split(" ");
    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
  }
   const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);
  
    // If the search field is emptied, reload all records
    if (value.trim() === '') {
      getAllUsers({ page: activepage, limit: recperpage });
    }
  };

  const handleRefresh = () => {
    // Clear search
    SetSearch("");
    // setIsSearch(false);

    // Clear filters
    // setFilter([]);
    // setIsFilter(false);
    // filterEmptySelected();

    // Clear Checkbox
    // setSelectedRows([]);

    // Fetch data again
    getAllUsers({ page: activepage, limit: recperpage });
  };

  // console.log(allRoles);

  const roleHasFeatureAccess = (role) => {
    const selectedRole = allRoles.find((r) => r.roleName === role);
    return (
      selectedRole &&
      selectedRole.feature_access &&
      selectedRole.feature_access.length > 0
    );
  };

  // feature access options

  // const selectedRole =data.length > 0 && allRoles.find((role) => role.roleName === enquiry.role);
  //   const featureAccessOptions = (selectedRole?.feature_access || []).map((feature) => ({
  //     value: feature,
  //     label: feature,
  //   })) || [];

  // console.log(enquiry);

  const handleSelectedRoleChange = (selectedOptions) => {
    const newRoles = selectedOptions.map((option) => option.value);

    // Filter out feature access associated with roles that are no longer selected
    const newFeatureAccess = enquiry.feature_access.filter((access) => {
      const associatedRole = allRoles.find((role) =>
        role.feature_access?.includes(access)
      );
      return newRoles.includes(associatedRole?.roleName);
    });

    setEnquiry({
      ...enquiry,
      role: newRoles,
      feature_access: newFeatureAccess,
    });
  };

  const handleupdaterolechange = (selectedOptions) => {
    const newRoles = selectedOptions.map((option) => option.value);
    const newFeatureAccess = updatedUserRole.feature_access.filter((access) => {
      const associatedRole = allRoles.find((role) =>
        role.feature_access?.includes(access)
      );
      return newRoles.includes(associatedRole?.roleName);
    });
    setUpdatedUserRole({
      ...updatedUserRole,
      role: newRoles,
      feature_access: newFeatureAccess,
    });
  };

  // console.log(updatedUserRole);

  // console.log(totalData);
  return (
    <Container
      fluid
      className="p-0"
      style={{
        backgroundColor: "#F2F5F7",
        marginTop: "80px",
        fontFamily: "Inter",
      }}
    >
      <Row className="mx-3">
        <div
          className="bg-white  my-3 rounded "
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "30px",
          }}
        >
          {/* Search button */}
          <div style={{ marginLeft: "12px", width: "25%" }}>
            <InputGroup className="my-2" style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
              <div
                style={{
                  backgroundColor: "#F8F9FB",
                  paddingLeft: "11px",
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                }}
                className="d-flex justify-content-center align-items-center  "
              >
                <img src="/assets/images/dashboard/search.png"></img>
              </div>
              <Form.Control
                placeholder="Search In Users Table"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                name="search"
                className=""
                style={{
                  backgroundColor: "#F8F9FB",
                  border: "none",
                  fontFamily: "Inter",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
                onChange={handleSearchChange}
                value={search}
                onKeyPress={handleSearch}
              />
            </InputGroup>
          </div>

          <div
            className="p-0"
            style={{
              padding: "0px",
              right: "0px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "flex-end",
              // marginRight: "12px",
            }}
          >
            <div
              style={{
                height: "100% ",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="d-flex gap-3 "
                style={{
                  padding: "0",
                  marginLeft: "12px",
                  // height: "100%",
                }}
              >
                <button
                  className="rounded-1 px-3 py-2 float-right"
                  style={{
                    backgroundColor: "white",
                    fontFamily: "Inter",
                    color: "#2B73FF",
                    borderColor: "#2B73FF",
                    borderRadius: "4px",
                  }}
                  onClick={showAddRoleModal}
                >
                  Create New Role
                </button>

                <button
                  className="rounded-1 px-3 py-2 float-right"
                  style={{
                    backgroundColor: "white",
                    fontFamily: "Inter",
                    color: "#2B73FF",
                    borderColor: "#2B73FF",
                    borderRadius: "4px",
                  }}
                  onClick={showModal}
                >
                  Create New User
                </button>
                <div
                  style={{
                    width: "1px",
                    height: "12/12",
                    backgroundColor: "#E5E9EB",
                  }}
                ></div>
              </div>

              <div
                className="d-flex gap-3 "
                style={{
                  padding: "0px",
                  marginLeft: "12px",
                  height: "100%",
                }}
              >
                <RefreshOutlinedIcon
                  fontSize="large"
                  color="disabled"
                  className="my-auto"
                  cursor="pointer"
                  onClick={handleRefresh}
                />
              </div>
            </div>
          </div>
        </div>
        <Col lg={12}>
          <div className="py-2  ">
            <div
              className="maintable "
              id="table-container"
              style={{ height: "900px" }}
            >
              <table className="col-md-12 enquiryTable px-3">
                <thead className="bg-transparent ">
                  <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                    <th>S.No.</th>
                    <th style={{ width: "15%" }}>Name</th>
                    <th>Role</th>
                    <th style={{ width: "15%" }}>Email</th>
                    <th>Created At</th>
                    <th style={{ width: "15%" }}>Edit Role</th>
                    <th>Delete</th>
                  </tr>
                </thead>

                <tbody>
                  {data.length > 0 &&
                    data.map((item, index) => {
                      function isValidDate(date) {
      return date instanceof Date && !isNaN(date);
    }
                      const dateObject = new Date(item?.created_at);
    const formattedDate =
      isValidDate(dateObject) ?
        dateObject.toISOString().split('T')[0].split('-').reverse().join('-') :
        "Invalid Date";

                      return (
                        < >
                          <tr key={item._id} className="tableRows ">
                            <td>{sno + index + 1}</td>
                            <td className="fw-bold">
                              {capitalizeWords(
                                `${item.firstname} ${item.lastname}`
                              )}
                            </td>
                            <td>
                              {item.role
                                .map(
                                  (role) =>
                                    role.charAt(0).toUpperCase() + role.slice(1)
                                )
                                .join(", ")}
                            </td>

                            <td>{item?.username}</td>
                            <td>
                             {formattedDate}
                            </td>
                            <td>
                              <button
                                className="px-4"
                                style={{
                                  backgroundColor: "#2B73FF",
                                  border: "none",
                                  color: "white",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  lineHeight: "35px",
                                  borderRadius: "4px",
                                }}
                                onClick={() => showChangeRoleModal(item._id)}
                              >
                                Edit Role
                              </button>
                            </td>
                            <td>
                              <div
                                d-flex
                                justify-content-center
                                align-items-center
                              >
                                <PersonRemoveIcon
                                  sx={{ color: "#F7685B" }}
                                  onClick={() => handleDelete(item._id)}
                                />
                                {/* <button
                                  className="btn  btn-s "
                                  style={{
                                    backgroundColor: "#FFE3E0",
                                    color: "#F7685B",
                                  }}
                                  onClick={() => handleDelete(item._id)}
                                >
                                  Delete
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}

                  {data.length == "0" && (
                    <>
                      <div className="d-flex align-items-center justify-content-center">
                        <h1 className="text-muted">NO DATA</h1>
                      </div>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 form-inline py-2 tableFooter">
              <div className="row align-items-center  pageSet">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label className="control-label col-md-4" style={{fontWeight:"700", paddingTop:"5px", paddingLeft:"25px"}}>
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    //   onChange={(event) => SetRecPerPage(event.target.value)}

                    // onChange={(event) => applyFilter(event)}
                    onChange={(event) => SetRecPerPage(event.target.value)}
                    value={recperpage}
                    style={{border:"solid", borderWidth:"thin", color:"#000"}}
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div className="col-md-7 text-end">
                  <Pagination
                    total={totalData}
                    showSizeChanger={false}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      SetActivePage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* Create New User Modal */}
      <Modal
        open={open}
        onOk={handleOk}
        footer={null}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        style={{
          marginBlock: "auto",
          paddingTop: "20px",
          borderRadius: "44px",
          fontFamily: "Inter",
        }}
      >
        <div className="row">
          <div className="mb-3  col-md-10 mx-auto bg-transparent">
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "28px",
                lineHeight: "38px",
                fontWeight: "700",
              }}
            >
              Create New User
            </h2>
            {
              <div className="form-group">
                <label htmlFor="roles">Roles</label>
                <Select
                  style={{
                    border: "1px solid",
                    borderRadius: "12px",
                    padding: "15px",
                  }}
                  className="form-control border-secondary"
                  isMulti
                  options={
                    allRoles &&
                    allRoles.map((role) => ({
                      value: role.roleName,
                      label: role.roleName,
                    }))
                  }
                  value={
                    enquiry.role &&
                    enquiry.role.map((selectedRole) => ({
                      value: selectedRole,
                      label: selectedRole,
                    }))
                  }
                  onChange={handleSelectedRoleChange}
                  placeholder="Select roles"
                />
              </div>
            }

            {enquiry.role.length > 0 &&
              enquiry.role.map((selectedRole) => {
                const selectedRoleObj = allRoles.find(
                  (role) => role.roleName === selectedRole
                );
                const hasFeatureAccess = roleHasFeatureAccess(selectedRole);

                return (
                  // Check if feature_access is not empty before rendering the whole div
                  hasFeatureAccess && (
                    <div key={selectedRole} className="form-group">
                      <label htmlFor="feature_access">
                        Feature Access for {selectedRole}
                      </label>

                      <Select
                        style={{
                          border: "1px solid",
                          borderRadius: "12px",
                          padding: "15px",
                        }}
                        className="form-control border-secondary"
                        closeMenuOnSelect={false}
                        // components={makeAnimated()}
                        isMulti
                        options={selectedRoleObj.feature_access.map(
                          (feature) => ({
                            value: feature,
                            label: feature,
                          })
                        )}
                        value={enquiry.feature_access.map((feature) => ({
                          value: feature,
                          label: feature,
                        }))}
                        onChange={(selectedOptions) =>
                          setEnquiry((prevEnquiry) => ({
                            ...prevEnquiry,
                            feature_access: selectedOptions.map(
                              (option) => option.value
                            ),
                          }))
                        }
                        placeholder={`Select feature access for ${selectedRole}`}
                        isDisabled={!hasFeatureAccess}
                      />
                    </div>
                  )
                );
              })}

            <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                style={{
                  border: "2px solid",
                  borderRadius: "12px",
                  padding: "15px",
                }}
                className="form-control border-secondary"
                id="first_name"
                name="firstname"
                placeholder="Enter Your First Name"
                onChange={handleOnChange}
                value={enquiry.firstname}
              />
              {/* <span id="show_name" class="err">
              This field can not be empty
              </span> */}
            </div>
            <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                style={{
                  border: "2px solid",
                  borderRadius: "12px",
                  padding: "15px",
                }}
                className="form-control border-secondary"
                id="last_name"
                name="lastname"
                placeholder="Enter Your Last Name"
                onChange={handleOnChange}
                value={enquiry.lastname}
              />
              {/* <span id="show_name" class="err">
              This field can not be empty
              </span> */}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                style={{
                  border: "2px solid",
                  borderRadius: "12px",
                  padding: "15px",
                }}
                className="form-control border-secondary"
                id="email"
                name="username"
                placeholder="Enter email"
                onChange={handleOnChange}
                value={enquiry.username}
              />
              {/* <span id="show_email" class="err">
              This field can not be empty
              </span> */}
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                style={{
                  border: "2px solid",
                  borderRadius: "12px",
                  padding: "15px",
                }}
                className="form-control border-secondary"
                id="password"
                name="password"
                placeholder="password"
                onChange={handleOnChange}
                value={enquiry.password}
              />
              {/* <span id="show_password" class="err">
              This field can not be empty
              </span> */}
            </div>

            <div className="d-flex gap-3 align-items-center justify-content-center ">
              <div className="">
                <button
                  className=" py-2"
                  onClick={handleCancel}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "12px",
                    color: "black",
                    fontFamily: "Inter",
                    border: "2px solid",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline: "40px",
                  }}
                >
                  Cancel
                </button>
              </div>

              <div className="">
                <button
                  className=" py-2"
                  onClick={handleOk}
                  style={{
                    backgroundColor: "#2B73FF",
                    borderRadius: "12px",
                    color: "white",
                    fontFamily: "Inter",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline: "40px",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Change Role Modal */}
      <Modal
        title="Change Role "
        style={{ fontFamily: "Inter" }}
        open={changeRoleOpen}
        onCancel={cancelChangeRole}
        onOk={changeRoleOk}
      >
        {selectedRowId && (
          <container>
            <Row>
              {data.length > 0 &&
                data.map((item, index) => {
                  // Only show the selected row's data
                  if (item._id === selectedRowId) {
                    return (
                      <>
                        <Col lg={12} className="my-3">
                          <div
                            className="d-flex justify-content-between mx-5 "
                            style={{ fontFamily: "Inter", fontSize: "20px" }}
                          >
                            <div style={{ fontWeight: "inherit" }}>
                              Name of User :{" "}
                            </div>

                            <div>
                              {capitalizeWords(
                                `${item.firstname} ${item.lastname}`
                              )}
                            </div>
                          </div>
                        </Col>

                        <Col lg={12} className="my-3">
                          <div
                            className="d-flex justify-content-between mx-5 "
                            style={{ fontFamily: "Inter", fontSize: "20px" }}
                          >
                            <div style={{ fontWeight: "inherit" }}>
                              Current Role:
                            </div>
                            <div>
                              {item.role
                                .map(
                                  (role) =>
                                    role.charAt(0).toUpperCase() + role.slice(1)
                                )
                                .join(", ")}
                            </div>
                          </div>
                        </Col>
                        {item.feature_access &&
                          item.feature_access.length > 0 && (
                            <Col lg={12} className="my-3">
                              <div
                                className="d-flex justify-content-between mx-5 "
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "20px",
                                }}
                              >
                                {item.feature_access &&
                                  item.feature_access.length > 0 && (
                                    <div style={{ fontWeight: "inherit" }}>
                                      Current Feature:
                                    </div>
                                  )}

                                <div>
                                  {item.feature_access &&
                                    item.feature_access
                                      .map(
                                        (role) =>
                                          role.charAt(0).toUpperCase() +
                                          role.slice(1)
                                      )
                                      .join(", ")}
                                </div>
                              </div>
                            </Col>
                          )}

                        <Col lg={12} className="my-3 ">
                          <div
                            className="d-flex justify-content-between align-items-center mx-5 "
                            // style={{ fontFamily: "Inter", fontSize: "20px" }}
                          >
                            <div
                              style={{
                                fontFamily: "Inter",
                                fontSize: "20px",
                                fontWeight: "inherit",
                              }}
                            >
                              Select New Role:
                            </div>
                            <div>
                              <Select
                                style={{
                                  border: "1px solid",
                                  borderRadius: "12px",
                                  padding: "15px",
                                }}
                                className="form-control border-secondary"
                                isMulti
                                options={
                                  allRoles &&
                                  allRoles.map((role) => ({
                                    value: role.roleName,
                                    label: role.roleName,
                                  }))
                                }
                                value={
                                  updatedUserRole.role &&
                                  updatedUserRole.role.map((selectedRole) => ({
                                    value: selectedRole,
                                    label: selectedRole,
                                  }))
                                }
                                onChange={handleupdaterolechange}
                                placeholder="Select roles"
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg={12} className="my-3">
                          {updatedUserRole.role.length > 0 &&
                            updatedUserRole.role.map((selectedRole) => {
                              const selectedRoleObj = allRoles.find(
                                (role) => role.roleName === selectedRole
                              );
                              const hasFeatureAccess =
                                roleHasFeatureAccess(selectedRole);

                              return (
                                // Check if feature_access is not empty before rendering the whole div
                                hasFeatureAccess && (
                                  <div
                                    key={selectedRole}
                                    className="d-flex justify-content-between align-items-center mx-5 "
                                  >
                                    <div
                                      style={{
                                        fontFamily: "Inter",
                                        fontSize: "20px",
                                        fontWeight: "inherit",
                                      }}
                                      htmlFor="feature_access"
                                    >
                                      Feature Access for {selectedRole}
                                    </div>

                                    <div>
                                      <Select
                                        style={{
                                          border: "1px solid",
                                          borderRadius: "12px",
                                          padding: "15px",
                                        }}
                                        className="form-control border-secondary"
                                        closeMenuOnSelect={false}
                                        // components={makeAnimated()}
                                        isMulti
                                        options={selectedRoleObj.feature_access.map(
                                          (feature) => ({
                                            value: feature,
                                            label: feature,
                                          })
                                        )}
                                        value={updatedUserRole.feature_access.map(
                                          (feature) => ({
                                            value: feature,
                                            label: feature,
                                          })
                                        )}
                                        onChange={(selectedOptions) =>
                                          setUpdatedUserRole((prevEnquiry) => ({
                                            ...prevEnquiry,
                                            feature_access: selectedOptions.map(
                                              (option) => option.value
                                            ),
                                          }))
                                        }
                                        placeholder={`Select feature access for ${selectedRole}`}
                                        isDisabled={!hasFeatureAccess}
                                      />
                                    </div>
                                  </div>
                                )
                              );
                            })}
                        </Col>
                      </>
                    );
                  }
                  return null;
                })}
            </Row>
          </container>
        )}
      </Modal>
      {/* Create new Role Modal */}
      <Modal
        open={showAddRoleopen}
        onOk={addRoleOk}
        footer={null}
        onCancel={cancelAddRole}
        style={{
          marginBlock: "auto",
          paddingTop: "20px",
          borderRadius: "44px",
          fontFamily: "Inter",
        }}
      >
        <div className="row">
          <div className="mb-3  col-md-10 mx-auto bg-transparent">
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "28px",
                lineHeight: "38px",
                fontWeight: "700",
              }}
            >
              Create New Role
            </h2>

            <div className="form-group">
              <label htmlFor="roleName">Role Name</label>
              <input
                type="text"
                style={{
                  border: "2px solid",
                  borderRadius: "12px",
                  padding: "15px",
                }}
                className="form-control border-secondary"
                id="roleName"
                name="roleName"
                placeholder="Enter Role Name"
                onChange={handleNewRoleChange}
                value={newRole.roleName}
              />
            </div>

            <div className="form-group">
              <label htmlFor="feature_access">Feature Access</label>
              {newRole.feature_access.map((access, index) => (
                <div key={index} className="d-flex mb-2">
                  <input
                    type="text"
                    style={{
                      border: "2px solid",
                      borderRadius: "12px",
                      padding: "15px",
                    }}
                    className="form-control border-secondary"
                    placeholder="Enter Feature Access"
                    value={access}
                    onChange={(e) => handleFeatureAccessChange(index, e)}
                  />

                  <div style={{ marginTop: "0.7rem", marginLeft: "0.4rem" }}>
                    <CancelIcon
                      onClick={() => removeFeatureAccessCard(index)}
                    />
                  </div>
                </div>
              ))}

              <AddCircleIcon
                style={{ marginTop: "-7px", marginLeft: "5px" }}
                onClick={addFeatureAccessCard}
              />
            </div>
            <div className="d-flex gap-3 align-items-center justify-content-center ">
              <div className="">
                <button
                  className=" py-2"
                  onClick={cancelAddRole}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "12px",
                    color: "black",
                    fontFamily: "Inter",
                    border: "2px solid",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline: "40px",
                  }}
                >
                  Cancel
                </button>
              </div>

              <div className="">
                <button
                  className=" py-2"
                  onClick={handleCreateRollOk}
                  style={{
                    backgroundColor: "#2B73FF",
                    borderRadius: "12px",
                    color: "white",
                    fontFamily: "Inter",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline: "40px",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      ;
    </Container>
  );
};

export default NewCreateUser;
