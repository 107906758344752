import React, { useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";

const BBAFilter = ({ isOpen, handleFilterChange }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleButtonClickProgramCategory = async (category) => {
    if (selectedCategory === category) {
      // Unselect category and call the API for unselection
      // console.log("check 1");
      setSelectedCategory(null);
      handleFilterChange({ params: {} }); // Assuming empty params to unselect
    } else {
      // Select the new category and call the API for selection
      setSelectedCategory(category);
      // console.log("check 2");
      const apiParams = {
        program: "BBA",
        batch: category,
      };
      handleFilterChange({ params: apiParams });
    }
  };

  return (
    <Container
      className="py-3 mx-auto"
      style={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "white",
        boxShadow: "0px 20px 10px 0px rgba(0, 31, 51, 0.10)",
        paddingLeft: "30px",
        fontFamily: "Inter",
        paddingRight: "40px",
      }}
    >
      <Row className="team-member-1 text-start my-2">
        <Col>Filter By Batch :</Col>
      </Row>
      <Row className="d-flex row-cols-3">
        {[
          "1997-2000",
          "1998-2001",
          "1999-2002",
          "2000-03",
          "2001-04",
          "2002-05",
          "2003-06",
          "2004-07",
          "2005-08",
          "2006-09",
          "2007-10",
          "2008-11",
          "2009-12",
          "2010-13",
          "2011-14",
          "2012-15",
          "2013-16",
          "2014-17",
          "2015-18",
          "2016-19",
          "2017-20",
          "2018-21",
          "2019-22",
          "2020-23",
          "2021-24",
        ].map((category) => (
          <Col className="my-1" key={category}>
            <Badge
              bg=""
              onClick={() => handleButtonClickProgramCategory(category)}
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor:
                  selectedCategory === category ? "#2B73FF" : "#F2F2F2",
                color: selectedCategory === category ? "#FFFFFF" : "#2B73FF",
              }}
            >
              {category}
            </Badge>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BBAFilter;
