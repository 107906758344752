import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "./NavBar";
import {  useNavigate } from "react-router-dom";
import { notify_error, notify_success } from "./Notify";
import cookie from "js-cookie";
import StudentProfile from "./Studentprofile";
import ProgressBar from "./ProgressBar";
import LeadRemarks from "./LeadRemarks";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import { Button } from "react-bootstrap";
import { EnquiryContext } from "../state/context/EnquiryProvider";
import Duplicates from "./Duplicates";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const LeadProfile = () => {

 
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [data,setDate]= useState([])
  const navigate=useNavigate();

  
  // const { SetActivePage, SetRecPerPage, handlePageChange, isBack, setIsBack } = useContext(EnquiryContext);

  const handleRefresh = () => {
    EnquiryAPI.getOneEnquiry(id)
      .then((res) => {
        setDate(res.data.data[0]);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
  };

  



  useEffect(() => {
    if (id) {
      EnquiryAPI.getOneEnquiry(id)
        .then((res) => {
          setDate(res.data.data[0]);
          if (res.data.data[0].isReassigned && !res.data.data[0].hasNewAssigneeResponded) {
            if (cookie.get('name') == res.data.data[0].assignedTo || cookie.get('name') == res.data.data[0].handled_by) {
              OnlineEnquiryAPI.updateReassignResponse(id)
                .then((res) => {
                  notify_success('Reassigned Enquiry Handled');
                })
                .catch((err) => {
                  notify_error("Reassigned Enquiry Handle Status Couldn't be updated");
                });
            }
          }
        })
        .catch((err) => {
          notify_error(err.message || "Something Went Wrong");
        });
    }
  }, []);

  return (
    <>
      <div className="container-fluid page-body-wrapper" style={{ fontFamily: "Inter", position: "relative" }}>
        <NavBar />
        {/* {data && data._id} */}
        <div className="" style={{ top: "128px", position: "absolute", left: "-27px", zIndex: "90" }}>
          <button className="" style={{ border: "none", background: "transparent", borderRadius: "50%" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate('/dashboard/enquiry-table')
             
            }}
          >
            <img width={"36px"} height={"36px"} src="/assets/images/dashboard/back-button.png" alt="Back" />
          </button>

        </div>
        {/* MISSING CLOSING TAG FOR THE DIV ELEMENT */}
        <div className="enquirypersonDetail">
        {loading ? (
      // Show a loading indicator here
      <div>Loading...</div>
    ) : (
      // Render your components when data is available
      <>
        <StudentProfile data={data} handleRefresh={handleRefresh} />
        <Duplicates data={data} />
      </>
    )}

        </div>
        <div className="page-body-wrapper1">
          <ProgressBar id={id} />
          <LeadRemarks data={data} />
         
        </div>




      </div>
    </>
  );
};

export default LeadProfile;

