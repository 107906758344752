import React from "react";
import PageHeader from "./PageHeader";
import StretchCard from "./StretchCard";
import Graph from "./Graph";
import RecentTickets from "./RecentTickets";
import RecentUpdate from "./RecentUpdate";
import ProjectStatus from "./ProjectStatus";
import ChartData from "./Chart";

const Main = () => {
  return (
    <>
      <div className="main-panel mt-2" style={{fontFamily:"Inter"}}>
      <div className="content-wrapper">
      
          {/* <div className="dashboard-1">
          <span className="dashboard-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-home" />
          </span>
          <PageHeader title="Dashboard"/>

          </div> */}
          <div className="content-wrapper">
          

          <StretchCard />
          </div>
      </div>
          
          {/* <Graph/> */}
          {/* <RecentTickets/> */}
          {/* <RecentUpdate/> */}
          {/* <ProjectStatus/> */}
        
        {/* content-wrapper ends */}
        {/* partial:partials/_footer.html */}
        <footer className="footer">
          <div className="container-fluid d-flex justify-content-between">
            <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">
            Copyright © <a href="https://assertit.io/" style={{color:"inherit" , textDecoration:"underline"}}>AssertIT Solutions 2022</a>
            </span>
          </div>
        </footer>
        {/* partial */}
      </div>
    </>
  );
};

export default Main;
