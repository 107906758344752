import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom"
import store from './state/store';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import EnquiryProvider from './state/context/EnquiryProvider';
import LawProvider from './state/context/LawProvider';
import ContactProvider from './state/context/ContactProvider';
import CareerProvider from './state/context/CareerProvider';
import FeedbackProvider from './state/context/FeedbackProvider';
import IecegnProvider from './state/context/IecegnProvider';
import IcdsProvider from './state/context/IcdsProvider';
import PsbmProvider from './state/context/PsbmProvider';
ReactDOM.render(
  <React.StrictMode>
  <EnquiryProvider>
  <LawProvider>
  <ContactProvider>
  <CareerProvider>
  <FeedbackProvider>
  <IecegnProvider>
  <PsbmProvider>
  <IcdsProvider>
  <Provider store={store}>
  <Router>
  <StyledEngineProvider injectFirst>
  <App />
  </StyledEngineProvider>
  </Router>
  </Provider>
  </IcdsProvider>
  </PsbmProvider>
  </IecegnProvider>
  </FeedbackProvider>
  </CareerProvider>
  </ContactProvider>
  </LawProvider>
  </EnquiryProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
