
const initialState = {
    status:"",
    msg:""
}

const reducer = (state=initialState,action)=>{
        if(action.type==='ADMIN_LOGIN'){
            return {...action.payload};
        }else if(action.type==='ADMIN_LOGOUT'){
            return action.payload;
        }else{
            return state;
        }
}

export default reducer;