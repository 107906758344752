import React, { useEffect, useState } from "react";
import IecegnAPI from "../../utils/api/IecegnAPI";
import { notify_error, notify_success } from "../Notify";
import { serverAddress } from "../../constant";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
import CreditScoreIcon from '@mui/icons-material/CreditScore';

const IecegnProfile = ({ data }) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("");
    const [paymentEmail,setPaymentEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);

  // const handlePrint=(params)=>{
  //     IecegnAPI.updateStatus({ _id: params }).then((result) => {
  //         if (result.data.status) {
  //           notify_success(result.data.message);
  //         //   getAllEnquiry({ page: activepage, limit: recperpage });
  //           // window.open(result?.data?.downloadLink);
  //           let x = result?.data?.downloadLink;
  //           const url = new URL(x);
  //           const path = url.pathname.slice(1);
  //           setKey(path);
  //           window.open(
  //             `${serverAddress}/job_application/preview?file_path=${path}`
  //           );
  //         } else {
  //           notify_error("Failed to Print");
  //         }
  //       });
  // }

  const handlePaymentStatus = (_id,email) => {
    setOpen(true);
    setCandidateId(_id);
    setPaymentEmail(email);

  };
  

  const handleCancel = () => {
    setOpen(false);
  };

  function verifyPaytmTransactionId(transactionId) {
    const regex = /^.{9,33}$/;

    return regex.test(transactionId);
  }

  function transactionVerification(params) {
    if (verifyPaytmTransactionId(params)) {
      return true;
    } else {
      return false;
    }
  }

  const handleOk = () => {
    setConfirmLoading(true);
    if (transactionId) {
      const validate = transactionVerification(transactionId);

      if (validate) {
        IecegnAPI.updateTransactionIdIecegn(candidateId, transactionId)
          .then((result) => {
            // // console.log('confirm');
            IecegnAPI.confirmPaymentIECEGN(candidateId)
              .then((result) => {
                // console.log("confirm");
                IecegnAPI.paymentEmailIcegn(candidateId,paymentEmail).then((result)=>{
                  top_loader(true, 45);
                // getAllLaw({ page: activepage, limit: recperpage });
                window.location.reload();

                setCandidateId("");
                setTransactionId("");
                notify_success(
                  "Candidate Payment Status Updated Successfully."
                );
                setOpen(false);
                }) .catch((err) => {
                  notify_error(err?.message || err);
                });
              })
              .catch((err) => {
                notify_error(err?.message || err);
              });
          })
          .catch((err) => {
            notify_error(err?.message || err);
          });
      } else {
        notify_error("Not a Valid transaction ID");
      }
    }
    // validateFormData();
    // if (isFormValidate) {
    //   UserAPI.createUser(enquiry).then((result) => {
    //     if (result.data.status) {
    //       notify_success(result.data.message);
    //       setOpen(false);
    //       setConfirmLoading(false);
    //       setFormValidate(false);
    //       resetFormData();
    //       getAllUsers({ page: activepage, limit: recperpage });
    //     }
    //   });
    // } else {
    //   setConfirmLoading(false);
    // }
  };

  const handleOnChange = (e) => {
    setTransactionId(e.target.value);
  };

  const handlePaymentLink = (_id) => {
    IecegnAPI.sendLinkLaw(_id)
      .then((result) => {
        if (result) {
          window.alert("email sent Successfully!");
        }
      })
      .catch((err) => {
        notify_error(err?.message || err);
      });
  };
  useEffect(() => {
    // console.log(data);
    // console.log(key);
  }, [key]);

  function getFirstWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords;
  }
  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  return (
    <>
      {data && data._id && (
        <div
          className="spWrapper  "
          style={{
            height: "400px",
            boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
          }}
        >
          <div className="spContent">
            <div className="spContent01">
              {getFirstWords(data.first_name)}
              {getFirstWords(data.last_name)}
            </div>

            <div className="spContent02">
              {capitalizeWords(data.first_name)}{" "}
              {capitalizeWords(data.last_name)}
            </div>
            <div className="spContent03">{data.mobile_no}</div>
          </div>

          <div className="spContent1">
            <img
              height={"20px"}
              src="/assets/images/dashboard/calender.png"
            ></img>
            <div className="spContent11">Created at:</div>
            <div className="spContent12">{data.created_at?.slice(0, 10)}</div>
          </div>

          <div className="spContent2"></div>

          <div className="spContent31 mt-3 mx-3">
            <div className="spContent32">Payment Status :</div>
            <div className="spContent33">
              {data.isPaymentDone ? "Paid" : "Not Paid"}
            </div>
          </div>
          <div className="spContent2 mt-3"></div>
          <div className="spContent31 mt-3 mx-3">
          <div className="spContent32">Payment Required :</div>
          <div className="spContent33">
          {`${data.price?.unit_amount/100} ${data.price?.currency?.toUpperCase()}`}
          </div>
          </div>

          <div className="spContent2 mt-3"></div>

          <div className="spContent4">
            <div className="spContent41">
            <CreditScoreIcon/>

            </div>

            <div className="spContent42"></div>

            <div className="spContent43">
              <div>
                {!data.isPaymentDone ? (
                  <button
                    className="spButton1 py-1 px-4"
                    style={{ border: "none" }}
                    onClick={() => handlePaymentStatus(data._id,data.email)}
                  >
                    Confirm Payment
                  </button>
                ) : (
                  <span className="" style={{}}>
                    PAYMENT RECEIVED
                  </span>
                )}
              </div>
            </div>
          </div>
          <Modal
            style={{ fontFamily: "Inter" }}
            title="Transaction Id Verification"
            centered
            open={open}
            onOk={() => handleOk(false)}
            onCancel={() => handleCancel(false)}
          >
            <div className="form-group">
              <label htmlFor="last_name">Transaction Id</label>
              <input
                type="text"
                className="form-control"
                id="transactionId"
                name="transactionId"
                placeholder="Please Enter Transaction Id"
                onChange={handleOnChange}
                value={transactionId}
              />
              <span id="show_name" class="err">
                Please Enter Last Name
              </span>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
export default IecegnProfile;
