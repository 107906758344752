import './App.css';
import Dashboard from "./components/Dashboard"
import { Route, Routes, useNavigate,useLocation } from "react-router-dom"
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from 'react-top-loading-bar'
import { notify_success } from './components/Notify';
import UserAPI from './utils/api/UserAPI';
import { bindActionCreators } from 'redux';
import { actionCreators } from './state';

function App() {
  const loader = useSelector(state => state.top_loader)
  const [tokenState,setTokenState]=useState(cookie.get('ajwt'))
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   if (!cookie.get('ajwt')) {
  //     navigate('/');
  //   }

  // }, [navigate])


  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);

  const logout = async () => {
    top_loader(true, 10);
    top_loader(true, 70);
    top_loader(true, 90);
    top_loader(true, 100);
    cookie.remove("ajwt");
    cookie.remove("user");
    cookie.remove("role");
    cookie.remove("name");
    cookie.remove("image");
    cookie.remove("firstname");
    cookie.remove("lastname");
    notify_success("Logout successfully...");
    navigate("/");
    top_loader(false, 0);
  };

  useEffect(async() => {
    if(cookie.get('ajwt')){
      const currentPathname = location.pathname;
      UserAPI.verifyToken().then(async(data) => {
        let res = await data.json();
        if(res.status === true){
          // notify_success('token-success')
          if(currentPathname =="/"){
            navigate("/dashboard/");
            return;
          }
         return;
        } else {
          logout();
        }
      });
    }
  }, [cookie.get('ajwt')]);


  return (
    <>

      <LoadingBar
        color='#f11946'
        progress={loader.progress}
      />


      <ToastContainer autoClose={2000} />
      <Routes>
        <Route exact path="/" element={<Login />} />
      

        {/* ================Protected Routes Define Below===================== */}
        <Route element={<ProtectedRoute />}>
          <Route exact path="/dashboard/*" element={<Dashboard />} />
        </Route>
        {/* ================Protected Routes End Here===================== */}

        <Route exact path="*" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
