import { data } from "autoprefixer";
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import LawAPI from "../../utils/api/LawAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
// import { EnquiryContext } from "../../state/context/EnquiryProvider";
// import {  Pagination} from "antd";
// import {
//   PlusCircleOutlined,
//   RetweetOutlined,
//   MailOutlined,
// } from "@ant-design/icons";​
import moment from "moment";
import { serverAddress } from "../../constant";
import { notify_error, notify_success } from "../Notify";
import { verify } from "crypto";
import UserAPI from "../../utils/api/UserAPI";
import { useNavigate } from "react-router-dom";
import { CareerContext } from "../../state/context/CareerProvider";
import CareerAPI from "../../utils/api/CareersAPI";
import FilterCareerTable from "./FilterCareerTable";

const NewCareerTable = () => {
  const navigate = useNavigate();
  const {
    filter,
    setFilter,
    // applyFilter,
    recperpage,
    handlePageChange,
    search,
    SetSearch,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
  } = React.useContext(CareerContext);
  const dispatch = useDispatch();
  const filterPanelRef = useRef(null);
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const sno = recperpage * (activepage - 1);

  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  useEffect(() => {
    top_loader(true, 45);
    getAllCareer({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);
  const handleRefresh = () => {
    // top_loader(true, 45);
    getAllCareer({ page: activepage, limit: recperpage });
  };

  const getAllCareer = (params) => {
    setLoading(true);
    CareerAPI.getAllCareer(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handleSearch = () => {
    CareerAPI.searchQuery({ search: search }, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res.data)
        return;
      }
    );
  };

  const handleSearchChange = (e) => {
    SetSearch(e.target.value);
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleFilterRefresh = () => {
    // Clear search
    SetSearch("");
    // setIsSearch(false);

    // Clear filters
    setFilter([]);
    // setIsFilter(false);
    // filterEmptySelected();

    // Clear Checkbox
    // setSelectedRows([]);

    // Fetch data again
    getAllCareer({ page: activepage, limit: recperpage });
  };

  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup className="my-2" style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search Applicant Name"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <FilterAltOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={toggleFilterPanel}
                      />
                      <div
                        style={{
                          width: "1px",
                          height: "12/12",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleFilterRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="maintable "
                id="table-container"
                style={{ height: "920px" }}
              >
                <table className="col-md-12 enquiryTable px-3">
                  <thead className="bg-transparent ">
                    <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th style={{ width: "15%" }}>Position Applied For</th>
                      <th>Action</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                            <tr
                              className="tableRows "
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/dashboard/candidate-profile/${item._id}`
                                )
                              }
                            >
                              <td>{sno + index + 1}</td>
                              <td>{`${item.firstname} ${item.lastname}`}</td>
                              <td>{item.email}</td>
                              <td>{item.contactno}</td>
                              <td className="d-flex flex-column ">
                                {item.applied_position}
                              </td>
                              <td>
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(
                                      `${serverAddress}/job_application/preview?file_path=${item.image}`
                                    );
                                  }}
                                  className="px-2"
                                  style={{
                                    backgroundColor: "#2B73FF",
                                    border: "none",
                                    color: "white",
                                    fontWeight: "400",
                                    fontSize: "13px",
                                    lineHeight: "35px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Download Resume
                                </button>
                              </td>

                              <td>
                                {format(
                                  new Date(item.created_at),
                                  "dd/MM/yyyy"
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    {data.length == "0" && (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <h1 className="text-muted">NO DATA</h1>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {data && data.length > 0 && (
  // <div className="tableEnd"></div>
    <div className="col-md-12 form-inline py-2 tableFooter">
      <div className="row align-items-center pageSet">
        <div className="col-md-4 d-flex gap-2 align-baseline">
          <label className="control-label col-md-4" style={{fontWeight:"700", paddingTop:"5px", paddingLeft:"25px"}}>
            Rows per page :
          </label>
          {/* <i className="pi pi-chevron-down" style={{ position: "absolute", left: "92%", top: "15%" }} /> */}
          <Form.Select
            className="form-control "
            onChange={(event) => SetRecPerPage(event.target.value)}
            value={recperpage}
            style={{border:"solid", borderWidth:"thin", color:"#000"}}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </Form.Select>
        </div>

        <div className="col-md-7 text-end">
          <Pagination
            total={totalData}
            showSizeChanger={false}
            current={activepage}
            size="small"
            pageSize={recperpage}
            onChange={(page, pageSize) => {
              SetActivePage(page);
            }}
          />
        </div>
      </div>
    </div>
  
)}

            </div>
          </Col>
        </Row>
      </Container>
      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "0",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "fit content",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 0.3s ease-in-out",
        }}
      >
        <FilterCareerTable />
      </div>
    </>
  );
};
export default NewCareerTable;
