import React, { useState,useEffect } from 'react'
import NsatAPI from '../../utils/api/NsatAPI';
import { Container, Row, Col } from 'react-bootstrap';
import {  Pagination,Badge } from "antd";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { DownloadOutlined } from "@ant-design/icons";
import { notify_error, notify_success } from "../Notify";

import {
  Image,
  Modal,
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
const Nsat = () => {
    const [loading, setLoading] = useState(false);

    const [nsatDetails,setNsatDetails] = useState([]);
    const [recperpage, SetRecPerPage] = useState(25);
    const [activepage, SetActivePage] = useState(1);
    const [totalData, setTotalData] = useState();
    const [search, SetSearch] = useState();
    const [isSearch, setIsSearch] = useState(false);
    const sno = recperpage * (activepage - 1);



    const NcrScholarship = async (params)=>{
        setLoading(true);

         NsatAPI.getNsatDetails(params).then((res)=>{
            if(res.data.status){
                setNsatDetails(res.data.data);
                setTotalData(res.data.total);
                setLoading(false);
            }else{
                setNsatDetails([]);
                setLoading(false);
            }
        })

    }

    useEffect(() => {
        NcrScholarship({ page: activepage, limit: recperpage });
    },[activepage, recperpage])
    


    const handleSearch = (e) => {
      if (e.key === "Enter" && search === "") {
        // If the search input is empty or the Enter key is pressed, perform a refresh.
        handleRefresh();
      }
      if (e.key === "Enter") {
        NsatAPI.SearchNsat(
          { search: search },
          activepage,
          recperpage
        ).then((res) => {
          setNsatDetails(res.data.data);
          setTotalData(res.data.total);
          SetActivePage(1);
          // console.log(res.data)
        });
      }
    };


    function handlePageChange(page) {
        SetActivePage(page);
        // getAllUllas({ page: page, limit: recperpage });
      }


const handleRefresh = () => {
  // Clear search
  SetSearch("");
  setIsSearch(false);
  // Fetch data again
  NcrScholarship({ page: activepage, limit: recperpage });
};

const handleSearchChange = (e) => {
  SetSearch(e.target.value);
};

function handleDownloadButtonClick() {
  NsatAPI.ExportData({ search: search }, activepage, recperpage)
    .then((response) => {
      if (response.data && response.data.link) {
        // Download the TXT file
        const txtLink = response.data.link;

        fetch(txtLink)
          .then((txtResponse) => txtResponse.text())
          .then((txtContent) => {
            // Convert TXT content to CSV format
            const csvContent = convertTxtToCsv(txtContent);

            // Create a Blob with the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv' });

            // Create a temporary link to trigger the download
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = URL.createObjectURL(blob);
            a.download = 'nsatstudents.csv';

            // Trigger the download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
          })
          .catch((txtError) => {
            notify_error('Error while fetching or converting TXT file:', txtError);
          });
      } else {
        notify_error('Invalid response format. Download link not found.');
      }
    })
    .catch((error) => {
      notify_error('Download error:', error);
    });
}

function convertTxtToCsv(txtContent) {
  // Implement the logic to convert the TXT content to CSV.
  // This depends on the structure and format of your TXT data.
  // You may need to split and process the text to create CSV rows and columns.
  // Here's a simple example assuming a space-separated format:

  const lines = txtContent.split('\n');
  const csvRows = [];

  for (const line of lines) {
    const columns = line.split(' '); // Change the separator based on your data format
    csvRows.push(columns.join(' '));
  }

  return csvRows.join('\n');
}




  return (
    <>
<Container  fluid
        className="p-0 "
        style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}>


<Row style={{marginInline:"10px"}}>
    <Col lg={12}>

{/* search and refresh */}
<div
                    lg={12}
                    className="bg-white mx-0 rounded"
                    style={{ display: "flex", justifyContent: "space-between",marginTop:"5rem" }}
                  >
                    {/* Search button */}
                    <div style={{ marginLeft: "12px", width: "25%" }}>
                      <InputGroup className="my-2" style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
                        <div
                          style={{
                            backgroundColor: "#F8F9FB",
                            paddingLeft: "11px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          className="d-flex justify-content-center align-items-center  "
                        >
                          <img src="/assets/images/dashboard/search.png"></img>
                        </div>
                        <Form.Control
                          placeholder="Search In NSAT Table by Name, Email and Mobile Number"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          name="search"
                          className=""
                          style={{
                            backgroundColor: "#F8F9FB",
                            border: "none",
                            fontFamily: "Inter",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                          }}
                          onChange={handleSearchChange}
                          value={search}
                          onKeyPress={handleSearch}
                        />
                      </InputGroup>
                    </div>

                    <div
                      className="p-0"
                      style={{
                        padding: "0px",
                        right: "0px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "flex-end",
                        marginRight: "12px",
                      }}
                    >

<div
                        style={{
                          height: "100% ",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="d-flex gap-3 "
                          style={{ padding: "0", marginLeft: "12px" }}
                        >
            
                          <div
                            style={{
                              width: "1px",
                              height: "full",
                              backgroundColor: "#E5E9EB",
                            }}
                          ></div>
                        </div>

                        <div
                          className="d-flex gap-3 "
                          style={{
                            padding: "0",
                            marginLeft: "12px",
                            height: "100%",
                          }}
                        >
                         
                         
                        </div>

                        <div
                          className="d-flex gap-3 "
                          style={{
                            padding: "8px ",
                            marginLeft: "12px",
                            height: "100%",
                          }}
                        >
                         <button icon={<DownloadOutlined />} style={{backgroundColor: "rgb(43, 115, 255)",
    border: "none",
    padding:"0px 20px",
    color: "white",
    fontWeight: "500",
    fontSize: "16px",
    borderRadius:"10px"}}
    onClick={()=>handleDownloadButtonClick()}
    >Export</button>
                        </div>
                      </div>





                      <div
                        style={{
                          height: "100% ",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="d-flex gap-3 "
                          style={{ padding: "0", marginLeft: "12px" }}
                        >
            
                          <div
                            style={{
                              width: "1px",
                              height: "full",
                              backgroundColor: "#E5E9EB",
                            }}
                          ></div>
                        </div>

                        <div
                          className="d-flex gap-3 "
                          style={{
                            padding: "0",
                            marginLeft: "12px",
                            height: "100%",
                          }}
                        >
                         
                         
                        </div>

                        <div
                          className="d-flex gap-3 "
                          style={{
                            padding: "0px",
                            marginLeft: "12px",
                            height: "100%",
                          }}
                        >
                          <RefreshOutlinedIcon
                            fontSize="large"
                            color="disabled"
                            className="my-auto"
                            cursor="pointer"
                            onClick={handleRefresh}
                          />
                        </div>
                      </div>
                    </div>


                  </div>

       <div className="py-2" style={{ marginTop: "50px"}}> 
            <div className="maintable p-0 " id="table-container" style={{ height: "820px" }}>
              <table className="col-md-12 enquiryTable ">
                <thead className="tableHeadAllEnquiry">
                  <tr className="tableHeading">
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Stream</th>
                    <th>Email Address</th>
                    <th>Mobile Number</th>
                    <th>affiliating Board</th>
                    <th>Class</th>
                    <th>City</th>
                   
                    <th></th>
                  </tr>
                </thead>

                <tbody className="mt-1">
                {nsatDetails && nsatDetails.length>0&&nsatDetails.map((item,index)=>{
return(
                      <tr key={index} className='tableRows'>
                      <td>
                                      {!item.repeat && (
                                        <span>{sno + index + 1}</span>
                                      )}
                                      {item.repeat && (
                                        <span
                                          style={{
                                            display: "flex",
                                            // position:'relative',
                                            justifyContent: "space-around",
                                            alignItems: "",
                                          }}
                                        >
                                          {sno + index + 1}
                                          <Badge.Ribbon
                                            style={{
                                              fontSize: "8px",
                                              right: "-20px",
                                              position: "absolute",
                                            }}
                                            color="#f50"
                                            placement="end"
                                            text="DUP"
                                          />
                                        </span>
                                      )}
                                    </td>
                            <td>{item.name}</td>
                            <td>{item.stream}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile_number}</td>
                            <td>{item.affiliating_board}</td>
                            <td>{item.class}</td>
                            <td>{item.city}</td>
                        
                      </tr>
)
                    })
                }
                </tbody>
                </table>
            </div>
        </div>
    </Col>
</Row>


        <div className="tableEnd"></div>
            <div className="col-md-12 form-inline py-2 tableFooter">
                <div className="row align-items-center  pageSet">
                  <div className="col-md-4 d-flex gap-2 align-baseline">
                    <label className="control-label col-md-4" style={{fontWeight:"700", paddingTop:"5px", paddingLeft:"25px"}}>
                      Rows per page :
                    </label>
                    {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                    <Form.Select
                      className="form-control "
                      // onChange={(event) => applyFilter(event)}
                      onChange={(event) => SetRecPerPage(event.target.value)}
                      value={recperpage}
                      style={{border:"solid", borderWidth:"thin", color:"#000"}}
                    >
                      {/* <option value="5">5</option> */}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </Form.Select>
                  </div>

                  <div className="col-md-7 text-end">
                  <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  SetActivePage(page);
                handlePageChange(page);
                }}
              />

                  </div>
                </div>
              </div>
              
</Container>


    </>
  )
}

export default Nsat;