import React, { useEffect, useState } from "react";
import { serverAddress } from "../../state/constants/content_types";
import LawAPI from "../../utils/api/LawAPI";

const StudentInfo = ({ data }) => {
  const [key, setKey] = useState("");

  useEffect(() => {
    // console.log(data);
    // console.log(key);
  }, [key]);

  return (
    <>
      {data && data._id && (
        <div className="lead-remarks mx-3" style={{ marginTop: "96px" }}>
          <div className="">
            <div
              className=""
              style={{
                fontSize: "22px",
                fontWeight: "500",
                paddingLeft: "22px",
              }}
            >
              PERSONAL INFORMATION
            </div>
            <div
              className=""
              style={{
                marginInline: "15px",
                marginTop: "5px",
                height: "2px",
                backgroundColor: "#E5E9EB",
                width: "97%",
              }}
            >
              {" "}
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <div className="spContent31 mx-3">
              <div className="lpContent32">Name :</div>
              <div className="lpContent33">
                {data.first_name} {data.last_name}
              </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Mobile :</div>
              <div className="lpContent33">{data?.mobile_no}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">E-mail :</div>
              <div className="lpContent33">{data?.email}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Institute Name :</div>
              <div className="lpContent33">{data?.institute}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Address :</div>
              <div className="lpContent33">
                {data?.city} - {data?.state}
              </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Nationality :</div>
              <div className="lpContent33">{`${data.nationality} -${
                data.country || "N/A"
              }`}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Category :</div>
              <div className="lpContent33">{data?.category}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Amount :</div>
              <div className="lpContent33">{data?.amount}</div>
            </div>
            <div className="spContent31 mx-3">
              <div className="lpContent32">Address:</div>
              <div className="lpContent33">{data?.shipping_address}</div>
            </div>

            {data.abstract !== "no file uploaded" && (
              <div className="spContent31 mx-3">
                <div className="lpContent32">Abstract :</div>
                <div className="lpContent33">
                  <span>
                    <a
                      target="_blank"
                      href={`${serverAddress}/job_application/preview?file_path=${data.abstract}`}
                    >
                      Download
                    </a>
                  </span>
                </div>
              </div>
            )}

            {data.co_authors.length > 0 &&
              JSON.parse(data.co_authors[0]).map((item, index) => (
                <React.Fragment key={index}>
                  <h5 className="ms-3">Co-Author: {index + 1}</h5>
                  {Object.entries(item).map(([key, value]) => (
                    <div className="spContent31 mx-3" key={key}>
                      <div className="lpContent32">{key.toUpperCase()} :</div>
                      <div className="lpContent33">{value}</div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
          </div>

          <div className="col-md-12 mt-3">
            <div className="spContent31 mx-3">
              <h5>Title Of Paper:</h5>
              <div className="lpContent33">{data?.title_of_paper}</div>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default StudentInfo;
