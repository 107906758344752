import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AlumniApi from "../../utils/api/AlumniAPI";
import { notify_error, notify_success } from "../Notify";
import AlumniProfile from "./alumniProfile";
import {
  Container,
  Row,
  Col,
  Image,
  Modal,
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import AlumniProgress from "./AlumniProgress";
import CreateEmployeeHis from "./CreateEmployeeHis";
import EditAlumni from "./EditAlumni";

const EmploymentHistory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [editalumniopen,seteditalumniopen] = useState(false);
  const [employeehistory, setEmployeeHistory] = useState([]);

  

  const handleEditDetails = (e) => {
    e.preventDefault();
    seteditalumniopen((prev) => !prev);
  };

  useEffect(() => {
    if (id) {
      AlumniApi.getSingleAlumni(id)
        .then((res) => {
          setData(res.data.data);
          setEmployeeHistory(res.data.data.employeeHistory);
        })
        .catch((err) => {
          notify_error(err.message || "Something Went Wrong");
        });
    }
  }, [id]);

  const handleEnquiryOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  return (
    <>
      <div
        className="container-fluid page-body-wrapper"
        style={{ fontFamily: "Inter", position: "relative" }}
      >
      <div className="" style={{ top: "128px", position: "absolute", left: "-27px", zIndex: "90" }}>
           <button className="" style={{ border: "none", background: "transparent", borderRadius: "50%" }}
             onClick={(e) => {
               e.preventDefault();
               e.stopPropagation();
               navigate(-1);
               // setIsBack(true);
               // handlePageChange(activepage);
             }}
           >
             <img width={"36px"} height={"36px"} src="/assets/images/dashboard/back-button.png" alt="Back" />
           </button>
         </div>
        <div className="enquirypersonDetail">
          <AlumniProfile data={data} />

<div className="edit-button-container">
<button className="edit-details-button" onClick={handleEditDetails}>Edit Details</button>

</div>


 
          {editalumniopen && <EditAlumni alumniID={id} data={data} editalumniopen={editalumniopen} seteditalumniopen={seteditalumniopen} />}
        </div>

        <div className="page-body-wrapper1">
          <AlumniProgress id={id} />

          <div className="lead-remarks-new mx-3">
            <Container
              fluid
              style={{ fontFamily: "Inter", backgroundColor: "#fff" }}
            >
              <Row style={{ textAlign: "center" }}>
                
               

              <div
                    lg={12}
                    className=" mx-0 my-3 rounded"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* Search button */} 

                    <div style={{ marginLeft: "12px", width: "25%" }}>
                      <InputGroup className="my-2">
                        <div
                          style={{
                            paddingLeft: "11px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          className="d-flex justify-content-center align-items-center  "
                        >

                    <h2> Employment History </h2> 

                        </div>
                       
                      </InputGroup>
                    </div>
                                        <div
                      className="p-0"
                      style={{
                        padding: "0px",
                        right: "0px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "flex-end",
                        marginRight: "12px",
                      }}
                    >
                    
                      <div
                        style={{
                          height: "100% ",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="d-flex gap-3" style={{ padding: "0" }}>
                          <button
                            onClick={handleEnquiryOpen}
                            className="px-4 my-2 py-2 "
                            style={{
                              backgroundColor: "white",
                              fontFamily: "Inter",
                              color: "#2B73FF",
                              borderColor: "#2B73FF",
                              borderRadius: "4px",
                              
                            }}
                          >
                            <span>Add employee history</span>
                          </button>
                          <div
                            style={{
                              width: "1px",
                              height: "full",
                              backgroundColor: "#E5E9EB",
                            }}
                          ></div>
                        </div>

                        <div
                          className="d-flex gap-3 "
                          style={{ padding: "0", marginLeft: "12px" }}
                        >
                

                          <div
                            style={{
                              width: "1px",
                              height: "full",
                              backgroundColor: "#E5E9EB",
                            }}
                          ></div>
                        </div>
                        </div>
                      </div>
                      </div>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="py-2" style={{ marginTop: "50px" }}>
                    <div
                      className="jobfairMaintable"
                      id="table-container"
                      style={{ height: "820px" }}
                    >
                      <table className="col-md-12 jobfairtable px-3">
                        <thead className="bg-transparent">
                          <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                            <th>S.No</th>
                            <th>Company Name</th>
                            <th>Designation</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>location</th>
                          </tr>
                        </thead>

                        <tbody>
                          {employeehistory &&
                            employeehistory.length > 0 &&
                            employeehistory.map((item, index) => {
                              const startDate = item.start_date ? item.start_date.split("T")[0] : "";
                              const endDate = item.end_date ? item.end_date.split("T")[0] : "";
                              return (
                                <tr className="tableRows">
                                  <td>{index + 1}</td>
                                  <td>{item.company_name}</td>
                                  <td>{item.designation}</td>
                                  <td>{startDate}</td>
                                  <td>{endDate}</td>
                                  <td>{item.location}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
             
            {open && <CreateEmployeeHis open={open} id={id} course="BALLB" setOpen={setOpen} />}

          </div>
        </div>
      </div>
    </>
  );
};

export default EmploymentHistory;
