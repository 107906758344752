import React, { useEffect, useState } from "react";
import LawAPI from "../../utils/api/LawAPI";

import {Container,Row,Col} from 'react-bootstrap';

const FeedbackInfoProfile = ({ data }) => {
  const [key, setKey] = useState("");

  useEffect(() => {
    // console.log(data);
    // console.log(key);
  }, [key]);
  function getPercentageRange(score) {
    switch (score) {
      case 5:
        return '85 to 100%';
      case 4:
        return '70 to 84%';
      case 3:
        return '55 to 69%';
      case 2:
        return '30 to 54%';
      case 1:
        return 'Below 30%';
      default:
        return 'Invalid Score';
    }
  }
  
  // Example usage
  // console.log(getPercentageRange(4));  // Output: '70 to 84%'
  // console.log(getPercentageRange(1));  // Output: 'Below 30%'
  // console.log(getPercentageRange(5));  // Output: '85 to 100%'
  // console.log(getPercentageRange(3));  // Output: '55 to 69%'
  // console.log(getPercentageRange(6));  // Output: 'Invalid Score'
  

  function feedbackTransform(param){
    if(param ==1){
      return 'Strongly Disagree'
    }
    if(param ==2){
      return 'Disagree'
    }
    if(param ==3){
      return 'Neutral'
    }
    if(param ==4){
      return 'Agree'
    }
    if(param ==5){
      return 'Strongly Agree'
    }
  }

  return (
    <>
      {data && data._id && (
        <div className="lead-remarks mx-3" style={{marginTop:"96px"}}>
          <div className="">
            <div
              className=""
              style={{ fontSize: "22px", fontWeight: "500", paddingLeft: "22px" }}
            >
              INFORMATION
            </div>
            <div
              className=""
              style={{
                marginInline: "15px",
                marginTop: "5px",
                height: "2px",
                backgroundColor: "#E5E9EB",
                width: "97%",
              }}
            ></div>
          </div>
          <div className="col-md-12 mt-3 ">
          {/*
            <div className="spContent31 mx-3">
              <div className="lpContent32">Name :</div>
              <div className="lpContent33">{data.name}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">E-mail :</div>
              <div className="lpContent33">{data?.email}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Campus :</div>
              <div className="lpContent33">{data?.campus}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">roll_no :</div>
              <div className="lpContent33">{data?.roll_no}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="lpContent32">Suggestions :</div>
              <div className="lpContent33">{data.other_suggestions}</div>
            </div>
 */}
            {data.feedback.length > 0 && data.feedbackType!='student_satisfaction' && (
                <>
                <Container fluid className="bg-transparent">
                  {data.feedback.map((item, index) => (
                    
                  
                    <Row  key={index}>
                    <Col lg={9}>
                    <p className="text-muted">{`${index+1}: ${data?.questionSet[index]}`}</p>
                    </Col>
                    <Col lg={3}>{feedbackTransform(item.rating)}</Col>
                    </Row>
           
                    ))}
                    </Container>
                </>
              )}
              {data.feedback.length > 0 && data.feedbackType=='student_satisfaction' && (
                <>
                  <Container fluid className="bg-transparent">
                    {data.feedback.map((item, index) => (
                      <Row key={index}>
                        <Col lg={9}>
                          {index === 0 ? (
                            // Handle the first item differently
                            <p className="text-muted">{`${index + 1}: ${data?.questionSet[index]}`}</p>
                          ) : (
                            // Handle the rest of the items
                            <p className="text-muted">{`${index + 1}: ${data?.questionSet[index]}`}</p>
                          )}
                        </Col>
                        {index == 0 ? (
                          <Col lg={3}>{getPercentageRange(item.rating)}</Col>
                        ):(<Col lg={3}>{feedbackTransform(item.rating)}</Col>)}
                        
                      </Row>
                    ))}
                  </Container>
                </>
              )}
              


                  {data.feedbackType !='student_feedback' && data.feedbackType!='student_satisfaction' && data.other_suggestions && (
                    <Container fluid className="bg-transparent">
                    <Row>
                    <Col><h6 className='border-bottom text-success'>OTHER SUGGESTIONS :</h6></Col>
                    </Row>
                      <Row>
                    <Col><p className="text-muted">{data.other_suggestions}</p></Col>
                    </Row>
                    </Container>
                  )}
                  {data.feedbackType =='student_satisfaction' && data.other_suggestions && (
                    <Container fluid className="bg-transparent">
                    <Row>
                    <Col><h6 className='border-bottom text-success'>CURRICULUM SUGGESTIONS :</h6></Col>
                    </Row>
                      <Row>
                    <Col><p className="text-muted">{data.other_suggestions}</p></Col>
                    </Row>
                    </Container>
                  )}
       {data.feedbackType =='student_feedback' && data.courses_suggestions && (
                    <Container fluid className="bg-transparent">
                    <Row>
                    <Col><h6 className='border-bottom text-success'>COURSES SUGGESTIONS :</h6></Col>
                    </Row>
                      <Row>
                    <Col><p className="text-muted">{data.courses_suggestions}</p></Col>
                    </Row>
                    </Container>
                  )}
                   {data.feedbackType =='student_feedback' && data.curriculum_suggestions && (
                    <Container fluid className="bg-transparent">
                    <Row>
                    <Col><h6 className='border-bottom text-success'>CURRICULUM SUGGESTIONS :</h6></Col>
                    </Row>
                      <Row>
                    <Col><p className="text-muted">{data.curriculum_suggestions}</p></Col>
                    </Row>
                    </Container>
                  )}
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackInfoProfile;

{/*
<div className="spContent31 mx-3 d-flex align-items-center"  key={index}>
<div className="lpContent32 " style={{whiteSpace:'nowrap'}}>{`${index+1}: ${data?.questionSet[index=}`}</div>

<div className="lpContent33 ms-5" style={{float:'right'}}>{item.rating}</div>
</div>
*/}