import React, { useEffect, useState } from "react";

const AlumniProfile = ({ data }) => {
  const [isMounted, setIsMounted] = useState(true);
  const [newDob, setnewDob] = useState();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note that months are zero-based, so we add 1
    const year = date.getFullYear().toString(); // Get the last two digits of the year
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    setIsMounted(true);
    const newDAte = formatDate(data.dob);
    setnewDob(newDAte);
    return () => {
      setIsMounted(false);
    };
  }, [data]);

  function getFirstWords(name) {
  if(name){

    const words = name?name.split(" "):""; 
   
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords; 
  
  }
  
  }

  function capitalizeWords(name) {
    if(name){
      const words = name.split(" ");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(" ");
    }
  
  }

  return (
    <>
      {isMounted && data && data._id && (
        <div className="spWrapper" style={{ height: "535px" }}>
          <div className="spContent">
            <div
              style={{
                fontSize: "large",
                fontWeight: "400",
                lineHeight: "21px",
                color: "#2B73FF",
                border: "2px",
                borderStyle: "none",
                padding: "14px",
                borderRadius: "100%",
                backgroundColor: "#F1EDFF",
                marginBottom: "13px",
                marginTop: "10px",
              }}
            >
              {getFirstWords(data.name)}
            </div>

            <div className="spContent02">{capitalizeWords(data.name)}</div>
            <div className="spContent03">+91{data.mobile_number}</div>
            <br />
          </div>

          <div className="spContent2"></div>

          <div className="spContent3">
            <div className="spContent31 mx-3">
              <div className="spContent32">Program :</div>
              <div className="spContent33">
                {data?.program ? data.program.toUpperCase() : "N/A"}
              </div>
            </div>

            {data?.email && (
              <div className="spContent31 mx-3">
                <div className="spContent32">E-mail :</div>
                <div className="spContent33">{data.email_address}</div>
              </div>
            )}

            {data?.mobile_number && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Mobile Number :</div>
                <div className="spContent33">{data?.mobile_number}</div>
              </div>
            )}

            {data?.city && (
              <div className="spContent31 mx-3">
                <div className="spContent32">location :</div>
                <div className="spContent33">
                  {data?.city ? data.city.toUpperCase() : "N/A"}
                </div>
              </div>
            )}

            {data?.company && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Company :</div>
                <div className="spContent33">
                  {data?.company ? data.company.toUpperCase() : "N/A"}
                </div>
              </div>
            )}

            {data?.email_address && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Email :</div>
                <div className="spContent33">
                  {data?.email_address
                    ? data.email_address.toUpperCase()
                    : "N/A"}
                </div>
              </div>
            )}

            {data?.designation && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Designation :</div>
                <div className="spContent33">
                  {data?.designation
                    ? data.designation.toUpperCase()
                    : "N/A"}
                </div>
              </div>
            )}

            {data?.dob && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Date-Of-Birth :</div>
                <div className="spContent33">{newDob}</div>
              </div>
            )}

            {data?.batch && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Batch :</div>
                <div className="spContent33">{data.batch}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AlumniProfile;
