import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import {} from "date-fns/locale";
import { Pagination,Modal } from "antd";
import JobFairApi from "../../utils/api/JobFairApi";
import { serverAddress } from "../../constant";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { DownloadOutlined } from "@ant-design/icons";
import { notify_error, notify_success } from "../Notify";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import { useDispatch } from "react-redux";
import {
  Image,
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {Badge } from "antd";

const JobFairDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { top_loader } = bindActionCreators(actionCreators, dispatch);

  const [loading, setLoading] = useState(false);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [search, SetSearch] = useState();
  const sno1 = recperpage * (activepage - 1);
  const sno2 = recperpage * (activepage - 1);
  const sno3 = recperpage * (activepage - 1);

    // Retrieve the active tab from localStorage or default to "link-0"
    const initialActiveTab = localStorage.getItem("activeTab") || "link-0";
    const [activeTab, setActiveTab] = useState(initialActiveTab);
  
    const [reqforToggler, setreqforToggler] = useState(1);


  const [Compdata, setCompDate] = useState([]);
  const [ipemStudent, setIpemStudent] = useState([]);
  const [ExternalStudents, setExternalStudents] = useState([]);

   
  const [candidateId, setCandidateId] = useState("");
  const [open, setOpen] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);





  const handleTabChange = (key) => {
    setActiveTab(key);
    localStorage.setItem("activeTab", key); // Save the active tab in localStorage

    if (key === "link-0") {
      setreqforToggler(0);
    } else if (key === "link-1") {
      setreqforToggler(1);
    } else if (key === "link-2") {
      setreqforToggler(2);
    }

  };



  const getCompanyDetails = async (params) => {
    setLoading(true);
    JobFairApi.getallCompanyRegistration(params).then((result) => {
      if (result.data.status) {
        // console.log(result.data);
        setCompDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        // // console.log(data);
      } else {
        setCompDate([]);
        setLoading(false);
      }
    });
  };

  const getStudentDetails = async (params) => {
    setLoading(true);
    JobFairApi.getAllStudentRegistration(params).then((result) => {
      if (result.data.status) {
        // console.log(result.data);
        setIpemStudent(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
      } else {
        setIpemStudent([]);
        setLoading(false);
      }
    });
  };

  const getStudentExternalDetails = async (params) => {
    setLoading(true);
    JobFairApi.getExternalStudentRegistration(params).then((result) => {
      if (result.data.status) {
        console.log(result.data);
        setExternalStudents(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
      } else {
        setIpemStudent([]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    let fetchDataFunction = null;
  
    switch (activeTab) {
      case 'link-0':
        setreqforToggler(0);
        fetchDataFunction = getStudentDetails;
        break;
      case 'link-1':
        setreqforToggler(1);
        fetchDataFunction = getStudentExternalDetails;
        break;
      case 'link-2':
        setreqforToggler(2);
        fetchDataFunction = getCompanyDetails;
        break;
      default:
        break;
    }
  
    if (fetchDataFunction) {
      fetchDataFunction({ page: activepage, limit: recperpage });
    }
  }, [activepage, recperpage, activeTab]);


  function handlePageChange(page) {
    SetActivePage(page);
    // getAllUllas({ page: page, limit: recperpage });
  }

  // ipem student search

  const handleipemstudentSearch = (e) => {
    if (e.key === "Enter" && search === "") {
      // If the search input is empty or the Enter key is pressed, perform a refresh.
      handleRefresh();
    }
    if (e.key === "Enter") {
      JobFairApi.SearchIpemStudent(
        { search: search },
        activepage,
        recperpage
      ).then((res) => {
        setIpemStudent(res.data.data);
        setTotalData(res.data.total);
        SetActivePage(1);
        // console.log(res.data)
      });
    }
  };

  //ipem external student search
  const handleExternalStudentSearch = (e) => {
    if (e.key === "Enter" && search === "") {
      // If the search input is empty or the Enter key is pressed, perform a refresh.
      handleRefresh();
    }
    if (e.key === "Enter") {
      JobFairApi.SearchExternalStudent(
        { search: search },
        activepage,
        recperpage
      ).then((res) => {
        setExternalStudents(res.data.data);
        setTotalData(res.data.total);
        SetActivePage(1);
        // console.log(res.data)
      });
    }
  };

  // company search
  const handleCompanySearch = (e) => {
    if (e.key === "Enter" && search === "") {
      // If the search input is empty or the Enter key is pressed, perform a refresh.
      handleRefresh();
    }
    if (e.key === "Enter") {
      JobFairApi.SearchJobfairCompany(
        { search: search },
        activepage,
        recperpage
      ).then((res) => {
        setCompDate(res.data.data);
        setTotalData(res.data.total);
        SetActivePage(1);
        // console.log(res.data)
      });
    }
  };

  const handleSearchChange = (e) => {
    SetSearch(e.target.value);
  };

  const handleRefresh = () => {
    // Clear search
    SetSearch("");
    // setIsSearch(false);
    // Fetch data again
    getStudentDetails({ page: activepage, limit: recperpage });
    getCompanyDetails({ page: activepage, limit: recperpage });
    getStudentExternalDetails({ page: activepage, limit: recperpage });
  };

  function handleIpenStudentDownload() {
    JobFairApi.ExportIpemStudent({ search: search }, activepage, recperpage)
      .then((response) => {
        if (response.data && response.data.link) {
          // Download the TXT file
          const txtLink = response.data.link;

          fetch(txtLink)
            .then((txtResponse) => txtResponse.text())
            .then((txtContent) => {
              // Convert TXT content to CSV format
              const csvContent = convertTxtToCsv(txtContent);

              // Create a Blob with the CSV content
              const blob = new Blob([csvContent], { type: "text/csv" });

              // Create a temporary link to trigger the download
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              a.download = "ipemStudent.csv";

              // Trigger the download
              document.body.appendChild(a);
              a.click();

              // Clean up
              document.body.removeChild(a);
            })
            .catch((txtError) => {
              notify_error(
                "Error while fetching or converting TXT file:",
                txtError
              );
            });
        } else {
          notify_error("Invalid response format. Download link not found.");
        }
      })
      .catch((error) => {
        notify_error("Download error:", error);
      });
  }

  function handleExternalStudentDownload() {
    JobFairApi.ExportexternalStudent({ search: search }, activepage, recperpage)
      .then((response) => {
        if (response.data && response.data.link) {
          // Download the TXT file
          const txtLink = response.data.link;

          fetch(txtLink)
            .then((txtResponse) => txtResponse.text())
            .then((txtContent) => {
              // Convert TXT content to CSV format
              const csvContent = convertTxtToCsv(txtContent);

              // Create a Blob with the CSV content
              const blob = new Blob([csvContent], { type: "text/csv" });

              // Create a temporary link to trigger the download
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              a.download = "externalStudents.csv";

              // Trigger the download
              document.body.appendChild(a);
              a.click();

              // Clean up
              document.body.removeChild(a);
            })
            .catch((txtError) => {
              notify_error(
                "Error while fetching or converting TXT file:",
                txtError
              );
            });
        } else {
          notify_error("Invalid response format. Download link not found.");
        }
      })
      .catch((error) => {
        notify_error("Download error:", error);
      });
  }

  function handleCompanyDownload() {
    JobFairApi.ExportCompany({ search: search }, activepage, recperpage)
      .then((response) => {
        if (response.data && response.data.link) {
          // Download the TXT file
          const txtLink = response.data.link;

          fetch(txtLink)
            .then((txtResponse) => txtResponse.text())
            .then((txtContent) => {
              // Convert TXT content to CSV format
              const csvContent = convertTxtToCsv(txtContent);

              // Create a Blob with the CSV content
              const blob = new Blob([csvContent], { type: "text/csv" });

              // Create a temporary link to trigger the download
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              a.download = "Jobfaircompany.csv";

              // Trigger the download
              document.body.appendChild(a);
              a.click();

              // Clean up
              document.body.removeChild(a);
            })
            .catch((txtError) => {
              notify_error(
                "Error while fetching or converting TXT file:",
                txtError
              );
            });
        } else {
          notify_error("Invalid response format. Download link not found.");
        }
      })
      .catch((error) => {
        notify_error("Download error:", error);
      });
  }

  function convertTxtToCsv(txtContent) {
    // Implement the logic to convert the TXT content to CSV.
    // This depends on the structure and format of your TXT data.
    // You may need to split and process the text to create CSV rows and columns.
    // Here's a simple example assuming a space-separated format:

    const lines = txtContent.split("\n");
    const csvRows = [];

    for (const line of lines) {
      const columns = line.split(" "); // Change the separator based on your data format
      csvRows.push(columns.join(" "));
    }

    return csvRows.join("\n");
  }


  const handleCancel = () => {
    setOpen(false);
  };


  const handleOk = () => {

    console.log(candidateId,transactionId);

    JobFairApi.confirmPaymentLaw(candidateId, transactionId)
    .then((result) => {
        top_loader(true, 45);
        getStudentExternalDetails({ page: activepage, limit: recperpage });

        setCandidateId("");
        setTransactionId("");
        notify_success("Student Payment Status Updated Successfully.");
        setOpen(false);
    })
    .catch((err) => {
        notify_error(err?.message || err);
    });
  };


  return (
    <>
      <Container  fluid
        className="p-0 "
        style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}>
        <Row>
           <Nav
            id="wrapper"
            style={{
              overflow: "hidden",
              borderBottom: "2px solid #070261 ",
              borderTop: "2px solid #eee !important",
              marginTop: "6rem ",
            }}
            fill
            defaultActiveKey={activeTab}
            onSelect={handleTabChange}
          >
            <Nav.Item style={{ overflow: "hidden" }}>
              <Nav.Link
                className="company-registration"
                eventKey="link-1"
              >
                External Student Registration
              </Nav.Link>
            </Nav.Item>
            <Nav.Item style={{ overflow: "hidden" }}>
              <Nav.Link
                className="student-registration"
                eventKey="link-0"
              >
                Student Registration
              </Nav.Link>
            </Nav.Item>
            <Nav.Item style={{ overflow: "hidden" }}>
              <Nav.Link
                className="External-students"
                eventKey="link-2"
              >
                Company Registration
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
        {/* company's registration */}

        {reqforToggler == 1 ? (
          <Row style={{ marginInline: "10px" }}>
            <Col lg={12}>
              {/* Search , export and refresh */}

              <div
                lg={12}
                className="bg-white mx-0 rounded"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup className="my-2" style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In Job-Fair Table by Name ,Email and Contact Number"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleExternalStudentSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{ padding: "0", marginLeft: "12px" }}
                    >
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    ></div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "8px ",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <button
                        icon={<DownloadOutlined />}
                        style={{
                          backgroundColor: "rgb(43, 115, 255)",
                          border: "none",
                          padding: "0px 20px",
                          color: "white",
                          fontWeight: "500",
                          fontSize: "16px",
                          borderRadius: "10px",
                        }}
                        onClick={() => handleExternalStudentDownload()}
                      >
                        Export
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{ padding: "0", marginLeft: "12px" }}
                    >
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    ></div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="jobfair-Headings">
                {" "}
                <h2>External Student Registration</h2>
              </div>

              <div className="py-2" style={{ marginTop: "80px" }}>
                <div className="maintable "
                id="table-container"
                >
                  <table className="col-md-12 enquiryTable ">
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No</th>
                        <th>Student name</th>
                        <th>Selected stream</th>
                        <th>Passing year </th>
                        <th>Email address</th>
                        <th>Father's name</th>
                        <th>Contact number</th>
                        <th>College</th>
                        <th>Student id</th>
                      </tr>
                    </thead>

                    <tbody className="mt-1">
                      {ExternalStudents &&
                        ExternalStudents.length > 0 &&
                        ExternalStudents.map((item, index) => {
                          return (
                            <tr className="tableRows">
                            <td>
                                      {!item.repeat && (
                                        <span>{sno1 + index + 1}</span>
                                      )}
                                      {item.repeat && (
                                        <span
                                          style={{
                                            display: "flex",
                                            // position:'relative',
                                            justifyContent: "space-around",
                                            alignItems: "",
                                          }}
                                        >
                                          {sno1 + index + 1}
                                          <Badge.Ribbon
                                            style={{
                                              fontSize: "8px",
                                              right: "-20px",
                                              position: "absolute",
                                            }}
                                            color="#f50"
                                            placement="end"
                                            text="DUP"
                                          />
                                        </span>
                                      )}
                                    </td>
                              <td>{item.studentName}</td>
                              <td>{item.selectStream}</td>
                              <td>{item.passingYear}</td>
                              <td>{item.email_address}</td>
                              <td>{item.fatherName}</td>
                              <td>{item.studentContactNumber}</td>
                              <td>{item.collegeName}</td>
                              <td>
                                {/* Add a button to trigger the download */}
                                <button
                                  style={{
                                    backgroundColor: "#2B73FF",
                                    border: "none",
                                    color: "white",
                                    fontWeight: "400",
                                    fontSize: "13px",
                                    lineHeight: "35px",
                                    borderRadius: "4px",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(
                                      `${serverAddress}/jobfair/registration/external-student/get/preview?file_path=${item.studentIDCard}`
                                    );
                                  }}
                                >
                                  Preview
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        ) : reqforToggler == 0 ? (
          <Row style={{ marginInline: "10px" }}>
            <Col lg={12}>
              {/* Search , export and refresh */}
              <div
                lg={12}
                className="bg-white mx-0 rounded"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup className="my-2"  style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In Job-Fair Table by Name ,Email and Contact Number"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleipemstudentSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{ padding: "0", marginLeft: "12px" }}
                    >
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    ></div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "8px ",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <button
                        icon={<DownloadOutlined />}
                        style={{
                          backgroundColor: "rgb(43, 115, 255)",
                          border: "none",
                          padding: "0px 20px",
                          color: "white",
                          fontWeight: "500",
                          fontSize: "16px",
                          borderRadius: "10px",
                        }}
                        onClick={() => handleIpenStudentDownload()}
                      >
                        Export
                      </button>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{ padding: "0", marginLeft: "12px" }}
                    >
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    ></div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="jobfair-Headings">
                {" "}
                <h2> Student Registration</h2>
              </div>{" "}
              <div className="py-2" style={{ marginTop: "50px" }}>
                <div className="maintable p-0 " id="table-container">
                  <table className="col-md-12 enquiryTable">
                    <thead className="tableHeadAllEnquiry">
                      <tr className="tableHeading">
                        <th>S.No</th>
                        <th>Student name</th>
                        <th>Selected stream</th>
                        <th>Passing year </th>
                        <th>Email address</th>
                        <th>Father's name</th>
                        <th>Student contact</th>

                        <th>Student id</th>
                      </tr>
                    </thead>

                    <tbody className="mt-1">
                      {ipemStudent &&
                        ipemStudent.length > 0 &&
                        ipemStudent.map((item, index) => {
                          return (
                            <tr className="tableRows">
                            <td>
                                      {!item.repeat && (
                                        <span>{sno2 + index + 1}</span>
                                      )}
                                      {item.repeat && (
                                        <span
                                          style={{
                                            display: "flex",
                                            // position:'relative',
                                            justifyContent: "space-around",
                                            alignItems: "",
                                          }}
                                        >
                                          {sno2 + index + 1}
                                          <Badge.Ribbon
                                            style={{
                                              fontSize: "8px",
                                              right: "-20px",
                                              position: "absolute",
                                            }}
                                            color="#f50"
                                            placement="end"
                                            text="DUP"
                                          />
                                        </span>
                                      )}
                                    </td>
                              <td>{item.studentName}</td>
                              <td>{item.selectStream}</td>
                              <td>{item.passingYear}</td>
                              <td>{item.email_address}</td>
                              <td>{item.fatherName}</td>
                              <td>{item.studentContactNumber}</td>
                              {/* <td>{item.studentIDCard}</td> */}
                              <button
                                style={{
                                  backgroundColor: "#2B73FF",
                                  border: "none",
                                  color: "white",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  lineHeight: "35px",
                                  borderRadius: "4px",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  window.open(
                                    `${serverAddress}/jobfair/registration/ipem-student/get/preview?file_path=${item.studentIDCard}`
                                  );
                                }}
                              >
                                Preview
                              </button>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        ) : reqforToggler == 2 ? (
          <Row style={{ marginInline: "10px" }}>
            <Col lg={12}>
              {/* Search , export and refresh */}
              <div
                lg={12}
                className="bg-white mx-0 rounded"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup className="my-2" style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In Job-Fair Table by Name ,Email and Contact Number"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleCompanySearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{ padding: "0", marginLeft: "12px" }}
                    >
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    ></div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "8px ",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <button
                        icon={<DownloadOutlined />}
                        style={{
                          backgroundColor: "rgb(43, 115, 255)",
                          border: "none",
                          padding: "0px 20px",
                          color: "white",
                          fontWeight: "500",
                          fontSize: "16px",
                          borderRadius: "10px",
                        }}
                        onClick={() => handleCompanyDownload()}
                      >
                        Export
                      </button>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="jobfair-Headings">
                {" "}
                <h2> Company Registration</h2>
              </div>{" "}
              <div className="py-2" style={{ marginTop: "10px" }}>
                <div className="maintable p-0 " id="table-container">
                  <table className="col-md-12 enquiryTable ">
                    <thead className="tableHeadAllEnquiry">
                      <tr className="tableHeading">
                        <th>S.No</th>
                        <th>Organization name</th>
                        <th>Organization address</th>
                        <th>Email address</th>
                        <th>Contact person</th>
                        <th>Role hiring</th>
                        <th>Technologies required</th>
                        <th>Contact number</th>
                        <th>Job description</th>
                      </tr>
                    </thead>

                    <tbody className="mt-1">
                      {Compdata &&
                        Compdata.length > 0 &&
                        Compdata.map((item, index) => {
                          return (
                            <tr className="tableRows">
                            <td>
                                      {!item.repeat && (
                                        <span>{sno3 + index + 1}</span>
                                      )}
                                      {item.repeat && (
                                        <span
                                          style={{
                                            display: "flex",
                                            // position:'relative',
                                            justifyContent: "space-around",
                                            alignItems: "",
                                          }}
                                        >
                                          {sno3 + index + 1}
                                          <Badge.Ribbon
                                            style={{
                                              fontSize: "8px",
                                              right: "-20px",
                                              position: "absolute",
                                            }}
                                            color="#f50"
                                            placement="end"
                                            text="DUP"
                                          />
                                        </span>
                                      )}
                                    </td>


                              <td>{item.organizationName}</td>
                              <td>{item.organizationAddress}</td>
                              <td>{item.emailAddress}</td>
                              <td>{item.contactPersonName}</td>
                              <td>{item.roleHiring}</td>
                              <td>{item.technologiesRequired}</td>
                              <td>{item.contactNumber}</td>
                              {/* <td>{item.jobDescription}</td> */}
                              <button
                                style={{
                                  backgroundColor: "#2B73FF",
                                  border: "none",
                                  color: "white",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  lineHeight: "35px",
                                  borderRadius: "4px",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  window.open(
                                    `${serverAddress}/jobfair/registration/company/get/preview?file_path=${item.jobDescription}`
                                  );
                                }}
                              >
                                Preview
                              </button>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )
        
        }

        {/* <div className="tableEnd"></div> */}
          <div className="col-md-12 form-inline py-2 tableFooter">
            <div className="row align-items-center  pageSet">
              <div className="col-md-4 d-flex gap-2 align-baseline">
                <label className="control-label col-md-4" style={{fontWeight:"700", paddingTop:"5px", paddingLeft:"25px"}}>
                  Rows per page :
                </label>
                <Form.Select
                  className="form-control "
                  // onChange={(event) => applyFilter(event)}
                  onChange={(event) => SetRecPerPage(event.target.value)}
                  value={recperpage}
                  style={{border:"solid", borderWidth:"thin", color:"#000"}}
                >
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </Form.Select>
              </div>

              <div className="col-md-7 text-end">
                <Pagination
                  total={totalData}
                  showSizeChanger={false}
                  size="small"
                  // showQuickJumper
                  pageSize={recperpage}
                  onChange={(page, pageSize) => {
                    SetActivePage(page);
                    handlePageChange(page);
                  }}
                />
              </div>
            </div>
          </div>
        
        <Modal
          style={{ fontFamily: "Inter" }}
          open={open}
    footer={null}
          onOk={() => handleOk(false)}
 onCancel={() => handleCancel(false)}
        >
        <div className="mb-3  col-md-10 mx-auto bg-transparent">
        <h2
              style={{
                fontFamily: "Inter",
                fontSize: "24px",
                lineHeight: "38px",
                fontWeight: "700",
              }}
            >
              Transaction Id Verification
            </h2>
          <div className="form-group mt-4">
            <label htmlFor="last_name">Transaction Id</label>
            <input
              type="text"
              
              style={{ border: "2px solid", borderRadius: "12px" }}
              className="form-control border-secondary"
              id="transactionId"
              name="transactionId"
              placeholder="Please Enter Transaction Id"
              // onChange={handleOnChange}
              value={transactionId}
            />
            <span id="show_name" class="err">
              Please Enter Last Name
            </span>
          </div>

          <div className="text-center">
                <button
                  className=" py-2 "
                  onClick={handleOk}
                  style={{
                    backgroundColor: "#2B73FF",
                    borderRadius: "12px",
                    color: "white",
                    fontFamily: "Inter",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline:"40px"
                  }}
                >
                  Submit
                </button>
              </div>
        </div>
          
        </Modal>
      </Container>
    </>
  );
};

export default JobFairDetails;
