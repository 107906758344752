import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ url }) => {
  return (
    <div style={{ width: '100%', height: '700px', overflow: 'scroll',borderBlock:'40px solid black',borderInline:'40px solid black', display:'flex',justifyContent:'center' }}>
      {url && (
        <Document file={url}>
          <Page pageNumber={1} width={800} />
        </Document>
      )}
    </div>
  );
};

export default PDFViewer;