import React, { useState } from 'react';
import { Modal, Input, DatePicker, Button } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import { notify_error, notify_success } from '../Notify';
import AlumniApi from '../../utils/api/AlumniAPI';

function EditAlumni({ alumniID, editalumniopen, seteditalumniopen, data }) {
  const [newAlumni, setNewAlumni] = useState({ ...data });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAlumni({ ...newAlumni, [name]: value });
  };

  const handleDateChange = (date, dateString) => {
    setNewAlumni({ ...newAlumni, dob: dateString });
  };

  const handleEditDetailsCancel = () => {
    seteditalumniopen(false);
  };

  const handleEnquiryOk = async () => {  
    
    setLoading(true);

    const updatedData =  { ...newAlumni };
   await AlumniApi.UpdateAlumni(alumniID, updatedData).then((result) => {
      if(result.status) {
        console.log(result.data.message);
       notify_success(result.data.message);
        seteditalumniopen(false);
        setLoading(false);
        resetAlummniFormData();
        window.location.reload();



      } else {
        notify_error(result.data.message);
        setLoading({...data});
      }
    });
  };


  const resetAlummniFormData=()=>{
    setNewAlumni(
  {...data}
    );



  }
  return (
    <Modal
      visible={editalumniopen}
      footer={null}
      onCancel={handleEditDetailsCancel}
      style={{
        marginBlock: 'auto',
        paddingTop: '20px',
        borderRadius: '44px',
        fontFamily: 'Inter',
      }}
    >
      <h2
        style={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontSize: '32px',
          lineHeight: '38px',
          fontWeight: '700',
        }}
      >
        Edit Alumni Data
      </h2>
      <div className="row">
        <div className="mb-3 col-md-6 mx-auto bg-transparent">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Input
              type="text"
              name="name"
              placeholder="Enter name"
              onChange={handleInputChange}
              value={newAlumni.name}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              name="email_address"
              placeholder="Enter email"
              onChange={handleInputChange}
              value={newAlumni.email_address}
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <Input
              type="text"
              name="mobile_number"
              placeholder="Mobile number"
              onChange={handleInputChange}
              value={newAlumni.mobile_number}
            />
          </div>

          <div className="form-group">
            <label htmlFor="program">Program</label>
            <Input
              type="text"
              name="program"
              placeholder="Enter program"
              onChange={handleInputChange}
              value={newAlumni.program}
            />
          </div>

          <div className="form-group">
            <label htmlFor="dob">Date of Birth</label>
            <DatePicker
              name="dob"
              value={newAlumni.dob ? moment(newAlumni.dob) : null}
              onChange={handleDateChange}
            />
          </div>
        </div>



        <div className="mb-3 col-md-6 mx-auto bg-transparent">
         



          <div className="form-group">
            <label htmlFor="company">Company</label>
            <Input
              type="text"
              name="company"
              placeholder="Enter company"
              onChange={handleInputChange}
              value={newAlumni.company}
            />
          </div>

          <div className="form-group">
            <label htmlFor="designation">Designation</label>
            <Input
              type="text"
              name="designation"
              placeholder="Enter designation"
              onChange={handleInputChange}
              value={newAlumni.designation}
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">City</label>
            <Input
              type="text"
              name="city"
              placeholder="Enter city"
              onChange={handleInputChange}
              value={newAlumni.city}
            />
          </div>

          <div className="form-group">
            <label htmlFor="state">State</label>
            <Input
              type="text"
              name="state"
              placeholder="Enter state"
              onChange={handleInputChange}
              value={newAlumni.state}
            />
          </div>

          <div className="form-group">
            <label htmlFor="batch">Batch</label>
            <Input
              type="text"
              name="batch"
              placeholder="Enter batch"
              onChange={handleInputChange}
              value={newAlumni.batch}
            />
          </div>
        </div>


      </div>

      <div className="row">
    <div className="d-flex gap-3 align-items-center justify-content-center">
            <div className="">
              <button
                onClick={handleEditDetailsCancel}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '3px',
                  color: 'black',
                  fontFamily: 'Inter',
                  border: '2px solid',
                  fontSize: '15px',
                  padding:"6px 40px"
                }}
              >
                Cancel
              </button>
            </div>

            <div className="">
                <button
                  className=" py-2"
                  onClick={handleEnquiryOk}
                  style={{
                    backgroundColor: "#2B73FF",
                    borderRadius: "3px",
                    color: "white",
                    fontFamily: "Inter",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline:"40px"
                  }}
                >
                  Submit
                </button>
              </div>
            </div>

    </div>
    </Modal>
  );
}

export default EditAlumni;
