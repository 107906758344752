import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import NationalSeminarAPI from "../../utils/api/NationalSeminarAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
import { NationalSeminarContext } from "../../state/context/NationalSeminarProvider";
import GradingIcon from "@mui/icons-material/Grading";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { notify_error, notify_success } from "../Notify";
import { useRef } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import NationalSeminarFilter from "./NationalSeminarFilter";
import axios from "axios";

const NationalSeminarTable = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api-dev.assertit.io/ipem/national/seminar/get/all"
        );
        setData(response);
        console.log("31", data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
  

  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup
                    className="my-2"
                    style={{
                      border: "0.1px solid",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In National Seminar Table by Name , Email , Mobile"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      //   onChange={handleSearchChange}
                      //   value={search}
                      //   onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <FilterAltOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        // onClick={toggleFilterPanel}
                      />
                      <div
                        style={{
                          width: "1px",
                          height: "12/12",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        // onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="maintable "
                id="table-container"
                style={{ height: "820px" }}
              >
                <table className="col-md-12 enquiryTable px-3">
                  <thead className="bg-transparent ">
                    <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th style={{ width: "20%" }}>Institute</th>

                      <th style={{ width: "10%" }}>Payment Status</th>
                      <th style={{ width: "15%" }}>Action</th>
                      <th>Created At</th>
                    </tr>
                  </thead>

                  {/* <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                            <tr
                              className="tableRows "
                              key={item._id}
                              // onClick={() =>
                              //   navigate(`/dashboard/icds-profile/${item._id}`)
                              // }
                            >
                              <td>{sno + index + 1}</td>
                              <td>{`${item.first_name} ${item.last_name}`}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile_no}</td>
                              <td className="">{item.institute}</td>
                              {item.paymentStatus === false && (
                                <td className="">
                                  <span
                                    className=""
                                    style={{
                                      border: "1px solid #FFE3E0",
                                      backgroundColor: "#FFE3E0",
                                      color: "#F7685B",
                                      paddingTop: "3px",
                                      paddingBottom: "3px",
                                      paddingLeft: "13px",
                                      paddingRight: "13px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Not Paid
                                  </span>
                                </td>
                              )}
                              {item.paymentStatus === true && (
                                <td className="">
                                  <span
                                    className=""
                                    style={{
                                      border: "1px solid #CAEEDB",
                                      backgroundColor: "#CAEEDB",
                                      color: "#46CF85",
                                      paddingTop: "3px",
                                      paddingBottom: "3px",
                                      paddingLeft: "5px",
                                      paddingRight: "5px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Payment Done
                                  </span>
                                </td>
                              )}

                              <td className=" ">
                                {!item.paymentStatus && (
                                  <Tooltip
                                    placement="top"
                                    title="Confirm Payment"
                                  >
                                    <span
                                      className="mx-2 px-1 "
                                      style={{
                                        paddingBlock: "10px",
                                        backgroundColor: "#2B73FF",
                                        border: "none",
                                        color: "white",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                        lineHeight: "35px",
                                        borderRadius: "4px",
                                      }}
                                      // onClick={(e) => {
                                      //   e.stopPropagation();
                                      //   handlePaymentStatus(
                                      //     item._id,
                                      //     item.email
                                      //   );
                                      // }}
                                    >
                                      <CreditScoreIcon />
                                    </span>
                                  </Tooltip>
                                )}
                                {item.abstractStatus == null ||
                                  (item.abstractStatus == 0 &&
                                    item.category != "Attendee" && (
                                      <Tooltip
                                        placement="top"
                                        title="Approve Abstract"
                                      >
                                        <span
                                          className="mx-2 px-1 "
                                          style={{
                                            paddingBlock: "10px",
                                            backgroundColor: "lightseagreen",
                                            border: "none",
                                            color: "white",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            lineHeight: "35px",
                                            borderRadius: "4px",
                                          }}
                                          // onClick={(e) => {
                                          //   e.stopPropagation();
                                          //   handleApprove(item._id, item.email);
                                          // }}
                                        >
                                          <GradingIcon />
                                        </span>
                                      </Tooltip>
                                    ))}
                                {item.abstractStatus == null ||
                                  (item.abstractStatus == 0 &&
                                    item.category != "Attendee" && (
                                      <Tooltip
                                        placement="top"
                                        title="Reject Abstract"
                                      >
                                        <span
                                          className="mx-2 px-1 "
                                          style={{
                                            paddingBlock: "10px",
                                            backgroundColor: "lightcoral",
                                            border: "none",
                                            color: "white",
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            lineHeight: "35px",
                                            borderRadius: "4px",
                                          }}
                                          // onClick={(e) => {
                                          //   e.stopPropagation();
                                          //   handleReject(item._id, item.email);
                                          // }}
                                        >
                                          <PlaylistRemoveIcon />
                                        </span>
                                      </Tooltip>
                                    ))}

                                {item.abstractStatus == 1 && (
                                  <>
                                    <span className="text-success">
                                      Approved
                                    </span>
                                  </>
                                )}
                                {item.abstractStatus == 2 && (
                                  <>
                                    <span className="text-danger">
                                      Rejected
                                    </span>
                                  </>
                                )}
                              </td>
                              <td>
                                {format(
                                  new Date(item.created_at),
                                  "dd/MM/yyyy"
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody> */}
                </table>
              </div>

              <div className="tableEnd "></div>

              



            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NationalSeminarTable;
