import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, InputGroup, Form  } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import IcdsAPI from "../../utils/api/IcdsAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
import { IcdsContext } from "../../state/context/IcdsProvider";
import GradingIcon from '@mui/icons-material/Grading';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { notify_error, notify_success } from "../Notify";
import { useRef } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import IcdsFilter from "./IcdsFilter";


const ICDSNewTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    filter,
    setFilter,
    search,
    SetSearch,
    recperpage,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
 } = React.useContext(IcdsContext);
 const filterPanelRef = useRef(null);
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  // const [recperpage, SetRecPerPage] = useState(25);
  // const [activepage, SetActivePage] = useState(1);
  // const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  // const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [paymentEmail,setPaymentEmail] = useState("");

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    top_loader(true, 45);
    getAllIcds({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const handleRefresh=()=>{
    top_loader(true, 45);
    getAllIcds({ page: activepage, limit: recperpage });
  }

  const getAllIcds = (params) => {
    setLoading(true);
    IcdsAPI.getAllIcds(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handlePaymentStatus = (_id,email) => {
    setOpen(true);
    setCandidateId(_id);
    setPaymentEmail(email);

  };



  const handleApprove=(id,email)=>{
    IcdsAPI.approveAbstract(id,email).then((res)=>{
      if(res.status){
        notify_success("Successfully approved")
        top_loader(true, 45);
        getAllIcds({ page: activepage, limit: recperpage });
      }else{
        notify_error("Error:Couldn't Update Approval status " )

      }
    }).catch((err)=>{
      notify_error(err.message)
    })
  }


  const handleReject=(id,email)=>{
    IcdsAPI.rejectAbstract(id,email).then((res)=>{
      if(res.status){
        notify_success("Successfully Rejected Following Abstract")
        top_loader(true, 45);
        getAllIcds({ page: activepage, limit: recperpage });
      }else{
        notify_error("Error:Couldn't Update Approval status " )

      }
    }).catch((err)=>{
      notify_error(err.message)
    })
  }


  const handleCancel = () => {
    setOpen(false);
  };
  function verifyPaytmTransactionId(transactionId) {
    const regex = /^.{9,33}$/;

    return regex.test(transactionId);
  }

  function transactionVerification(params) {
    if (verifyPaytmTransactionId(params)) {
      return true;
    } else {
      return false;
    }
  }

  const handleOk = () => {
    setConfirmLoading(true);

    if (transactionId) {
      const validate = transactionVerification(transactionId);

      if (validate) {
        IcdsAPI.updateTransactionId(candidateId, transactionId)
          .then((result) => {
            // // console.log('confirm');
            IcdsAPI.confirmPaymentICDS(candidateId)
              .then((result) => {
          IcdsAPI.paymentEmailIcds(candidateId,paymentEmail).then((result)=>{
            top_loader(true, 45);
            getAllIcds({ page: activepage, limit: recperpage });

            setCandidateId("");
            setTransactionId("");
            notify_success(
              "Candidate Payment Status Updated Successfully."
            );
            setOpen(false);
          }).catch((err)=>{
            notify_error(err?.message || err);
          })
              })
              .catch((err) => {
                notify_error(err?.message || err);
              });
          })
          .catch((err) => {
            notify_error(err?.message || err);
          });
      } else {
        notify_error("Not a Valid transaction ID");
      }
    }
    // validateFormData();
    // if (isFormValidate) {
    //   UserAPI.createUser(enquiry).then((result) => {
    //     if (result.data.status) {
    //       notify_success(result.data.message);
    //       setOpen(false);
    //       setConfirmLoading(false);
    //       setFormValidate(false);
    //       resetFormData();
    //       getAllUsers({ page: activepage, limit: recperpage });
    //     }
    //   });
    // } else {
    //   setConfirmLoading(false);
    // }
  };

  const handleOnChange = (e) => {
    setTransactionId(e.target.value);
  };

  const handleSearch = () => {
    IcdsAPI.searchQuery({ search: search }, activepage, recperpage).then(
      (res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res.data)
        return;
      }
    );
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);
  
    // If the search field is emptied, reload all records
    if (value.trim() === '') {
      getAllIcds({ page: activepage, limit: recperpage });
    }
  };


  return (
    <>
    <Container
      fluid
      className="p-0"
      style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}
    >
      <Row style={{ marginInline: "10px" }}>
        <Col lg={12}>
          <div className="py-2" style={{ marginTop: "80px" }}>
          <div
                
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div 
                style={{marginLeft:"12px" , width:"25%"}}>
                
                  <InputGroup className="my-2" style={{border:"0.1px solid",borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",}}>
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",         
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In ICDS Table by Name , Email , Mobile"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                 
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight:"12px"
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <FilterAltOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={toggleFilterPanel}
                      />
                      <div
                        style={{
                          width: "1px",
                          height: "12/12",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
            <div
              className="maintable "
              id="table-container"
              style={{ height: "820px" }}
            >
              <table className="col-md-12 enquiryTable px-3">
                <thead className="bg-transparent ">
                  <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                    <th>S.No.</th>
                    <th>Name</th>
                    <th >Email</th>
                    <th>Mobile</th>
                    <th style={{ width: "20%" }}>Institute</th>

                    <th style={{ width: "10%" }}>Payment Status</th>
                    <th style={{ width: "15%" }}>Action</th>
                    <th>Created At</th>
                  </tr>
                </thead>

                <tbody>
                  {data.length > 0 &&
                    data.map((item, index) => {
                      return (
                        <>
                          <tr
                            className="tableRows "
                            key={item._id}
                            onClick={() =>
                              navigate(
                                `/dashboard/icds-profile/${item._id}`
                              )
                            }
                          >
                            <td>{sno + index + 1}</td>
                            <td>{`${item.first_name} ${item.last_name}`}</td>
                            <td >{item.email}</td>
                            <td>{item.mobile_no}</td>
                            <td className="">{item.institute}</td>
                            {item.isPaymentDone === false && (
                              <td className="">
                                <span
                                  className=""
                                  style={{
                                    border: "1px solid #FFE3E0",
                                    backgroundColor: "#FFE3E0",
                                    color: "#F7685B",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingLeft: "13px",
                                    paddingRight: "13px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Not Paid
                                </span>
                              </td>
                            )}
                            {item.isPaymentDone === true && (
                              <td className="">
                                <span
                                  className=""
                                  style={{
                                    border: "1px solid #CAEEDB",
                                    backgroundColor: "#CAEEDB",
                                    color: "#46CF85",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Payment Done
                                </span>
                              </td>
                            )}

                            <td className=" ">
                              {!item.isPaymentDone && (
                                <Tooltip placement="top" title="Confirm Payment">
                                
                                <span
                                className="mx-2 px-1 "
                                  style={{
                                    paddingBlock:"10px",
                                    backgroundColor: "#2B73FF",
                                    border: "none",
                                    color: "white",
                                    fontWeight: "400",
                                    fontSize: "13px",
                                    lineHeight: "35px",
                                    borderRadius: "4px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handlePaymentStatus(item._id,item.email);
                                  }}
                                  >
                                  <CreditScoreIcon/>
                                  </span>
                                  </Tooltip>
                              ) }
                              {item.abstractStatus == null ||item.abstractStatus ==0 && item.category !='Attendee'  &&  (
                                <Tooltip placement="top" title="Approve Abstract">
                                <span
                                className="mx-2 px-1 "
                                    style={{
                                      paddingBlock:"10px",
                                      backgroundColor: "lightseagreen",
                                      border: "none",
                                      color: "white",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                      lineHeight: "35px",
                                      borderRadius: "4px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleApprove(item._id,item.email);
                                    }}
                                    >
                                    <GradingIcon/>
                                    </span>
                                    </Tooltip>
                              )}
                              {item.abstractStatus == null ||item.abstractStatus ==0  && item.category !='Attendee'  && (
                                <Tooltip placement="top" title="Reject Abstract">
                                <span
                                className="mx-2 px-1 "
                                style={{
                                  paddingBlock:"10px",
                                  backgroundColor: "lightcoral",
                                  border: "none",
                                  color: "white",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  lineHeight: "35px",
                                  borderRadius: "4px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                 handleReject(item._id,item.email)
                                }}
                                >
                                <PlaylistRemoveIcon/>
                                </span>
                                </Tooltip>
                              )}

                              {item.abstractStatus == 1 && (
                        <>
                        <span className='text-success'>
                        Approved
                        </span>
                        </>
                      )}
                      {item.abstractStatus == 2 && (
                        <>
                        <span  className='text-danger'>
                        Rejected
                        </span>
                        </>
                      )}
                            
                            </td>
                            <td>
                              {format(new Date(item.created_at), "dd/MM/yyyy")}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="tableEnd "></div>


            <div className="col-md-12 form-inline py-2 tableFooter">
            <div className="row align-items-center  pageSet">
              <div className="col-md-4 d-flex gap-2 align-baseline">
                <label className="control-label col-md-4" style={{fontWeight:"700", paddingTop:"5px", paddingLeft:"25px"}}>
                  Rows per page :
                </label>
                <Form.Select
                  className="form-control "
                  //   onChange={(event) => SetRecPerPage(event.target.value)}

                  // onChange={(event) => applyFilter(event)}
                  onChange={(event) => SetRecPerPage(event.target.value)}
                  value={recperpage}
                  style={{border:"solid", borderWidth:"thin", color:"#000"}}
                >
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </Form.Select>
              </div>

              <div className="col-md-7 text-end">
                <Pagination
                  total={totalData}
                  showSizeChanger={false}
                  current={activepage}
                  size="small"
                  // showQuickJumper
                  pageSize={recperpage}
                  onChange={(page, pageSize) => {
                    SetActivePage(page);
                  }}
                />
              </div>
            </div>
          </div>
          </div>
        </Col>
      </Row>
      <Modal
      style={{ fontFamily: "Inter" }}
      open={open}
footer={null}
      onOk={() => handleOk(false)}
      onCancel={() => handleCancel(false)}
    >
    <div className="mb-3  col-md-10 mx-auto bg-transparent">
    <h2
          style={{
            fontFamily: "Inter",
            fontSize: "24px",
            lineHeight: "38px",
            fontWeight: "700",
          }}
        >
          Transaction Id Verification
        </h2>
      <div className="form-group mt-4">
        <label htmlFor="last_name">Transaction Id</label>
        <input
          type="text"
          
          style={{ border: "2px solid", borderRadius: "12px" }}
          className="form-control border-secondary"
          id="transactionId"
          name="transactionId"
          placeholder="Please Enter Transaction Id"
          onChange={handleOnChange}
          value={transactionId}
        />
        <span id="show_name" class="err">
          Please Enter Transaction ID Name
        </span>
      </div>

      <div className="text-center">
            <button
              className=" py-2 "
              onClick={handleOk}
              style={{
                backgroundColor: "#6434f8",
                borderRadius: "12px",
                color: "white",
                fontFamily: "Inter",
                border: "none",
                fontSize: "15px",
                fontWeight: "700",
                paddingInline:"40px"
              }}
            >
              Submit
            </button>
          </div>
    </div>
      
    </Modal>
    </Container>

    <div
    ref={filterPanelRef}
    className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
    id="handler-container"
    // Style the slide-out panel as per your requirement
    style={{
      position: "fixed",
      top: "0",
      right: `${isFilterPanelOpen ? "0" : "-100%"}`,
      width: "400px",
      height: "fit content",
      overflowY: "auto",
      background: "#fff",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      transition: "right 0.3s ease-in-out",
    }}
  >
    <IcdsFilter />
  </div>
    </>
  );
};

export default ICDSNewTable;
