import React, { useState, useEffect } from "react";
import AlumniApi from "../../utils/api/AlumniAPI";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { notify_error, notify_success } from "../Notify";
import CreateAlumni from "../CreateAlumni";
import { Link, useNavigate } from "react-router-dom";
import { Pagination, Badge } from "antd";

const Mca = () => {
  const [mcaalumni, setMcaAlumni] = useState([]);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [search, SetSearch] = useState("");
  const [activepage, SetActivePage] = useState(1);
  const [recperpage, SetRecPerPage] = useState(25);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);

  const handleSearchChange = (e) => {
    SetSearch(e.target.value);
  };

  const handleEnquiryOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };
  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note that months are zero-based, so we add 1
    const year = date.getFullYear().toString(); // Get the last two digits of the year
    return `${day}/${month}/${year}`;
  };

  const fetchAlumniData = (params) => {
    // Include the program parameter
    const apiParams = {
      page: params.page, // Pass the page parameter
      limit: params.limit, // Pass the limit parameter
      program: "MCA", // Set the program parameter to 'BBA'
    };

    AlumniApi.getalumnins(apiParams)
      .then((res) => {
        setMcaAlumni(res.data.data);
        setTotalData(res.data.total);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
  };
  // Use useEffect to fetch alumni data when the component mounts
  useEffect(() => {
    fetchAlumniData({ page: activepage, limit: recperpage });
  }, [activepage, recperpage]);

  return (
    <>
      <Container fluid style={{ fontFamily: "Inter" }}>
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                lg={12}
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}

                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup
                    className="my-2"
                    style={{
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      border: "0.1px solid",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In MCA by Name, Email and Mobile Number"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      // onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex gap-3" style={{ padding: "0" }}>
                      <button
                        onClick={handleEnquiryOpen}
                        className="px-4 my-2 py-2 "
                        style={{
                          backgroundColor: "white",
                          fontFamily: "Inter",
                          color: "#2B73FF",
                          borderColor: "#2B73FF",
                          borderRadius: "4px",
                        }}
                      >
                        <span>Add Alumni</span>
                      </button>
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{ padding: "0", marginLeft: "12px" }}
                    >
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="maintable p-0 " id="table-container">
                {mcaalumni && mcaalumni.length > 0 ? (
                  <table className="col-md-12 enquiryTable">
                    <thead className="tableHeadAllEnquiry">
                      <tr className="tableHeading">
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        {/* <th>Location</th> */}
                        <th>Company</th>
                        <th>Designation</th>
                        {/* <th>Date of birth</th> */}
                        <th>mobile number</th>
                        <th>Batch</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#FFFFFF" }}>
                      {mcaalumni &&
                        mcaalumni.length > 0 &&
                        mcaalumni.map((item, index) => (
                          <tr className="alumniTableRows" key={index} style={{ height: "66px" }}>
                            <td>
                              {!item.repeat && <span>{sno + index + 1}</span>}
                              {item.repeat && (
                                <span
                                  style={{
                                    display: "flex",
                                    // position:'relative',
                                    justifyContent: "space-around",
                                    alignItems: "",
                                  }}
                                >
                                  {sno + index + 1}
                                  <Badge.Ribbon
                                    style={{
                                      fontSize: "8px",
                                      right: "-20px",
                                      position: "absolute",
                                    }}
                                    color="#f50"
                                    placement="end"
                                    text="DUP"
                                  />
                                </span>
                              )}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.name}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.email_address}
                            </td>
                            {/* <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.city}
                            </td> */}
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.company}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.designation}
                            </td>
                            {/* <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.dob}
                            </td> */}
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.mobile_number}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.batch}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div
                    className="text-center my-4"
                    style={{ fontSize: "18px" }}
                  >
                    No data available
                  </div>
                )}
              </div>

              <div className="tableEnd"></div>
              <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label
                    className="control-label col-md-4"
                    style={{
                      fontWeight: "700",
                      paddingTop: "5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    onChange={(event) => SetRecPerPage(event.target.value)}
                    // onChange={(event) => applyFilter(event)}
                    value={recperpage}
                    style={{
                      border: "solid",
                      borderWidth: "thin",
                      color: "#000",
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div className="col-md-8 d-flex gap-2  " style={{justifyContent:"end" , alignItems:"center" , paddingRight:"60px"}}>
                  <Pagination
                    total={totalData}
                    showSizeChanger={false}
                    current={activepage}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      SetActivePage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {open && <CreateAlumni open={open} course="MCA" setOpen={setOpen} />}
    </>
  );
};

export default Mca;
