import React from 'react'
import PageHeader from './PageHeader'

const User = () => {
  return (
    <>
    <div className="main-panel mt-5">
    <div className="content-wrapper">
    <PageHeader/>
        <h1>Users</h1>
   </div>
</div>
</>
  )
}

export default User
