import { data } from "autoprefixer";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import UllasAPI from "../../utils/api/UllasAPI";
import { format } from "date-fns";
import { UllasContext } from "../../state/context/UllasProvider";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";

import JobFairDetails from "../jobfair/JobFairDetails";

const UllasRegistrationTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  // console.log("---24---",totalData);
//   const {
//     filter,
//     setFilter,
//     search,
//     SetSearch,
//     recperpage,
//     SetRecPerPage,
//     activepage,
//     SetActivePage,
//     totalData,
//     setTotalData,
//  } = React.useContext( UllasContext);

  useEffect(() => {
    top_loader(true, 45);
    getAllUllas({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const getAllUllas = (params) => {
    setLoading(true);
    UllasAPI.getAllUllas(params).then((result) => {
      if (result.data.status) {
        // console.log("---48---",result.data);
        setDate(result.data.data);
        setTotalData(result.data.total);
        // console.log("---51---",result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  function handlePageChange(page) {
    SetActivePage(page);
    getAllUllas({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllUllas({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  // console.log(data)
  return (
    <Container
      fluid
      className="p-0"
      style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}
    >
      <Row style={{ marginInline: "10px" }}>
        <Col lg={12}>
          <div className="py-2" style={{ marginTop: "80px" }}>
            <div
              className="maintable"
              id="table-container"
              style={{ height: "920px" }}
            >
              <table className="col-md-12 enquiryTable px-3">
                <thead className="bg-transparent ">
                  <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                    <th>S.No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Program</th>
                    <th>Institute Name</th>
                    <th>Payment Status</th>
                    <th>Created At</th>
                  </tr>
                </thead>

                <tbody>
                  {data.length > 0 &&
                    data.map((item, index) => {
                      return (
                        <>
                          <tr
                            className="tableRows "
                            onClick={() =>
                                navigate(`/dashboard/student-profile/${item._id}`)
                                }
                          >
                            <td>{index + 1}</td>
                            <td style={{ width: "15%" }}>
                              {item.participantsName}
                            </td>
                            <td style={{ width: "15%" }}>
                              {item.emailAddress}
                            </td>
                            <td>{item.contactNumber}</td>
                            <td>{item.course}</td>
                            <td>{item.instituteName}</td>
                            {item.paymentStatus === "Not Paid" && (
                              <td className="">
                                <span
                                  className=""
                                  style={{
                                    border: "1px solid #FFE3E0",
                                    backgroundColor: "#FFE3E0",
                                    color: "#F7685B",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingLeft: "13px",
                                    paddingRight: "13px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Not Paid
                                </span>
                              </td>
                            )}
                            {item.paymentStatus === "Payment Done" && (
                              <td className="">
                                <span
                                  className=""
                                  style={{
                                    border: "1px solid #CAEEDB",
                                    backgroundColor: "#CAEEDB",
                                    color: "#46CF85",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Payment Done
                                </span>
                              </td>
                            )}
                            <td>
                              {format(new Date(item.created_at), "dd/MM/yyyy")}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {/* <div className="tableEnd"></div> */}
            <div className="col-md-12 form-inline py-2 tableFooter">
              <div className="row align-items-center  pageSet">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label
                    className="control-label col-md-4"
                    style={{
                      fontWeight: "700",
                      paddingTop: "5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    //   onChange={(event) => SetRecPerPage(event.target.value)}

                    onChange={(event) => applyFilter(event)}
                    //   onChange={(event) => SetRecPerPage(event.target.value)}

                    value={recperpage}
                    style={{
                      border: "solid",
                      borderWidth: "thin",
                      color: "#000",
                    }}
                  >
                    {/* <option value="5">5</option> */}
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div className="col-md-7 text-end">
                <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                //   SetActivePage(page);
                handlePageChange(page);
                }}
              />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UllasRegistrationTable;
