import React, { useState, useEffect } from "react";
import AlumniApi from "../../utils/api/AlumniAPI";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { notify_error, notify_success } from "../Notify";
import CreateAlumni from "../CreateAlumni";
import { Link, useNavigate } from "react-router-dom";
import { Pagination, Badge } from "antd";

const BComLLb = () => {
  const [bcomllb, setbcomllb] = useState([]);
  const navigate = useNavigate();
  const [data, setDate] = useState([]);
  const [open, setOpen] = useState(false);

  const [search, SetSearch] = useState("");
  const [activepage, SetActivePage] = useState(1);
  const [recperpage, SetRecPerPage] = useState(25);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);

  const handleEnquiryOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };
  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note that months are zero-based, so we add 1
    const year = date.getFullYear().toString(); // Get the last two digits of the year
    return `${day}/${month}/${year}`;
  };

  let timer;

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);

    clearTimeout(timer);

    timer = setTimeout(() => {
      if (value.trim() === "") {
        fetchAlumniData(); // You can define fetchData to handle empty search case
      } else {
        fetchDataWithSearch(value.trim());
      }
    }, 300);
  };

  const fetchDataWithSearch = async (searchTerm) => {
    try {
      // setLoading(true);

      const res = await AlumniApi.SearchAlumni(
        { search: searchTerm, program: "BCOMLLB" },
        activepage,
        recperpage
      );

      setDate(res.data.data);
      setTotalData(res.data.total);
      SetActivePage(1);

      // setLoading(false);
    } catch (error) {
      console.error("Error in handleSearch:", error);
      // setLoading(false);
    }
  };

  const fetchAlumniData = async (params) => {
    const apiParams = {
      page: params.page,
      limit: params.limit,
      program: "BCOMLLB",
    };

    await AlumniApi.getalumnins(apiParams)
      .then((res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      if (search.trim() === "") {
        // If search is empty, reload the page
        await fetchAlumniData({ page: activepage, limit: recperpage });
      }
      AlumniApi.SearchAlumni(
        { search: search, program: "BCOMLLB" },
        activepage,
        recperpage
      ).then((res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        SetActivePage(1);
        // console.log(res.data)
      });
    }
  };
  // Use useEffect to fetch alumni data when the component mounts
  useEffect(() => {
    fetchAlumniData({ page: activepage, limit: recperpage });
  }, [activepage, recperpage]);

  return (
    <>
      <Container fluid style={{ fontFamily: "Inter" }}>
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                lg={12}
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}

                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup
                    className="my-2"
                    style={{
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      border: "0.1px solid",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In B.COM-LLB by Name, Email and Mobile Number"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex gap-3" style={{ padding: "0" }}>
                      <button
                        onClick={handleEnquiryOpen}
                        className="px-4 my-2 py-2 "
                        style={{
                          backgroundColor: "white",
                          fontFamily: "Inter",
                          color: "#2B73FF",
                          borderColor: "#2B73FF",
                          borderRadius: "4px",
                        }}
                      >
                        <span>Add Alumni</span>
                      </button>
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{ padding: "0", marginLeft: "12px" }}
                    >
                      <div
                        style={{
                          width: "1px",
                          height: "full",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="maintable p-0 "
                id="table-container"
                style={{ height: "820px" }}
              >
                {data && data.length > 0 ? (
                  <table className="col-md-12 enquiryTable">
                    <thead className="tableHeadAllEnquiry">
                      <tr className="tableHeading">
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Location</th>
                        <th>Company</th>
                        <th>Designation</th>
                        <th>Date of birth</th>
                        <th>Mobile Number</th>
                        <th>Batch</th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#FFFFFF" }}>
                      {data &&
                        data.length > 0 &&
                        data.map((item, index) => (
                          <tr className="alumniTableRows" key={index}>
                            <td>
                              {!item.repeat && <span>{sno + index + 1}</span>}
                              {item.repeat && (
                                <span
                                  style={{
                                    display: "flex",
                                    // position:'relative',
                                    justifyContent: "space-around",
                                    alignItems: "",
                                  }}
                                >
                                  {sno + index + 1}
                                  <Badge.Ribbon
                                    style={{
                                      fontSize: "8px",
                                      right: "-20px",
                                      position: "absolute",
                                    }}
                                    color="#f50"
                                    placement="end"
                                    text="DUP"
                                  />
                                </span>
                              )}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.name}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.email_address}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.city}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.company}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.designation}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.dob}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.mobile_number}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                navigate(
                                  `/dashboard/employeeHistory/${item._id}`
                                );
                                // setStep(2);
                              }}
                            >
                              {item.batch}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div
                    className="text-center my-4"
                    style={{ fontSize: "18px" }}
                  >
                    No data available
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {open && <CreateAlumni open={open} course="BCOMLLB" setOpen={setOpen} />}
    </>
  );
};

export default BComLLb;
