import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Badge,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";
import cookie from "js-cookie";
import FileSaver from "file-saver";
// import {Button } from 'antd'
import jsonexport from "jsonexport/dist";
import "../Table.css";

import "../FilterOnlineEnquiry.css";
import { DownloadOutlined } from "@ant-design/icons";
import { FeedbackContext } from "../../state/context/FeedbackProvider";
import FeedbackAPI from "../../utils/api/FeedbackAPI";
import FeedbackCalendar from "./FeedbackCalendar";
import { notify_error } from "../Notify";
// // console.log(result.data?.data.filter((item)=>item.role === 'counsellor'));

export default function FilterFeedbackTable(props) {
  const {
    filter,
    setFilter,
    search,
    SetSearch,
    recperpage,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
    activeFeedback,
    setActiveFeedback
  } = React.useContext(FeedbackContext);
  const [clearDate, setClearDate] = useState(false);
  // const [search, SetSearch] = useState("");
  // const [filter, setFilter] = useState([
  //   { field: "form_location", selected: [] },
  //   { field: "program", selected: [] },
  //   { field: "handled_by", selected: [] },
  //   { field: "assignedTo", selected: [] },
  //   { field: "admissionStatus", selected: [] },
  //   { field: "date", selected: { start: "", end: "" } },
  // ]);
  // const [selectedDate, setSelectedDate] = useState(null);
  const [handlers, setHandlers] = useState("");
  const [showHandler, setShowHandler] = useState(false);
  const [countInProcess, setCountInProcess] = useState(0);
  const [countStudentFeedback, setCountStudentFeedback] = useState(0);
  const [countFacultyFeedback, setCountFacultyFeedback] = useState(0);
  const [countEmployerFeedback, setCountEmployerFeedback] = useState(0);
  const[countAlumniFeedback,setAlumniFeedback] = useState(0);
  const [countParentFeedback,setParentFeedback] = useState(0);
  const [countStudentSatisfaction,setStudentSatisfaction] = useState(0);

  const [totalOnlineEnquiry, setTotalOnlineEnquiry] = useState(0);
  const [totalAdOnlineEnquiry, setTotalAdOnlineEnquiry] = useState(0);

  let hand;


  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      // Pad month and day with leading zeros if necessary
      month = month.toString().padStart(2, "0");
      day = day.toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    return "";
  };

  useEffect(() => {
    // // console.log(filter, "test");
    handleApplyFilter();
  }, [filter]);


  useEffect(() => {
    handleCountAdmissionStatus();
  }, []);

  const handleButtonClick = (buttonValue) => {
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "campus"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "campus" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "campus"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleClearFilter = () => {
    setFilter([
      { field: "feedbackType", selected: [] },
      { field: "course", selected: [] },
      { field: "batch", selected: [] },
      { field: "campus", selected: [] },
      { field: "date", selected: { start: "", end: "" } },
    ]);
    setClearDate(true);
  };

  const filterEmptySelected = (arr) => {
    return arr.filter((obj) => {
      if (
        obj.field === "date" &&
        obj.selected &&
        obj.selected.start !== "" &&
        obj.selected.end !== ""
      ) {
        return true; // Retain the "date" object if start and end values are not empty
      }

      const hasSelectedValues =
        obj.selected &&
        obj.selected.length > 0 &&
        (!Array.isArray(obj.selected) ||
          (Array.isArray(obj.selected) &&
            obj.selected.length > 0 &&
            obj.selected[0] !== null));
      return hasSelectedValues;
    });
  };


  const handleApplyFilter = () => {
    const newData = filterEmptySelected(filter);
    SetActivePage(1);
    // console.log(newData+'test')

    FeedbackAPI.dynamicFilter(newData, activepage, recperpage).then((res) => {
      setDate(res.data.data);
      setTotalData(res.data.total);
      // // console.log(newData.feedbackType+'dynamic params');
    });
  };

  const handleFilterExport = () => {
    notify_error("Feature under Development!");
    return;
    // const newData = filterEmptySelected(filter);
    // // console.log(newData);

    // FeedbackAPI.downloadDynamicFilter(newData, activepage, recperpage)
    //   .then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));

    //     // Create a temporary link element to trigger the file download
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "results.csv");
    //     document.body.appendChild(link);
    //     link.click();

    //     // Cleanup: Remove the temporary link element and revoke the URL
    //     document.body.removeChild(link);
    //     window.URL.revokeObjectURL(url);
    //   })
    //   .catch((error) => {
    //     console.error("Error while downloading CSV:", error);
    //   });
  };

  const handleCountAdmissionStatus = () => {
    FeedbackAPI.dynamicFilter(
      [{ field: "feedbackType", selected: ["student_feedback"] }],
      1,
      25
    ).then((res) => {
      setCountStudentFeedback(res.data.total);
      // console.log(res);
    });

    FeedbackAPI.dynamicFilter(
      [{ field: "feedbackType", selected: ["faculty_feedback"] }],
      1,
      25
    ).then((res) => {
      setCountFacultyFeedback(res.data.total);
      // console.log(res);
    });

    FeedbackAPI.dynamicFilter(
      [{ field: "feedbackType", selected: ["employer_feedback"] }],
      1,
      25
    ).then((res) => {
    setCountEmployerFeedback(res.data.total);
      // console.log(res);
    });

    FeedbackAPI.dynamicFilter(
      [{ field: "feedbackType", selected: ["alumni_feedback"] }],
      1,
      25
    ).then((res) => {
    setAlumniFeedback(res.data.total);
      // console.log(res);
    });

    FeedbackAPI.dynamicFilter(
      [{ field: "feedbackType", selected: ["parent_feedback"] }],
      1,
      25
    ).then((res) => {
    setParentFeedback(res.data.total);
      // console.log(res);
    });

    FeedbackAPI.dynamicFilter(
      [{ field: "feedbackType", selected: ["student_satisfaction"] }],
      1,
      25
    ).then((res) => {
    setStudentSatisfaction(res.data.total);
      // console.log(res);
    });



    // parent_feedback

    FeedbackAPI.countTotatlEnquiry().then((result) => {
      if (result?.data?.status) {
        setTotalOnlineEnquiry(result?.data?.data);
      } else {
        setTotalOnlineEnquiry(0);
      }
    });

    FeedbackAPI.countAdEnquiry().then((result) => {
      if (result?.data?.status) {
        setTotalAdOnlineEnquiry(result?.data?.data);
      } else {
        setTotalAdOnlineEnquiry(0);
      }
    });
  };




  const handleButtonClickStatus = (buttonValue) => {
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "feedbackType"
      ).selected;
  
      const isSelected = existingSelected.includes(buttonValue);
  
      // If the buttonValue is already selected, do nothing
      if (isSelected) {
        return prevFilter;
      }
  
      // Remove all other selected values and set the buttonValue as selected
      const updatedSelected = [buttonValue];
  
      return prevFilter.map((f) =>
        f.field === "feedbackType" ? { ...f, selected: updatedSelected } : f
      );
    });
  };

  const handleButtonClickProgram = (buttonValue) => {
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "course"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "course" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "course"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleButtonClickProgramDepartment = (buttonValue) => {
    setFilter((prevFilter) => {
      const departmentFilter = prevFilter.find((f) => f.field === "department");

      if (!departmentFilter) {
        // If no department filter exists, return previous filter as is
        return prevFilter;
      }

      const existingSelected = departmentFilter.selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "department" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "department"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  
  const handleButtonHandler = (buttonValue) => {
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "assignedTo"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "assignedTo" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "assignedTo"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  const handleButtonAdmission = (buttonValue) => {
    setFilter((prevFilter) => {
      const existingSelected = prevFilter.find(
        (f) => f.field === "handled_by"
      ).selected;

      // Check if the buttonValue is already selected
      if (existingSelected.includes(buttonValue)) {
        // If selected, remove the buttonValue from selected
        const updatedSelected = existingSelected.filter(
          (value) => value !== buttonValue
        );
        return prevFilter.map((f) =>
          f.field === "handled_by" ? { ...f, selected: updatedSelected } : f
        );
      } else {
        // If not selected, add the buttonValue to selected
        return prevFilter.map((f) =>
          f.field === "handled_by"
            ? { ...f, selected: [...existingSelected, buttonValue] }
            : f
        );
      }
    });
  };

  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  return (
    <>
      <Container
        className="py-3 mx-auto"
        style={{
          width: "100%",
          marginTop: "80px",
          backgroundColor: "white",
          // marginBottom: "15px",
          boxShadow: "0px 20px 10px 0px rgba(0, 31, 51, 0.10)",
          paddingLeft: "30px",
          fontFamily: "Inter",
          paddingRight: "40px",
        }}
      >
      {/* 
        <Row>
          <Col className="">
            <InputGroup className="mb-1">
              <Form.Control
                placeholder="Search ...."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                name="search"
                onChange={(e) => SetSearch(e.target.value)}
                value={search}
              />
              <Button
                variant="outline-light"
                style={{ backgroundColor: "#2B73FF" }}
                id="button-addon2"
                onClick={handleSearch}
              >
                Go
              </Button>
            </InputGroup>
          </Col>
        </Row>

    */}

        <Row className="team-member-1 text-start my-2 ">
          <Col className="team-member-1">Filter By Type :</Col>
        </Row>
        <Row className="text-start gap-2 my-2"
        style={{paddingLeft:"8px"}}>
           <Col
            lg={12}
            onClick={() => handleButtonClickStatus("student_feedback")}
            className={` border border-2 p-2 d-flex justify-content-between mx-2 ${
              filter.some(
                (f) =>
                  f.field === "feedbackType" && f.selected.includes("student_feedback")
              )
                ? "border-#007bf4"
                : "border-primary"
            }`}
            style={{
              borderRadius: "4px",
              cursor:"pointer",
              backgroundColor: filter.some(
                (f) =>
                  f.field === "feedbackType" && f.selected.includes("student_feedback")
              )
                ? "#CCABDA" // Dark background color when selected
                : "",
              color: filter.some(
                (f) =>
                  f.field === "feedbackType" && f.selected.includes("student_feedback")
              )
                ? "white" // Light text color when selected
                : "",
              //   borderColor: filter.some(
              //   (f) =>
              //     f.field === "feedbackType" && f.selected.includes("student_feedback")
              // )
              //   ? "black" // Light text color when selected
              //   : "",

                
            }}
          
          >
            <span> Student Feedback </span>
            <span>
              <button style={{ backgroundColor:"#b762db" , border:"none", borderRadius:"50%" , color:"white"}}>{countStudentFeedback}</button>
            </span>
          </Col> 
          <Col
            lg={12}
            onClick={() => handleButtonClickStatus("faculty_feedback")}
            className={`border border-2 p-2 d-flex justify-content-between mx-2 ${
              filter.some(
                (f) =>
                  f.field === "feedbackType" &&
                  f.selected.includes("faculty_feedback")
              )
                ? "border-danger"
                : "border-danger"
            }`}
            style={{
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: filter.some(
                (f) =>
                  f.field === "feedbackType" &&
                  f.selected.includes("faculty_feedback")
              )
                ? "#F7685B" // Dark background color when selected
                : "",
              color: filter.some(
                (f) =>
                  f.field === "feedbackType" &&
                  f.selected.includes("faculty_feedback")
              )
                ? "#FFF" // Light text color when selected
                : "",
            }}
          >
            <span>Faculty Feedback</span>
            <span>
              <Badge bg="danger" style={{ borderRadius: "50%" }}>
                {countFacultyFeedback}
              </Badge>
            </span>
          </Col>
          <Col
            lg={12}
            onClick={() => handleButtonClickStatus("employer_feedback")}
            className={`border border-2 p-2 d-flex justify-content-between mx-2 ${
              filter.some(
                (f) =>
                  f.field === "feedbackType" &&
                  f.selected.includes("employer_feedback")
              )
                ? "border-warning"
                : "border-warning"
            }`}
            style={{
              borderRadius: "4PX",
              cursor: "pointer",
              backgroundColor: filter.some(
                (f) =>
                  f.field === "feedbackType" &&
                  f.selected.includes("employer_feedback")
              )
                ? "#FBEC5D" // Dark background color when selected
                : "",
              color: filter.some(
                (f) =>
                  f.field === "feedbackType" &&
                  f.selected.includes("employer_feedback")
              )
                ? "#FFF" // Light text color when selected
                : "",
            }}
          >
            <span> Employer Feedback</span>
            <span>
              <Badge bg="warning" style={{ borderRadius: "50%" }}>
                {countEmployerFeedback}
              </Badge>
            </span>
          </Col>
          <Col
          lg={12}
          onClick={() => handleButtonClickStatus("alumni_feedback")}
          className={`border border-2 p-2 d-flex justify-content-between mx-2 ${
            filter.some(
              (f) =>
                f.field === "feedbackType" && f.selected.includes("alumni_feedback")
            )
              ? "border-success"
              : "border-success"
          }`}
          style={{
            borderRadius: "4px",
            cursor:"pointer",
            backgroundColor: filter.some(
              (f) =>
                f.field === "feedbackType" && f.selected.includes("alumni_feedback")
            )
              ? "#0FB9A0" // Dark background color when selected
              : "",
            color: filter.some(
              (f) =>
                f.field === "feedbackType" && f.selected.includes("alumni_feedback")
            )
              ? "white" // Light text color when selected
              : "",

              
          }}
        
        >
          <span> Alumni Feedback </span>
          <span>
            <Badge bg="success" style={{ borderRadius:"50%"}}>{countAlumniFeedback}</Badge>
          </span>
        </Col> 
        <Col
        lg={12}
        onClick={() => handleButtonClickStatus("parent_feedback")}
        className={`border border-2 p-2 d-flex justify-content-between mx-2 ${
          filter.some(
            (f) =>
              f.field === "feedbackType" && f.selected.includes("parent_feedback")
          )
            ? "border-secondary"
            : "border-info"
        }`}
        style={{
          borderRadius: "4px",
          cursor:"pointer",
          backgroundColor: filter.some(
            (f) =>
              f.field === "feedbackType" && f.selected.includes("parent_feedback")
          )
            ? "#86E3CE" // Dark background color when selected
            : "",
          color: filter.some(
            (f) =>
              f.field === "feedbackType" && f.selected.includes("parent_feedback")
          )
            ? "white" // Light text color when selected
            : "",

            
        }}

        
      
      >
        <span> Parent Feedback </span>
        <span>
          <button style={{ borderRadius:"50%" , backgroundColor:"#17a2b8" , border:"none" }}>{countParentFeedback}</button>
        </span>
      </Col> 
      <Col
      lg={12}
      onClick={() => handleButtonClickStatus("student_satisfaction")}
      className={`border border-2 p-2 d-flex justify-content-between mx-2 ${
        filter.some(
          (f) =>
            f.field === "feedbackType" && f.selected.includes("student_satisfaction")
        )
          ? "border-info"
          : "border-secondary"
      }`}
      style={{
        borderRadius: "4px",
        cursor:"pointer",
        backgroundColor: filter.some(
          (f) =>
            f.field === "feedbackType" && f.selected.includes("student_satisfaction")
        )
          ? "#A6AEBB" // Dark background color when selected
          : "",
        color: filter.some(
          (f) =>
            f.field === "feedbackType" && f.selected.includes("student_satisfaction")
        )
          ? "white" // Light text color when selected
          : "",

          
      }}

      
    
    >
      <span> Student Satisfaction </span>
      <span>
        <button style={{ borderRadius:"50%" , backgroundColor:"#BAB2A6" , border:"none" }}>{countStudentSatisfaction}</button>
      </span>
    </Col> 
        </Row>


{activeFeedback =='student_feedback' || activeFeedback=='parent_feedback' && (
  <>
  <Row className="team-member-1 text-start my-2">
  <Col>Filter By Program :</Col>
</Row>
<Row className="d-flex row-cols-5 ">
  <Col className="my-1">
    <Badge
      bg=""
      onClick={() => handleButtonClickProgram("bba")}
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) => f.field === "course" && f.selected.includes("bba")
        )
          ? "#2B73FF"
          : "#F2F2F2",

        color: filter.some(
          (f) => f.field === "course" && f.selected.includes("bba")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      BBA
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("bca")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) => f.field === "course" && f.selected.includes("bca")
        )
          ? "#2B73FF"
          : "#F2F2F2",

        color: filter.some(
          (f) => f.field === "course" && f.selected.includes("bca")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      BCA
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("b.com(h)")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) =>
            f.field === "course" && f.selected.includes("b.com(h)")
        )
          ? "#2B73FF"
          : "#F2F2F2",

        color: filter.some(
          (f) =>
            f.field === "course" && f.selected.includes("b.com(h)")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      B.COM(H)
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("mba")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) => f.field === "course" && f.selected.includes("mba")
        )
          ? "#2B73FF"
          : "#F2F2F2",
        color: filter.some(
          (f) => f.field === "course" && f.selected.includes("mba")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      MBA
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("mca")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) => f.field === "course" && f.selected.includes("mca")
        )
          ? "#2B73FF"
          : "#F2F2F2",
        color: filter.some(
          (f) => f.field === "course" && f.selected.includes("mca")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      MCA
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("b.ed")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) => f.field === "course" && f.selected.includes("b.ed")
        )
          ? "#2B73FF"
          : "#F2F2F2",
        color: filter.some(
          (f) => f.field === "course" && f.selected.includes("b.ed")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      B.ED
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("llb")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) => f.field === "course" && f.selected.includes("llb")
        )
          ? "#2B73FF"
          : "#F2F2F2",
        color: filter.some(
          (f) => f.field === "course" && f.selected.includes("llb")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      LLB
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("ba.llb")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) => f.field === "course" && f.selected.includes("ba.llb")
        )
          ? "#2B73FF"
          : "#F2F2F2",
        color: filter.some(
          (f) => f.field === "course" && f.selected.includes("ba.llb")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      BA.LLB
    </Badge>
  </Col>
  <Col className="my-1">
    <Badge
      onClick={() => handleButtonClickProgram("b.com.llb")}
      bg=""
      style={{
        fontSize: "15px",
        cursor: "pointer",
        backgroundColor: filter.some(
          (f) =>
            f.field === "course" && f.selected.includes("b.com.llb")
        )
          ? "#2B73FF"
          : "#F2F2F2",
        color: filter.some(
          (f) =>
            f.field === "course" && f.selected.includes("b.com.llb")
        )
          ? "#FFFFFF"
          : "#2B73FF",
      }}
    >
      B.COM.LLB
    </Badge>
  </Col>
</Row>
  </>
)}

     

        {activeFeedback !='student_feedback' && activeFeedback !='alumni_feedback' && activeFeedback !='parent_feedback' && (
          <>
          <Row className="team-member-1 text-start my-2">
          <Col>Filter By Department :</Col>
        </Row>
          <Row className="d-flex row-cols-2">
          <Col className="my-1">
            <Badge
              bg=""
              onClick={() => handleButtonClickProgramDepartment("cte")}
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "department" && f.selected.includes("cte")
                )
                  ? "#2B73FF"
                  : "#F2F2F2",

                color: filter.some(
                  (f) => f.field === "department" && f.selected.includes("cte")
                )
                  ? "#FFFFFF"
                  : "#2B73FF",
              }}
            >
              Education
            </Badge>
          </Col>
          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgramDepartment("management")}
              bg=""
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "department" && f.selected.includes("management")
                )
                  ? "#2B73FF"
                  : "#F2F2F2",

                color: filter.some(
                  (f) => f.field === "department" && f.selected.includes("management")
                )
                  ? "#FFFFFF"
                  : "#2B73FF",
              }}
            >
            Management
            </Badge>
          </Col>
          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgramDepartment("law")}
              bg=""
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) =>
                    f.field === "department" && f.selected.includes("law")
                )
                  ? "#2B73FF"
                  : "#F2F2F2",

                color: filter.some(
                  (f) =>
                    f.field === "department" && f.selected.includes("law")
                )
                  ? "#FFFFFF"
                  : "#2B73FF",
              }}
            >
            Law
            </Badge>
          </Col>
          <Col className="my-1">
            <Badge
              onClick={() => handleButtonClickProgramDepartment("ca")}
              bg=""
              style={{
                fontSize: "15px",
                cursor: "pointer",
                backgroundColor: filter.some(
                  (f) => f.field === "department" && f.selected.includes("ca")
                )
                  ? "#2B73FF"
                  : "#F2F2F2",
                color: filter.some(
                  (f) => f.field === "department" && f.selected.includes("ca")
                )
                  ? "#FFFFFF"
                  : "#2B73FF",
              }}
            >
            Computer Applications
            </Badge>
          </Col>
  
        </Row>
        </>

        )}

        <Row className="team-member-1 text-start my-2">
          <Col>Filter By Origin :</Col>
        </Row>

        <Row className="my-2 row-cols-2  justify-content-center align-items-center ">
          <Col className="d-flex flex-1 gap-2  ">
            <div className="py-2">
              <button
                className={`rounded-pill ${
                  filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("UG")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  border: "1px solid #2B73FF",
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                  // textAlign:"center",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("UG")
                  )
                    ? "white"
                    : "#2B73FF",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("UG")
                  )
                    ? "#2B73FF"
                    : "white",
                }}
                onClick={() => handleButtonClick("UG")}
              >
               UG CAMPUS
              </button>
            </div>
          </Col>

          <Col className="d-flex flex-1 gap-2">
            <div className="p-2">
              <button
                className={`rounded-pill ${
                  filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("PG")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "16px",
                  border: "1px solid #2B73FF",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("PG")
                  )
                    ? "white"
                    : "#2B73FF",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("PG")
                  )
                    ? "#2B73FF"
                    : "white",
                }}
                onClick={() => handleButtonClick("PG")}
              >
                PG CAMPUS
              </button>
            </div>
          </Col>
          <Col className="d-flex flex-1 gap-2">
            <div className="p-2">
              <button
                className={`rounded-pill ${
                  filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("LAW")
                  )
                    ? ""
                    : ""
                }`}
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "16px",
                  border: "1px solid #2B73FF",
                  whiteSpace: "nowrap",
                  width: "100%",
                  color: filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("LAW")
                  )
                    ? "white"
                    : "#2B73FF",
                  backgroundColor: filter.some(
                    (f) =>
                      f.field === "campus" &&
                      f.selected.includes("LAW")
                  )
                    ? "#2B73FF"
                    : "white",
                }}
                onClick={() => handleButtonClick("LAW")}
              >
                LAW CAMPUS
              </button>
            </div>
          </Col>
      
        </Row>

        <Row className="team-member-1 text-start mt-2 mb-2">
          <Col>Filter By Date :</Col>
        </Row>
        {/* <Row className="my-2 ">
          <Col
            lg={2}
            className="d-flex p-0 flex-column border border-2"
            style={{ borderRadius: "4px", flex: "1" }}
          >
            <div className="d-flex flex-grow-1 gap-2 border-bottom py-1">
              <span className="ms-1 flex-grow-0">
                <input type="radio" name="date" />
              </span>
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                Today
              </span>
            </div>
            <div className="d-flex flex-grow-1 gap-2 border-bottom">
              <span className="ms-1 flex-grow-0">
                <input type="radio" name="date" />
              </span>
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                Last Week
              </span>
            </div>
            <div className="d-flex flex-grow-1 gap-2 border-bottom py-1">
              <span className="ms-1 flex-grow-0">
                <input type="radio" name="date" />
              </span>
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                Last Month
              </span>
            </div>
            <div className="d-flex flex-grow-1 gap-2 border-bottom py-1">
              <span className="ms-1 flex-grow-0">
                <input type="radio" name="date" />
              </span>
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                This Year
              </span>
            </div>
            <div className="d-flex flex-grow-1 gap-2 border-bottom py-1">
              <span className="ms-1 flex-grow-0">
                <input type="radio" name="date" />
              </span>
              <span
                className="flex-grow-1"
                style={{ whiteSpace: "nowrap", fontSize: "12px" }}
              >
                Set up
              </span>
            </div>
          </Col>
          <Col lg={10}>
            <Container className="p-0">
              <Row>
                <Col lg={6} className="p-0 ">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate} // Set the endDate to the same value as startDate
                    inline
                  />
                </Col>
                <Col lg={6} className="p-0">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate} // Set the startDate to the same value as endDate
                    endDate={endDate}
                    inline
                    // monthsShown={2}
                  />
                </Col>
              </Row>
              <Row className="border px-1">
                <Col className="d-flex gap-2">
                  <Button
                    className="btn btn-warning btn-small btn-outline-secondary  p-1 "
                    onClick={() => handleYearChange(2022)}
                  >
                    2022
                  </Button>
                  <Button
                    className="btn btn-warning btn-small btn-outline-secondary  p-1 "
                    onClick={() => handleYearChange(2023)}
                  >
                    2023
                  </Button>
                  <Button
                    className="btn btn-warning btn-small btn-outline-secondary  p-1 "
                    onClick={() => handleYearChange(2024)}
                  >
                    2024
                  </Button>
                </Col>
                <Col className="d-flex align-items-center">DATE RANGE</Col>
              </Row>
            </Container>
          </Col>
        </Row> */}
        <FeedbackCalendar
          filter={filter}
          setFilter={setFilter}
          clearDate={clearDate}
          setClearDate={setClearDate}
        />
        <Row className="my-3 ">
          <Col className="d-flex mx-4 justify-content-center">
            <button
              className="rounded-1 me-2 px-3 py-2"
              style={{
                backgroundColor: "#2B73FF",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
              }}
              onClick={handleClearFilter}
            >
              Reset Filters{" "}
            </button>
            <button
              type="primary"
              className="rounded-1 px-3 py-2"
              onClick={handleFilterExport}
              style={{
                backgroundColor: "#2B73FF",
                border: "none",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
              }}
              icon={<DownloadOutlined />}
            >
              Export
            </button>
          </Col>
        </Row>
      </Container>
     
    </>
  );
}


