// Main Server CMS Dashboard
    // export const serverAddress  = "https://api.assertit.io/ipem";
export const serverAddress = "https://server.assertit.io/ipem";

// Dev Server CMS Dashboard
    // export const serverAddress = "https://api-dev.assertit.io/ipem";



// Local Server 

// export const serverAddress = "http://localhost:4001/ipem";

// export const serverAddress=process.env.REACT_APP_BASE_URL