import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import EnquiryAPI from '../../utils/api/EnquiryAPI';
import { notify_error, notify_success } from '../Notify';

function ActionStatus({ onOk, onCancel, open, id,status,handleRefresh }) {
  const [remark, setRemark] = useState('');
  const [AllRemarks, setAllRemarks] = useState([]);
  const [currentStatus,setCurrentStatus] = useState('');

  useEffect(() => {
    if(status){
        setCurrentStatus(status);
    }
    if (id) {
      const fetchData = async () => {
        try {
          const response = await EnquiryAPI.getAllRemarks(id);
          const data = response.data.data;
          if (data.length > 0) {
          const non_empty=  data.filter((item)=>item.remark!=='')
            const reversedRemarks = non_empty.reverse();
            setAllRemarks(reversedRemarks);
          }
        } catch (error) {
          notify_error(error.message);
        }
      };
      fetchData();
    }

    return ()=>{
      setRemark('')
      setAllRemarks([]);
    }
  }, [id]);

  function formatDate(dateString) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  }

  const handleChange = (e) => {
    setRemark(e.target.value);
  };

  const handleAdmissionStatus = (e) => {
    const data = {
      _id: id,
      status: e.target.value,
    };
    EnquiryAPI.updateAdmissionStatus(data)
      .then((result) => {
        if (result.status) {
          notify_success('Admission status updated');
          setCurrentStatus(e.target.value);
          handleRefresh();
        } else {
          notify_error('Admission status could not be updated');
          handleRefresh();
        }
      })
      .catch((error) => {
        notify_error(error.message);
        handleRefresh();
      });
  };

  return (
    <>

        <Modal
          title='Update Status'
          centered
          visible={open}
          onCancel={onCancel}
          onOk={() => onOk(remark)}
        >

        {currentStatus && (
            <div className='p-2 fs-5 mb-2 fw-bold text-primary text-center'>Current Status:{`${currentStatus|| 'none'}`}</div>
        )}
          <div className='form-group d-flex flex-column'>
            <label htmlFor='status'>Change Admission Status</label>
            <select id='status' className='status-control' onChange={(e) => handleAdmissionStatus(e)}>
              <option value='none'>None</option>
              <option value='admission-taken'>Admission Taken</option>
              <option value='in-process'>In Process</option>
              <option value='not-interested'>Not Interested</option>
            </select>
          </div>

          <div className='form-group d-flex flex-column'>
            <label htmlFor='remarks'>Add Remark :</label>
            <textarea
              className='form-control'
              id='remarks'
              name='remarks'
              placeholder='Remarks/Reasons'
              onChange={handleChange}
              value={remark}
            />
            <div style={{height:'200px',overflowY:'scroll'}}>
            {AllRemarks.length>0 &&  AllRemarks.map((item) => (
              <div
                key={item._id}
                className='border p-2 mt-2 d-flex justify-content-around'
                style={{ backgroundColor: 'lightyellow',wordWrap: 'break-word'  }}
              >
              <div className='fw-bold' style={{ flex: '50%' }}>
              {item.remark}
            </div>
            <div style={{ flex: '25%' }}>{item?.handler}</div>
            <div style={{ flex: '25%' }}>{formatDate(item?.createdAt)? formatDate(item?.createdAt):'N/A'}</div>
              </div>
            ))}
            </div>
          </div>
        </Modal>
    </>
  );
}

export default ActionStatus;
