import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import PageHeader from "../PageHeader";
import EnquiryAPI from "../../utils/api/EnquiryAPI";
import ActionStatus from "./ActionStatus";
import { notify_error, notify_success } from "../Notify";
import { format } from "date-fns";
import FilterModal from './FilterModal';
import cookie from "js-cookie";
import './EnquiryTable.css';

import {
  PlusCircleOutlined,
  RetweetOutlined,
  FilterOutlined,
  WhatsAppOutlined,
  MoreOutlined
} from "@ant-design/icons";

import { serverAddress } from "../../constant";
import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import moment from "moment";
import "antd/dist/antd.css";
const EnquiryTable = () => {
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFormValidate, setFormValidate] = useState(false);
  const [actionModal,setActionModal] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusId,setStatusId]=useState('');
  const [admissionStatus,setAdmissionStatus] = useState('');
  const [showOperation,setShowOperation] = useState(false);
  const [showFilterModal,setShowFilterModal]=useState(false);

  const [enquiry, setEnquiry] = useState({
    program: "",
    name: "",
    email: "",
    mobile_number: "",
    form_location: "walk-in",
    enquiry_type: "organic",
  });

  useEffect(() => {
    top_loader(true, 45);
    getAllEnquiry({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const getAllEnquiry = (params) => {
    setLoading(true);
    EnquiryAPI.getAllEnquiry(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  const handleFilterStatus=(params)=>{
    // console.log(params);
    if(params ==''||params=='none'){
      top_loader(true, 45);
      getAllEnquiry({ page: activepage, limit: recperpage });
      setShowFilterModal(false);
      return;
    }

    setLoading(true);
    EnquiryAPI.filterAdmissionStatus({admissionStatus:params,page: activepage, limit: recperpage}).then((result) => {
      if (result.data.status) {
        // console.log(result.data);
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
        setShowFilterModal(false);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
        setShowFilterModal(false);
      }
    });
}


  const handleRefresh = () => {
    getAllEnquiry({ page: activepage, limit: recperpage });
  };

  const handleWhatsapp = (no) => {
    // console.log(no);
    window.open(`https://web.whatsapp.com/send?phone=91${no}`);
  };

  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  //under work
  // const handleFilter=()=>{
  //   const programFilterConst='bca';

  //   setLoading(true);
  //   EnquiryAPI.filterProgramEnqyiry({ page: 1, limit:25,program:'bca' }).then((result) => {
  //     if (result.data.status) {
  //       setDate(result.data.data);
  //       setTotalData(result.data.total);
  //       setLoading(false);
  //       top_loader(true, 100);
  //       top_loader(false, 0);
  //     } else {
  //       setDate([]);
  //       setLoading(false);
  //       top_loader(true, 100);
  //       top_loader(false, 0);
  //     }
  //   });
  // }
  const handleAssignment = (_id) => {
    EnquiryAPI.updateAssignment({
      _id: _id,
      username: cookie.get("name"),
    })
      .then((result) => {
        if (result.data.status) {
          notify_success(result.data.message);
          getAllEnquiry({ page: activepage, limit: recperpage });
        } else {
          notify_error("Failed to update");
        }
      })
      .catch((err) =>
        notify_error("Unauthorized Access. Only For Counsellors.")
      );
  };

  const handlePrint = (params) => {
    EnquiryAPI.updateStatus({ _id: params }).then((result) => {
      if (result.data.status) {
        notify_success(result.data.message);
        getAllEnquiry({ page: activepage, limit: recperpage });
        // window.open(result?.data?.downloadLink);
        let x = result?.data?.downloadLink;
        const url = new URL(x);
        const path = url.pathname.slice(1);

        window.open(
          `${serverAddress}/job_application/preview?file_path=${path}`
        );
      } else {
        notify_error("Failed to Print");
      }
    });
  };

  const downloadPdf = (params) => {
    let x = params;
    const url = new URL(x);
    const path = url.pathname.slice(1);

    window.open(
      `${serverAddress}/job_application/preview?file_path=${path}`
    );
  };

  function handlePageChange(page) {
    SetActivePage(page);
    getAllEnquiry({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllEnquiry({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
    handleRefresh();
  };
  const handleOk = () => {
    setConfirmLoading(true);
    validateFormData();
    if (isFormValidate) {
      EnquiryAPI.createEnquiry(enquiry).then((result) => {
        if (result.data.status) {
          notify_success(result.data.message);
          setOpen(false);
          setConfirmLoading(false);
          setFormValidate(false);
          resetFormData();
          getAllEnquiry({ page: activepage, limit: recperpage });
        }
      });
    } else {
      setConfirmLoading(false);
    }
  };

 const handleRemarks=(param)=>{
  if(param ==''){
    setActionModal(false);
    return;
  }
  const data={
    _id:statusId,
    remark:param,
    handler:cookie.get("name")
  }
  EnquiryAPI.updateAdmissionRemarks(data).then((result)=>{
    if(result.status){
        notify_success("admission remarks updated");
        setActionModal(false);
        setStatusId('');
        setAdmissionStatus('');
        handleRefresh();
        // window.reload();
    }else{
        notify_error("admission remarks Could not be updated");
        handleRefresh();
    }
}).catch((e)=>{
    notify_error(e.message);
    handleRefresh();
})



 }



  const validateFormData = () => {
    let check = 0;
    Object.entries(enquiry).forEach(([key, value]) => {
      if ((key && value === undefined) || value === null || value === "") {
        notify_error("Please enter " + key.split("_").join(" "));
        check = 1;
      }
    });
    if (check !== 1) {
      if (
        isEmail(enquiry.email) &&
        validateMobileNumber(enquiry.mobile_number)
      ) {
        setFormValidate(true);
      } else {
        notify_error("Please enter valid email or mobile number");
      }
    }
  };
  const isEmail = (email) => {
    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  };
  const validateMobileNumber = (mobileNumber) => {
    const indianRegex = /^[6-9]\d{9}$/;
    if (indianRegex.test(mobileNumber)) {
      return true;
    } else {
      return false;
    }
  };
  const resetFormData = () => {
    setEnquiry({
      program: "",
      name: "",
      email: "",
      mobile_number: "",
      form_location: "walk-in",
      enquiry_type: "organic",
    });
  };

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (text, record, index) => (
        <div className="table-td-div" >
          <div className="table-td-div-inner" >
            {record.admissionStatus == "in-process" ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "",
                }}
              >
                {record.sno}{" "}
                <Badge.Ribbon style={{fontSize:'11px',right:'-20px',top:'1px',position:'absolute'}} color="blue" placement="end" text="IP" />
              </span>
            ) : (
               record.admissionStatus =='admission-taken' ? (
                <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "",
                }}
              >
                {record.sno}{" "}
                <Badge.Ribbon style={{fontSize:'11px',right:'-20px',top:'1px',position:'absolute'}} color="lightgreen" placement="end" text="AD" />
              </span>
               ): 
               record.admissionStatus == "not-interested"?
               (
                <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "",
                }}
              >
                {record.sno}{" "}
                <Badge.Ribbon style={{fontSize:'11px',right:'-20px',top:'1px',position:'absolute'}} color="#f50" placement="end" text="NI" />
              </span>
               ):(<span>
                {record.sno} 
              </span>)
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Name",
      width: 100,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{capitalizeWords(record.name)}</span>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Email",
    //   width: 100,
    //   dataIndex: "email",
    //   key: "email",
    //   render: (text, record, index) => (
    //     <div className="table-td-div">
    //       <div className="table-td-div-inner">
    //         <span>{record.email}</span>
    //       </div>
    //     </div>
    //   )
    // },
    {
      title: "Mobile",
      width: 100,
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.mobile_number}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Program",
      width: '10%',
      dataIndex: "program",
      key: "program",
      filters: [
        {
          text: "BBA",
          value: "bba",
        },
        {
          text: "BCA",
          value: "bca",
        },
        {
          text: "B.COM(H)",
          value: "b.com(h)",
        },
        {
          text: "MBA",
          value: "mba",
        },
        {
          text: "MCA",
          value: "mca",
        },
        {
          text: "B.A.LLB",
          value: "b.a.llb" && "ba.llb",
        },
        {
          text: "LLB",
          value: "llb",
        },
        {
          text: "B.COM LLB",
          value: "b.com llb",
        },
        {
          text: "B.ED",
          value: "b.ed",
        },
      ],
      onFilter: (value, record) => record.program.indexOf(value) === 0,
      filterSearch: true,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{record.program.toUpperCase()}</span>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Status",
    //   width: 100,
    //   dataIndex: "is_enquiry_done",
    //   key: "is_enquiry_done",
    //   render: (text, record, index) => (
    //     <div className="table-td-div">
    //       <div className="table-td-div-inner">
    //         <span>
    //           {record.is_enquiry_done ? (
    //             <Badge
    //               className="site-badge-count-109"
    //               count={"Handled"}
    //               style={{
    //                 backgroundColor: "#52c41a",
    //               }}
    //             />
    //           ) : (
    //             <Badge count={"NA"} color="#faad14" />
    //           )}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Enquiry Status",
      width: '10%',
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner text-center d-flex flex-column">
            {record.is_enquiry_done ? (
              <Badge
                className="site-badge-count-109"
                count={"Handled"}
                style={{
                  backgroundColor: "#52c41a",
                }}
              />
            ) : (
              <Badge count={"NA"} color="#faad14" />
            )}
            {record.assignedTo ? (
              <span className="text-secondary fw-bold">
                {record.assignedTo.toUpperCase()}
              </span>
            ) : (
              <Button
                className="bg-transparent border-0 border-bottom text-primary"
                onClick={() => handleAssignment(record._id)}
              >
                Assign To Me
              </Button>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Created At",
      width: 80,
      dataIndex: "created_at",
      key: "created_at",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
            onChange={(date) =>
              setSelectedKeys(date ? [date.startOf("day").toDate()] : [])
            }
            onClear={() => {
              setSelectedKeys([]);
              clearFilters();
              confirm();
            }}
            disabledDate={(current) =>
              current && current > moment().endOf("day")
            }
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
          >
            Filter
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const date = moment(record.created_at).format("yyyy-MM-DD");
        return moment(date).isSame(moment(value).format("yyyy-MM-DD"));
      },
      filters: [
        {
          text: "This week",
          value: [
            moment().startOf("week").toDate(),
            moment().endOf("week").toDate(),
          ],
        },
        {
          text: "This month",
          value: [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ],
        },
      ],
      filterMultiple: false,
      render: (text, record, index) => (
        <div className="table-td-div">
          <div className="table-td-div-inner">
            <span>{format(new Date(record.created_at), "dd-MM-yyyy")}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Operation",
      key: "operation",
      fixed: "right",
      width: showOperation?'20%':'10%',
      render: (text, record, index1) => (
        <>
        {!showOperation && <Button className="border-0 bg-dark text-white"  onClick={()=>setShowOperation((prev)=>!prev)}><i class="fa-solid fa-circle-plus"></i></Button>}

{showOperation && (<div style={{ display: "flex", justifyContent: "space-around",position:'relative' }}>
<div><Button style={{position:'absolute',left:'-18px',borderRadius:"50%"}} className="border-0 " onClick={()=>setShowOperation(false)}><i class="fa-solid fa-square-minus"></i></Button></div>
<div>
<Button className="border-0" onClick={()=>{
  setActionModal(true)
  setStatusId(record.operation._id);
  if(record.admissionStatus){setAdmissionStatus(record.admissionStatus)}
}}><MoreOutlined style={{fontSize:'20px'}}/></Button>
</div>
<div>
{record.operation.downloadLink === null ? (
  <button
    className="btn  btn-sm"
    style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',minWidth:'94px',backgroundColor:'#efdff7'}}
    onClick={() => handlePrint(record.operation._id)}
  >
    Print
  </button>
) : (
  <button
    className="btn btn-success btn-sm"
    style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)'}}
    onClick={() => downloadPdf(record.operation.downloadLink)}
  >
    Download
  </button>
)}
</div>

    <div>
      {" "}
      <button
        style={{ backgroundColor: "transparent", border: "0px" }}
        onClick={() => handleWhatsapp(record.mobile_number)}
      >
        <WhatsAppOutlined
          style={{ fontSize: "25px", color: "green" }}
        />
      </button>
    </div>
  </div>)}
       
        </>
      ),
    },
  ];

  const data2 = [];
  if (data) {
    data.forEach((item, index) => {
      data2.push({
        sno: sno + index + 1,
        _id: item._id,
        name: item.name,
        email: item.email,
        assignedTo: item?.assignedTo,
        mobile_number: item.mobile_number,
        program: item.program,
        is_enquiry_done: item.is_enquiry_done,
        created_at: item.created_at,
        admissionStatus: item?.admissionStatus,
        operation: {
          _id: item._id,
          downloadLink: item.downloadLink,
        },
      });
    });
  }
 {/*  const getRowClassName = (record, index) => {
    if (record?.admissionStatus=="in-process") {
      return "highlight-primary";
    }
    if (record?.admissionStatus=="admission-taken") {
      return "highlight-green";
    }
    if (record?.admissionStatus=="not-interested") {
      return "highlight-red";
    }
    return "";
  };
*/} 
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEnquiry({ ...enquiry, [name]: value });
  };
  return (
    <>
      <div className="main-panel mt-5">
        <div className="content-wrapper">
          {/* {loader.isActive && <Spinner />} */}
          <PageHeader title="Walk-In Enquiry List" />
          <div
            className="ag-theme-alpine"
            style={{ height: 700, width: "100%" }}
          >
         {/*   <div className="row">
              <div className="col-md-4 d-flex justify-content-around">
                <div className="">
                  <h4>Admission Taken : </h4>
                  <div></div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-around">
                <div>
                  <h4>In Process: </h4>
                  <div></div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-around">
                <div>
                  <h4>Not Interested: </h4>
                  <div></div>
                </div>
              </div>
            </div>

          */}   
            <div className="row">
              <div className="col-md-3 form-inline">
                <div className="row align-items-center mb-3">
                  <div className="col-md-4">
                    <label className="control-label">Show :</label>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      onChange={(event) => applyFilter(event)}
                      value={recperpage}
                    >
                      {/* <option value="5">5</option> */}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-4">per page</div>
                </div>
              </div>
              <div className="col-md-9">
                <Tooltip
                  placement="top"
                  title="Create New Enquiry"
                  color="lime"
                >
                  <Button
                    type="primary"
                    className="mb-3"
                    style={{ float: "right" }}
                    onClick={showModal}
                    icon={<PlusCircleOutlined />}
                    size={"large"}
                  />
                </Tooltip>
                <Tooltip placement="top" title="refresh" color="geekblue">
                  <Button
                    type="primary"
                    className="mb-3 me-1"
                    style={{ float: "right", backgroundColor: "green" }}
                    onClick={handleRefresh}
                    icon={<i class="fa-solid fa-rotate-right"></i>}
                    size={"large"}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Filter By Admission Status" color="geekblue">
                <Button
                  type="primary"
                  className="mb-3 me-1"
                  style={{ float: "right", backgroundColor: "lightgrey" }}
                  onClick={()=>setShowFilterModal(true)}
                  icon={<i class="fa-solid fa-filter"></i>}
                  size={"large"}
                />
              </Tooltip>
       
              </div>
            </div>

            <Table
              columns={columns}
              dataSource={data2}
              pagination={false}
              loading={loading}
              scroll={{ x: 1000 ,y: 500 }}
            />
            <div className="mt-3 text-end">
              <Pagination
                total={totalData}
                showSizeChanger={false}
                size="small"
                // showQuickJumper
                pageSize={recperpage}
                onChange={(page, pageSize) => {
                  handlePageChange(page);
                }}
              />
            </div>
            <Modal
              title="Walk-In Form"
              open={open}
              onOk={handleOk}
              // confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="program">Program</label>
                    <select
                      class="form-control"
                      style={{ padding: "15px" }}
                      id="program"
                      name="program"
                      onChange={handleOnChange}
                    >
                      <option selected disabled value={enquiry.program}>
                        Select Your Program
                      </option>
                      <option value="bba">BBA</option>
                      <option value="bca">BCA</option>
                      <option value="b.com(h)">B.COM</option>
                      <option value="mba">MBA</option>
                      <option value="b.ed">B.ED</option>
                      <option value="mca">MCA</option>
                      <option value="ba.llb">B.A.LLB</option>
                      <option value="llb">LLB</option>
                      <option value="b.com.llb">B.Com LLB</option>
                    </select>
                    <span id="show_program" class="err">
                      Please Select Program
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter name"
                      onChange={handleOnChange}
                      value={enquiry.name}
                    />
                    <span id="show_name" class="err">
                      Please Enter Name
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter email"
                      onChange={handleOnChange}
                      value={enquiry.email}
                    />
                    <span id="show_email" class="err">
                      Please Enter Email
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile_number"
                      name="mobile_number"
                      placeholder="Mobile number"
                   
                      onChange={handleOnChange}
                      value={enquiry.mobile_number}
                    />
                    <span id="show_mobile_number" class="err">
                      Please Enter Mobile Number
                    </span>
                  </div>
                </div>
              </div>
            </Modal>

         
    

            <ActionStatus 
            open={actionModal}
            onOk={(param)=>handleRemarks(param)}
            onCancel={()=>{
              setActionModal(false)
              setStatusId('')
              setAdmissionStatus('')
            // window.location.reload();
            }}
            id={statusId}
            status={admissionStatus}
            handleRefresh={handleRefresh}
            />
           <FilterModal
            open={showFilterModal}
            onCancel={()=>{
              setShowFilterModal(false);
            }}
            onOk={handleFilterStatus}
            />
          
          
          </div>
        </div>
      </div>
    </>
  );
};

export default EnquiryTable;
