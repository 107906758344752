import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from "js-cookie";
const OnlineEnquiryAPI = {
  getAllOnlineEnquiry: (params) => {
    // let url =
    //   serverAddress +
    //   `/api/website/enquiry/get-all?page=${params?.page}&limit=${params?.limit}`;

    let url =
      serverAddress +
      `/website/enquiry/get-all?page=${params?.page}&limit=${params?.limit}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  getAllCollegeDekhoOnlineEnquiry: (params) => {
    // let url =
    //   serverAddress +
    //   `/api/website/enquiry/get-all?page=${params?.page}&limit=${params?.limit}`;

    let url =
      serverAddress +
      `/website/enquiry/get/all?page=${params?.page}&limit=${params?.limit}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  getEnquiryByEmail: (email) => {
    let url = serverAddress + `/website/enquiry/getRepeated?email=${email}`;
    return axios.post(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
 },

  //get total count of enquiry
  countTotatlEnquiry: () => {
    // let url = serverAddress + `/api/website/enquiry/get/total/enquiry/ad`;
    let url = serverAddress + `/website/enquiry/get/total/enquiry/ad`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //total Ad-enquiry
  countAdEnquiry: () => {
    // let url = serverAddress + `/api/website/enquiry/get/total/enquiry/popup`;
    let url = serverAddress + `/website/enquiry/get/total/enquiry/popup`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //total popup enquiry
  popupEnquiry: () => {
    // let url = serverAddress + `/api/website/enquiry/get/total/enquiry/popup`;
    let url = serverAddress + `/website/enquiry/get/total/enquiry/popup`;

    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //under work
  updateStatus: (params) => {
    // let url = serverAddress + `/api/website/enquiry/update/enquiry/status`;
    let url = serverAddress + `/website/enquiry/update/enquiry/status`;

    return axios.put(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

///dynamic filter api
dynamicFilter:(params,page,limit)=>{
  // let url=serverAddress+`/api/website/enquiry/filter/dynamic?page=${page}&limit=${limit}`;
  let url=serverAddress+`/website/enquiry/filter/dynamic?page=${page}&limit=${limit}`;
  return axios.post(url,params,{
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    },
  })
},

// College Dekho Dynamic Filter
dynamicFilterCollegeDekho:(params,page,limit)=>{
  // let url=serverAddress+`/api/website/enquiry/filter/dynamic?page=${page}&limit=${limit}`;
  let url=serverAddress+`/website/enquiry/filter/dynamic/college-dekho?page=${page}&limit=${limit}`;
  return axios.post(url,params,{
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    },
  })
},


//download dynamic filter api
///dynamic filter api
downloadDynamicFilter:(params,page,limit)=>{
  let url=serverAddress+`/website/enquiry/filter/ExportDynamic?page=${page}&limit=${limit}`;
  return axios.post(url,params,{
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    },
    responseType: 'blob', 
  })
},

// search API Filter Integration
searchQuery: (params,page,limit)=>{
  // let url=serverAddress+`/api/website/enquiry/search?page=${page}&limit=${limit}`;
  let url=serverAddress+`/website/enquiry/search?page=${page}&limit=${limit}`;

  return axios.post(url,params,{
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    },
  })
},

searchQueryCollegeDekho: (params,page,limit)=>{
  // let url=serverAddress+`/api/website/enquiry/search?page=${page}&limit=${limit}`;
  let url=serverAddress+`/website/enquiry/college-dekho/search?page=${page}&limit=${limit}`;

  return axios.post(url,params,{
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    },
  })
},

assignHandlerOne:(params)=>{
  let url=serverAddress +`/website/enquiry/assign`;
  return axios.put(url,params,{
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${cookie.get("ajwt")}`,
  })
},

assignHandlerAll:(params)=>{
  let url=serverAddress +`/website/enquiry/assign`;
  return axios.put(url,params,{
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${cookie.get("ajwt")}`,
  })
},
  

// Update Reassign response status
// updateReassignResponse :(params)=>{
//   let url=serverAddress+`/website/enquiry/updateResponseStatus?id=${params}`;
//   return axios.get(url,{
//     "Content-Type": "application/json;charset=UTF-8",
//     Authorization: `Bearer ${cookie.get('ajwt')}`,
//   })
// }
 
updateReassignResponse:async(params)=> {
  const url = serverAddress + `/website/enquiry/updateResponseStatus?id=${params}`;
  
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${cookie.get('ajwt')}`,
    },
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error updating reassign response:', error);
    throw error;
  }
},


uploadRemarks:async(params)=>{
  const url=`${serverAddress}/website/enquiry/remarksUpload`
  return await fetch (url,{
    method: 'POST',
    body: params,
  })
},

uploadOrigin:async(params)=>{

  let url = serverAddress + `/website/enquiry/update/origin`;
    return axios.post(url, params, {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${cookie.get("ajwt")}`,
    });
 


},
  

getAllDuplicates: (page,limit) => {
  const url = `${serverAddress}/website/enquiry/repeat/all?page=${page}&limit=${limit}`;

  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${cookie.get('ajwt')}`,
    },
  });
},




  /* Forget Password */
};

export default OnlineEnquiryAPI;
