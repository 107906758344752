import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import UllasAPI from "../../utils/api/UllasAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip, Modal, Pagination } from "antd";
import FilterContactUs from "./FilterContactus";
import ContactUsAPI from "../../utils/api/ContactUsAPI";
import { ContactContext } from "../../state/context/ContactProvider";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const ContactUsNewTable = () => {
  const {
    setFilter,
    search,
    SetSearch,
    applyFilter,
    recperpage,
    handlePageChange,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
  } = React.useContext(ContactContext);

  const dispatch = useDispatch();
  const filterPanelRef = useRef(null);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  // const [recperpage, SetRecPerPage] = useState(25);
  // const [activepage, SetActivePage] = useState(1);
  // const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  // const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    top_loader(true, 45);
    getAllContactUs({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);

  const getAllContactUs = (params) => {
    setLoading(true);
    ContactUsAPI.getAllContactUs(params).then((result) => {
      if (result.data.status) {
        setDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);

        top_loader(true, 100);
        top_loader(false, 0);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  function capitalizeWords(name) {
    if (!name) {
      return false;
    }
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSearch = () => {
    ContactUsAPI.searchQuery({ search: search }, activepage, recperpage).then(
      (res) => {
        // console.log(res.data)
        setDate(res.data.data);
        setTotalData(res.data.total);
        return;
      }
    );
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);
  
    // If the search field is emptied, reload all records
    if (value.trim() === '') {
      getAllContactUs({ page: activepage, limit: recperpage });
    }
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  const handleRefresh = () => {
    // Clear search
    SetSearch("");
    // setIsSearch(false);

    // Clear filters
    setFilter([]);
    // setIsFilter(false);
    // filterEmptySelected();

    // Clear Checkbox
    // setSelectedRows([]);

    // Fetch data again
    getAllContactUs({ page: activepage, limit: recperpage });
  };

  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{ backgroundColor: "#F2F5F7", fontFamily: "Inter" }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div 
                style={{marginLeft:"12px" , width:"25%" , height:"100%"}}>
                  <InputGroup className="my-2" style={{borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            border:"0.1px solid"}}>
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center  "
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In Contact Us Table"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <FilterAltOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={toggleFilterPanel}
                      />
                      <div
                        style={{
                          width: "1px",
                          height: "12/12",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="maintable "
                id="table-container"
                style={{ height: "900px" }}
              >
                <table className="col-md-12 enquiryTable px-3">
                  <thead className="bg-transparent ">
                    <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Created At</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        // console.log(data);
                        return (
                          <>
                            <tr className="tableRows ">
                              <td>{sno + index + 1}</td>
                              <td>{capitalizeWords(item.name)}</td>
                              <td>{item.email}</td>
                              <td>{item.mobile_number}</td>
                              <td>
                                {format(
                                  new Date(item.created_at),
                                  "dd/MM/yyyy"
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    {data.length == "0" && (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <h1 className="text-muted">NO DATA</h1>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="tableEnd"></div>

              <div className="col-md-12 form-inline py-2 tableFooter">
                <div className="row align-items-center  pageSet">
                  <div className="col-md-4 d-flex gap-2 align-baseline">
                    <label className="control-label col-md-4" style={{fontWeight:"700", paddingTop:"5px", paddingLeft:"25px"}}>
                      Rows per page :
                    </label>
                    {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                    <Form.Select
                      className="form-control "
                      //   onChange={(event) => applyFilter(event)}
                      onChange={(event) => SetRecPerPage(event.target.value)}
                      value={recperpage}
                      style={{border:"solid", borderWidth:"thin", color:"#000"}}
                    >
                      {/* <option value="5">5</option> */}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </Form.Select>
                  </div>

                  <div className="col-md-7 text-end">
                    <Pagination
                      total={totalData}
                      showSizeChanger={false}
                      size="small"
                      // showQuickJumper
                      pageSize={recperpage}
                      onChange={(page, pageSize) => {
                        SetActivePage(page);
                        // handlePageChange(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "0",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "fit content",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 0.3s ease-in-out",
        }}
      >
        <FilterContactUs />
      </div>
    </>
  );
};

export default ContactUsNewTable;
