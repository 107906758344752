import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from "js-cookie";

const AlumniApi = {
  getalumnins: (params) => {
    // let url = serverAddress + `/api/enquiry/get/total/enquiry`;
    let url =
      serverAddress +
      `/alumniDetails/getAlumnis?page=${params?.page}&limit=${params?.limit}&program=${params?.program}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  createAlumni: (params) => {
    let url = serverAddress + `/alumniDetails/createAlumni`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  getSingleAlumni: (params) => {
    let url = serverAddress + `/alumniDetails/getOneAlumni/${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  createEmployeeHistory: (employeeFormData, id) => {
    let url = serverAddress + `/alumniDetails/createEmployeeHistory/${id}`;
    return axios.post(url, employeeFormData, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  UpdateAlumni: (alumniID, newAlumni) => {
    let id = alumniID;

    let url = serverAddress + `/alumniDetails/updateAlumni/${id}`;
    return axios.put(url, newAlumni, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  SearchAlumni: (params, page, limit) => {
    let url =
      serverAddress +
      `/alumniDetails/search-alumni?page=${page}&limit=${limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  filterAlumni: (params, page, limit) => {
    // console.log(page);
    // console.log(params);
    let url =
      serverAddress +
      `/alumniDetails/get/alumni/by/batch?page=${page}&limit=${limit}&program=${params.params.program}&batch=${params.params.batch}`;
    return axios.get(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
};

export default AlumniApi;
