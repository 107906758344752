import React, { useEffect, useState } from "react";
import { serverAddress } from "../../state/constants/content_types";
import { notify_error, notify_success } from "../Notify";


import PDFViewer from "../PDFViewer";
import PsbmAPI from "../../utils/api/PsbmAPI";


const PsbmInfo = ({data}) => {

    const [key,setKey]=useState('')
    const [viewAbstract,setViewAbstract] = useState(false)
    const [viewPaper,setViewPaper] = useState(false)
    const [abstractVisibility, setAbstractVisibility] = useState([]);

    useEffect(()=>{
        // console.log(data);
        // console.log(key);
    },[key]);


    // console.log("psbmdata:",data);

    const handleApprove=()=>{
      PsbmAPI
      .approveAbstractPsbm(data._id,data.email).then((res)=>{
        if(res.status){
          notify_success("Successfully approved")
          window.location.reload();

        }else{
          notify_error("Error:Couldn't Update Approval status " )

        }
      }).catch((err)=>{
        notify_error(err.message)
      })
    }


    const handleReject=()=>{
      PsbmAPI
      .rejectAbstractPsbm(data._id,data.email).then((res)=>{
        if(res.status){
          notify_success("Successfully Rejected Following Abstract")
          window.location.reload();

        }else{
          notify_error("Error:Couldn't Update Approval status " )

        }
      }).catch((err)=>{
        notify_error(err.message)
      })
    }



    const handleViewAbstract = (index) => {
      setAbstractVisibility((prevVisibility) => {
        const updatedVisibility = [...prevVisibility];
        updatedVisibility[index] = !updatedVisibility[index];
        return updatedVisibility;
      });
    };


    return(
        <>
        {data && data._id && (  <div className="lead-remarks mx-3" style={{marginTop:"96px"}}>
            <div className="">
                <div className="" style={{fontSize:"22px", fontWeight:"500", paddingLeft:"22px" }}>
                    PERSONAL INFORMATION
                </div>
                <div className="" style={{marginInline:"15px", marginTop:"5px", height:"2px" , backgroundColor:"#E5E9EB", width:"97%"}}> </div>
            </div>

            <div className="col-md-6 mt-3">
                
                <div className="spContent31 mx-3">
                        <div className="lpContent32">Name :</div>
                        <div className="lpContent33">{data.first_name} {data.last_name}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">Mobile :</div>
                        <div className="lpContent33">{data?.mobile_no}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">E-mail :</div>
                        <div className="lpContent33">{data?.email}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">Institute Name :</div>
                        <div className="lpContent33">{data?.institute}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">Address :</div>
                        <div className="lpContent33">{data?.city} - {data?.state}</div>
                </div>

                <div className="spContent31 mx-3">
                        <div className="lpContent32">Nationality :</div>
                        <div className="lpContent33">{`${data.nationality} -${data.country || "N/A"}`}</div>
                </div>
                {data.co_author_details.length > 0 &&
                  data.co_author_details.map((item,index) => (
                  <>
                  <h5 className="ms-3">Co-Author:{index+1}</h5>
                      {Object.entries(item).map(([key, value]) => (
                        <div className="spContent31 mx-3" key={key + value}>
                          <div className="lpContent32">
                            {key.toUpperCase()} :
                          </div>
                          <div className="lpContent33">
                            {value}
                          </div>
                        </div>
                      ))}
                      </>
                  ))
                }
     
                <div className="spContent31 mx-3">
                    <div className="lpContent32">Abstract Status :</div>
                    <div className="lpContent33">
                    {data.abstractStatus ==0 && (
                      <>
                      <span>
                      <a href="#" onClick={handleApprove}>Approve</a>
                      </span>
                      <span>|</span>
                      </>
                      )}
                     
                      
                      {data.abstractStatus == 0 && (
                        <>
                        <span onClick={handleReject}>
                        <a href="#">Reject </a>
                      </span>
                      </>
                      )}
                      {data.abstractStatus == 1 && (
                        <>
                        <span className='text-success'>
                        Approved
                        </span>
                        </>
                      )}
                      {data.abstractStatus == 2 && (
                        <>
                        <span  className='text-danger'>
                        Rejected
                        </span>
                        </>
                      )} 
                    </div>
                  </div>
            </div>

        
            <div className="d-flex flex-column gap-5">
            {data.abstracts.length > 0 &&
              data.abstracts.map((abstract, index) => (
                <div className="col-md-6 mt-3" key={index}>

                  <div className="spContent31 mx-3">
                    <div className="lpContent32">Abstract {index + 1}:</div>
                    <div className="lpContent33">
                      {!abstractVisibility[index] && (
                        <span onClick={() => handleViewAbstract(index)}>
                          <a href="#">View</a>
                        </span>
                      )}
                      {abstractVisibility[index] && (
                        <span onClick={() => handleViewAbstract(index)}>
                          <a href="#">Hide</a>
                        </span>
                      )}
                      |
                      <span>
                        <a
                          target="_blank"
                          href={`${serverAddress}/job_application/preview?file_path=${abstract}`}
                        >
                          Download
                        </a>
                      </span>
                    </div>
                  </div>
                  {abstractVisibility[index] && (
                    <iframe
                      width="100%"
                      height="900"
                      className="mx-auto d-flex"
                      src={`${serverAddress}/job_application/preview?file_path=${abstract}`}
                    />
                  )}
                </div>
              ))}

            
            {data.docs.length > 0 && data.docs.length === 2 && (
              <>
              <div className="col-md-6 mt-3">
                <div className="spContent31 mx-3">
                  <div className="lpContent32">PDF file :</div>
                  <div className="lpContent33">
                    {!viewPaper && (
                      <span onClick={() => setViewPaper(true)}>
                        <a href="#">View</a>
                      </span>
                    )}
                    {viewPaper && (
                      <span onClick={() => setViewPaper(false)}>
                        <a href="#">Hide</a>
                      </span>
                    )}
                    |
                    <span>
                    <a target='_blank' href={`${serverAddress}/job_application/preview?file_path=${data.docs[1]}`}>Download</a>
                    </span>
                  </div>
                </div>
                </div>
                {viewPaper && (
                  <iframe
                  width="100%"
                  height="900"
                  className="mx-auto d-flex"
                  src={`${serverAddress}/job_application/preview?file_path=${data.docs[1]}`}
                />
                  // <PDFViewer url={`${serverAddress}/job_application/preview?file_path=${data.docs[1]}`} />
                )}
              </>
            )}
            
           
          </div>
          {data.docs.length > 1 && (
            <>
            <div className="col-md-6 mt-3">
            <div className="spContent31 mx-3">
            <div className="lpContent32">DOCX file :</div>
            <div className="lpContent33"><a style={{float:'left'}} target="_blank" href={`${serverAddress}/job_application/preview?file_path=${data.docs[0]}`}>view</a></div>
            </div>
            </div>
              
            </>
          )}
        
        </div>)}




        </>
    
    )
}

export default PsbmInfo;

