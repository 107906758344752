import axios from 'axios';
import cookie from 'js-cookie';
import React, { useState } from 'react';
import { serverAddress } from '../constant';

const FileUploadComponent = () => {
  const [file, setFile] = useState(null);
  const [uploadUrl, setUrl] = useState(null);
  const [key,setKey] = useState(null);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  async function getSignedUrl() {
    try {
      const url = `${serverAddress}/user/presigned`;
      const res = await axios.get(url);
      // console.log(res.data);
      setUrl(res.data.data.presignedUrl);
      setKey(res.data.data.key);
    } catch (error) {
      console.error('Error getting signed URL:', error);
    }
  }

  const handleUpload = async () => {
    getSignedUrl();
    if (file && uploadUrl) {
      try {
        const formData = new FormData();
        formData.append('file', file);
  
        const response = await fetch(uploadUrl, {
          method: 'PUT',
          body: formData,
          headers: {
            // No Authorization header needed
            'Content-Type': file.type,
          },
        });
  
        // Log response headers from the PUT request
        // console.log('PUT Response Headers:', response.headers,key);
  
        if (response.ok) {
          // console.log('File uploaded successfully',key);
          setKey(null);
  
         
        } else {
          console.error('File upload failed:', response.statusText,key);
          setKey(null);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        setKey(null);
      }
    } else {
      console.error('No file selected');
      setKey(null);
    }
  };
  

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload File</button>
    </div>
  );
};

export default FileUploadComponent;
