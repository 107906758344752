import React, { useState, useEffect } from "react";
import AlumniApi from "../../utils/api/AlumniAPI";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { notify_error, notify_success } from "../Notify";
import CreateAlumni from "../CreateAlumni";
import { Link, useNavigate } from "react-router-dom";
import { Pagination, Badge } from "antd";
import BBAFilter from "./BBAFilter";
import { EnquiryContext } from "../../state/context/EnquiryProvider";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useRef } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const BbaAlumni = () => {
  const navigate = useNavigate();
  const [data, setDate] = useState([]);
  const [activepage, SetActivePage] = useState(1);
  const [recperpage, SetRecPerPage] = useState(25);
  const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  const [open, setOpen] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [search, SetSearch] = useState("");
  const filterPanelRef = useRef(null);

  const handleEnquiryOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note that months are zero-based, so we add 1
    const year = date.getFullYear().toString(); // Get the last two digits of the year
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  let timer;

  const handleSearchChange = (e) => {
    const { value } = e.target;
    SetSearch(value);

    clearTimeout(timer);

    timer = setTimeout(() => {
      if (value.trim() === "") {
        fetchAlumniData(); // You can define fetchData to handle empty search case
      } else {
        fetchDataWithSearch(value.trim());
      }
    }, 300);
  };

  const fetchDataWithSearch = async (searchTerm) => {
    try {
      // setLoading(true);

      const res = await AlumniApi.SearchAlumni(
        { search: searchTerm, program: "BBA" },
        activepage,
        recperpage
      );

      setDate(res.data.data);
      setTotalData(res.data.total);
      SetActivePage(1);

      // setLoading(false);
    } catch (error) {
      console.error("Error in handleSearch:", error);
      // setLoading(false);
    }
  };

  const fetchAlumniData = async (params) => {
    const apiParams = {
      page: params.page,
      limit: params.limit,
      program: "BBA",
    };

    await AlumniApi.getalumnins(apiParams)
      .then((res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        // console.log(res);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      if (search.trim() === "") {
        // If search is empty, reload the page
        await fetchAlumniData({ page: activepage, limit: recperpage });
      }
      AlumniApi.SearchAlumni(
        { search: search, program: "BBA" },
        activepage,
        recperpage
      ).then((res) => {
        setDate(res.data.data);
        setTotalData(res.data.total);
        SetActivePage(1);
        // console.log(res.data)
      });
    }
  };

  const handleRefresh = () => {
    SetSearch("");
    fetchAlumniData({ page: activepage, limit: recperpage });
  };

  useEffect(() => {
    fetchAlumniData({ page: activepage, limit: recperpage });
  }, [activepage, recperpage]);

  const handleFilterChange = async (filterParams) => {
    try {
      // console.log(filterParams);
      if (
        Object.keys(filterParams.params).length === 0 &&
        filterParams.params.constructor === Object
      ) {
        fetchAlumniData({ page: activepage, limit: recperpage });
      } else {
        const res = await AlumniApi.filterAlumni(
          filterParams,
          activepage,
          recperpage
        );
        setDate(res.data.data);
        setTotalData(res.data.total);
      }
    } catch (err) {
      notify_error(err.message || "Something Went Wrong");
    }
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  return (
    <>
      <Container fluid style={{ fontFamily: "Inter" }}>
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                lg={12}
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  <InputGroup
                    className="my-2"
                    style={{
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      border: "0.1px solid",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img src="/assets/images/dashboard/search.png"></img>
                    </div>
                    <Form.Control
                      placeholder="Search In BBA Alumni by Name, Email and Mobile Number"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      className=""
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none",
                        fontFamily: "Inter",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                      onKeyPress={handleSearch}
                    />
                  </InputGroup>
                </div>

                <div
                  className="p-0"
                  style={{
                    padding: "0px",
                    right: "0px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-end",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      height: "100% ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex gap-3" style={{ padding: "0" }}>
                      <button
                        onClick={handleEnquiryOpen}
                        className="px-4 my-2 py-2 "
                        style={{
                          backgroundColor: "white",
                          fontFamily: "Inter",
                          color: "#2B73FF",
                          borderColor: "#2B73FF",
                          borderRadius: "4px",
                        }}
                      >
                        <span>Add Alumni</span>
                      </button>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "2px",
                          height: "12/12",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                      <FilterAltOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={toggleFilterPanel}
                      />
                      <div
                        style={{
                          width: "2px",
                          height: "12/12",
                          backgroundColor: "#E5E9EB",
                        }}
                      ></div>
                    </div>

                    <div
                      className="d-flex gap-3 "
                      style={{
                        padding: "0",
                        marginLeft: "12px",
                        height: "100%",
                      }}
                    >
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        className="my-auto"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="maintable p-0 " id="table-container">
                {data && data.length > 0 ? (
                  <table className="col-md-12 enquiryTable">
                    <thead className="tableHeadAllEnquiry">
                      <tr className="tableHeading">
                        <th style={{ width: "5%" }}>S.No</th>
                        <th style={{ width: "20%" }}>Name</th>
                        <th style={{ width: "20%" }}>Email</th>
                        {/* <th>Location</th> */}
                        <th style={{ width: "15%" }}>Company</th>
                        <th style={{ width: "15%" }}>Designation</th>
                        {/* <th>Date of birth</th> */}
                        <th style={{ width: "15%" }}>Mobile Number</th>
                        <th style={{ width: "15%" }}>Batch</th>
                      </tr>
                    </thead>

                    <tbody style={{ backgroundColor: "#FFFFFF" }}>
                      {data &&
                        data.length > 0 &&
                        data.map((item, index) => (
                          <tr
                            className="alumniTableRows"
                            key={index}
                            style={{ height: "66px" }}
                          >
                            <td>
                              {!item.repeat && <span>{sno + index + 1}</span>}
                              {item.repeat && (
                                <span
                                  style={{
                                    display: "flex",
                                    // position:'relative',
                                    justifyContent: "space-around",
                                    alignItems: "",
                                  }}
                                >
                                  {sno + index + 1}
                                  <Badge.Ribbon
                                    style={{
                                      fontSize: "8px",
                                      right: "-20px",
                                      position: "absolute",
                                    }}
                                    color="#f50"
                                    placement="end"
                                    text="DUP"
                                  />
                                </span>
                              )}
                            </td>

                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.name}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.email_address}
                            </td>
                            {/* <td
                            onClick={() => {
                              // setActiveId(item._id);
                              window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                              // setStep(2);
                            }}
                          >
                            {item.city}
                          </td> */}

                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.company}
                            </td>
                            <td
                              style={{ textTransform: "capitalize" }}
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.designation}
                            </td>
                            {/* <td
                            onClick={() => {
                              // setActiveId(item._id);
                              window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                              // setStep(2);
                            }}
                          >
                            {item.dob}
                          </td> */}
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.mobile_number}
                            </td>
                            <td
                              onClick={() => {
                                // setActiveId(item._id);
                                window.open(
                                  `/dashboard/employeeHistory/${item._id}`,
                                  "_blank"
                                );
                                // setStep(2);
                              }}
                            >
                              {item.batch}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div
                    className="text-center my-4"
                    style={{ fontSize: "18px" }}
                  >
                    No data available
                  </div>
                )}
              </div>

              <div className="tableEnd"></div>
              <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label
                    className="control-label col-md-4"
                    style={{
                      fontWeight: "700",
                      paddingTop: "5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    onChange={(event) => SetRecPerPage(event.target.value)}
                    // onChange={(event) => applyFilter(event)}
                    value={recperpage}
                    style={{
                      border: "solid",
                      borderWidth: "thin",
                      color: "#000",
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div
                  className="col-md-8 d-flex gap-2"
                  style={{
                    justifyContent: "end",
                    alignItems: "center",
                    paddingRight: "60px",
                  }}
                >
                  <Pagination
                    total={totalData}
                    showSizeChanger={false}
                    current={activepage}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      SetActivePage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {open && <CreateAlumni open={open} course="BBA" setOpen={setOpen} />}

      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "76px",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "fit content",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 1s ease-in-out",
        }}
      >
        <BBAFilter
          isOpen={isFilterPanelOpen}
          handleFilterChange={handleFilterChange}
        />
      </div>
    </>
  );
};

export default BbaAlumni;
