import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';



const NsatAPI={
    getNsatDetails: (params) => {
        // let url = serverAddress + `/api/website/enquiry/exportData`;
        let url = serverAddress + `/nsat/get-all?page=${params?.page}&limit=${params?.limit}`;
    return axios.get(url, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
    });
},

SearchNsat : (params,page,limit)=>{

    let url = serverAddress + `/nsat/search?page=${page}&limit=${limit}`;
    return axios.post(url,params,{
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
    });
},

ExportData:()=>{
    let url = serverAddress + `/nsat/export/data`;
    return axios.get(url,{
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
    });

}
   
};

export default NsatAPI;