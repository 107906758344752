import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from "js-cookie";

const Feedbackipem = {
  //get all feedback

  //total Ad-enquiry
  getAllStudentFeedback: (params) => {
    let url =
      serverAddress +
      `/student_feedback/get-all?page=${params.page}&limit=${params.limit}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getOneStudentFeedback: (params) => {
    let url = serverAddress + `/student_feedback/get?id=${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getAllFacultyFeedback: (params) => {
    let url =
      serverAddress +
      `/faculty_feedback/get-all?page=${params.page}&limit=${params.limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getOneFacultyFeedback: (params) => {
    let url = serverAddress + `/faculty_feedback/get?id=${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getAllEmployerFeedback: (params) => {
    let url =
      serverAddress +
      `/employer_feedback/get-all?page=${params.page}&limit=${params.limit}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getOneEmployerFeedback: (params) => {
    let url = serverAddress + `/employer_feedback/get?id=${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getAllAlumniFeedback: (params) => {
    let url =
      serverAddress +
      `/alumni_feedback/get-all?page=${params.page}&limit=${params.limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getOneAlumniFeedback: (params) => {
    let url = serverAddress + `/alumni_feedback/get?id=${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  getOneParentFeedback: (params) => {
    let url = serverAddress + `/parent_feedback/get?id=${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  getOneStudentSatisfaction:(params)=>{
    let url = serverAddress + `/student_satisfaction/get?id=${params}`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  countTotatlEnquiry: () => {
    let url = serverAddress + `/website/enquiry/get/total/enquiry/popup`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //total Ad-enquiry
  countAdEnquiry: () => {
    let url = serverAddress + `/website/enquiry/get/total/enquiry/ad`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  //total popup enquiry
  popupEnquiry: () => {
    let url = serverAddress + `/website/enquiry/get/total/enquiry/popup`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
  //get total count of enquiry
  countTotatlEnquiry: () => {
    let url = serverAddress + `/website/enquiry/get/total/enquiry/popup`;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },

  ///dynamic filter ipem
  dynamicFilter: (params, page, limit) => {
    const x = JSON.stringify(params);
    const data = JSON.parse(x);

    let url;
    if (
      data.filter((obj) => {
        if (
          obj.field === "feedbackType" &&
          obj.selected.includes("student_feedback")
        ) {
          url =
            serverAddress +
            `/student_feedback/filter/dynamic?page=${page}&limit=${limit}`;
        }
        if (
          obj.field === "feedbackType" &&
          obj.selected.includes("faculty_feedback")
        ) {
          url =
            serverAddress +
            `/Faculty_feedback/filter/dynamic?page=${page}&limit=${limit}`;
        }
        if (
          obj.field === "feedbackType" &&
          obj.selected.includes("employer_feedback")
        ) {
          url =
            serverAddress +
            `/Employer_feedback/filter/dynamic?page=${page}&limit=${limit}`;
        }
        if (
          obj.field === "feedbackType" &&
          obj.selected.includes("alumni_feedback")
        ) {
          url =
            serverAddress +
            `/alumni_feedback/filter/dynamic?page=${page}&limit=${limit}`;
        }
        if (
          obj.field === "feedbackType" &&
          obj.selected.includes("parent_feedback")
        ) {
          url =
            serverAddress +
            `/parent_feedback/filter/dynamic?page=${page}&limit=${limit}`;
        }
        if (
          obj.field === "feedbackType" &&
          obj.selected.includes("student_satisfaction")
        ) {
          url =
            serverAddress +
            `/student_satisfaction/filter/dynamic?page=${page}&limit=${limit}`;
        }
      })
    )
      // console.log(params);
      return axios.post(url, params, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${cookie.get("ajwt")}`,
        },
      });
  },

  //download dynamic filter ipem
  ///dynamic filter ipem
  downloadDynamicFilter: (params, page, limit) => {
    let url =
      serverAddress +
      `/website/enquiry/filter/ExportDynamic?page=${page}&limit=${limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
      responseType: "blob",
    });
  },

  // search ipem Filter Integration
  searchQuery: (params, page, limit) => {
    let url =
      serverAddress + `/website/enquiry/search?page=${page}&limit=${limit}`;
    return axios.post(url, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    });
  },
};

export default Feedbackipem;
