import { createContext, useEffect, useState } from 'react';
import { notify_error } from '../../components/Notify';
import LawAPI from '../../utils/api/LawAPI';

export const LawContext =createContext();

export default function LawProvider({children}){
    const [activepage,SetActivePage]=useState(1);
    const [recperpage,SetRecPerPage]=useState(25);
    const [data, setDate] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [filter,setFilter]=useState([
        { field: "isPaymentDone", selected: [] },
    { field: "date", selected: { start: "", end: "" } }
]);
    const [search,SetSearch] = useState("");  

    const filterEmptySelected = (arr) => {
        return arr.filter((obj) => {
          if (obj.field === "date" && obj.selected && obj.selected.start !== "" && obj.selected.end !== "") {
            return true; // Retain the "date" object if start and end values are not empty
          }
          if (obj.field === "isPaymentDone" && (Array.isArray(obj.selected) && obj.selected.includes("all-enquiry"))) {
             obj.selected.length=0;
            return false; // Empty out the object if admissionStatus includes "all-enquiry"
          }
          const hasSelectedValues =
            obj.selected &&
            obj.selected.length > 0 &&
            (!Array.isArray(obj.selected) ||
              (Array.isArray(obj.selected) &&
                obj.selected.length > 0 &&
                obj.selected[0] !== null));
          return hasSelectedValues;
        });
      };


      const handleApplyFilter=()=>{  if(search){
        LawAPI.searchQuery({"search":search},activepage,recperpage).then((res)=>{
                       
            setDate(res.data.data);
            setTotalData(res.data.total);
            // console.log(res.data)
            return;
        })
    }else if(filterEmptySelected(filter).length>0){
        const newData=filterEmptySelected((filter));
        LawAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
            setDate(res.data.data);
            setTotalData(res.data.total);
            // console.log(totalData);
            return;
            
        })

    }else{
        LawAPI.getAllLaw({page:activepage,limit:recperpage}).then((result)=>{
            if(result.data.status){
                setDate(result.data.data);
                setTotalData(result.data.total);
                return;
            

            }else{
                setDate([]);
                setTotalData(0)
                notify_error("Something went wrong")
                return;
            }
        })
    }
        const newData=filterEmptySelected((filter));
        // console.log(newData);
    
        LawAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
          setDate(res.data.data);
          setTotalData(res.data.total);
        
        })
    
      }
useEffect(()=>{
    handlePageChange();
},[activepage])

      const handlePageChange=(page)=>{
        // SetActivePage(page);
        if(search){
            LawAPI.searchQuery({"search":search},activepage,recperpage).then((res)=>{
                           
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(res.data)
                return;
            })
        }else if(filterEmptySelected(filter).length>0){
            const newData=filterEmptySelected((filter));
            LawAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(totalData);
                return;
                
            })

        }else{
            LawAPI.getAllLaw({page:activepage,limit:recperpage}).then((result)=>{
                if(result.data.status){
                    setDate(result.data.data);
                    setTotalData(result.data.total);
                    return;
                

                }else{
                    setDate([]);
                    setTotalData(0)
                    notify_error("Something went wrong")
                    return;
                }
            })
        }
      }

      useEffect(()=>{
        applyFilter();
      },[recperpage])

      function applyFilter(){
        if(search){
            LawAPI.searchQuery({"search":search},activepage,recperpage).then((res)=>{
                           
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(res.data)
                return;
            })
        }else if(filterEmptySelected(filter).length>0){
            const newData=filterEmptySelected((filter));
            LawAPI.dynamicFilter(newData,activepage,recperpage).then((res)=>{
                setDate(res.data.data);
                setTotalData(res.data.total);
                // console.log(totalData);
                return;
                
            })

        }else{
            LawAPI.getAllLaw({page:activepage,limit:recperpage}).then((result)=>{
                if(result.data.status){
                    setDate(result.data.data);
                    setTotalData(result.data.total);
                    return;
                

                }else{
                    setDate([]);
                    setTotalData(0)
                    notify_error("Something went wrong")
                    return;
                }
            })
        }
      }


    const value={
        activepage,
        recperpage,
        data,
        SetActivePage,
        SetRecPerPage,
        setDate,
        totalData,
        setTotalData,
        filter,
        setFilter,
        search,
        SetSearch,
        handlePageChange,
        applyFilter
    }

    
    return(
        <LawContext.Provider value={value}>{children}</LawContext.Provider>
    )

}




