import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import {
  Container,
  Row,
  Col,
  Image,
  Badge,
  Modal,
  InputGroup,
  Form,
} from "react-bootstrap";

export default function ProgramChart(props) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [countInProcess, setCountInProcess] = useState(0);
  const [countAdmissionTaken, setCountAdmissionTaken] = useState(0);
  const [countNotInterested, setCountNotInterested] = useState(0);
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [program, setProgram] = useState("bba");
  //  const program=props.program || 'ba.llb';
  useEffect(() => {
    OnlineEnquiryAPI.dynamicFilter(
      [
        { field: "program", selected: [program] },
        { field: "admissionStatus", selected: ["in-process"] },
      ],
      1,
      25
    ).then((res) => {
      setCountInProcess(res.data.total);
      // console.log(res);
    });

    OnlineEnquiryAPI.dynamicFilter(
      [
        { field: "program", selected: [program] },
        { field: "admissionStatus", selected: ["not-interested"] },
      ],
      1,
      25
    ).then((res) => {
      setCountNotInterested(res.data.total);
      // // console.log(res);
    });

    OnlineEnquiryAPI.dynamicFilter(
      [
        { field: "program", selected: [program] },
        { field: "admissionStatus", selected: ["admission-taken"] },
      ],
      1,
      25
    ).then((res) => {
      setCountAdmissionTaken(res.data.total);
      // console.log(res);
    });
    OnlineEnquiryAPI.dynamicFilter(
      [{ field: "program", selected: [program] }],
      1,
      25
    ).then((res) => {
      setTotalEnquiry(res.data.total);
      // console.log(res);
    });
  }, [program]);

  useEffect(() => {
    const data = {
      labels: [
        "All Enquiry",
        "Admission Taken",
        "In Process",
        "Not Interested",
      ],
      datasets: [
        {
          label: ["Program Wise data"],
          data: [
            totalEnquiry,
            countAdmissionTaken,
            countInProcess,
            countNotInterested,
          ],
          backgroundColor: [
            "rgb(217,217,217)",
            "rgb(190,235,210)",
            "rgb(255,223,139)",
            "rgb(255,195,189)",
          ],
          borderColor: [
            "rgb(217,217,217)",
            "rgb(190,235,210)",
            "rgb(255,223,139)",
            "rgb(255,195,189)",
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [
    countAdmissionTaken,
    countAdmissionTaken,
    countNotInterested,
    totalEnquiry,
  ]);

  return (
    <>
      <Container
        className="mt-1 pt-3"
        style={{
          marginTop: "200px",
          backgroundColor: "white",
          paddingBottom: "19px",
          boxShadow: "0px 10px 10px 0px rgba(0, 31, 51, 0.10)",
        }}
      >
        <Row>
          <Col>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "28px",
                fontWeight: "500",
              }}
            >
              Admission Count
            </p>
            {/* <p>Course View</p> */}
          </Col>
          <Col>
            <Form.Select
              className="me-4"
              style={{ width: "200px", float: "right" }}
              name="program"
              onChange={(e) => setProgram(e.target.value)}
              value={program}
              aria-label="Default select example"
            >
              <option value="bba">BBA</option>
              <option value="bca">BCA</option>
              <option value="b.com(h)">B.COM</option>
              <option value="mba">MBA</option>
              <option value="b.ed">B.ED</option>
              <option value="mca">MCA</option>
              <option value="ba.llb">B.A.LLB</option>
              <option value="llb">LLB</option>
              <option value="b.com.llb">B.COM.LLB</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <div className="card  ">
              <Chart type="bar" data={chartData} options={chartOptions} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
