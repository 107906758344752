import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Modal,
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/index";
import PageHeader from "./PageHeader";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import { format } from "date-fns";
import { Badge, Button, Tooltip } from "antd";
import { notify_error, notify_success } from "./Notify";
import cookie from "js-cookie";
import { useSearchParams } from "react-router-dom";
import { parse } from "querystring";
// import "./OnlineEnquiryTable.css";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import { Pagination } from "antd";
// import { Container, Row, Col } from "react-bootstrap";
import { EnquiryContext } from "../state/context/EnquiryProvider";
import "./Table.css";
import { BorderColor } from "@mui/icons-material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CreateEnquiry from "./createEnquiry";
import UserAPI from "../utils/api/UserAPI";
import LeadProfileCollegeDekho from "./leadProfileCollegeDekho";

const CollegeDekhoTable = () => {
  const {
    filter,
    setFilter,
    search,
    SetSearch,
  
    
    filterEmptySelected,
    handleApplyFilter,
    step,
    setStep,
    activeData,
  } = React.useContext(EnquiryContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  // const sno = recperpage * (activepage - 1);
  const [loading, setLoading] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState([]);
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState("All Enquiry List");
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [totalOnlineEnquiry, setTotalOnlineEnquiry] = useState(0);
  const [totalAdOnlineEnquiry, setTotalAdOnlineEnquiry] = useState(0);
  const [lastMonthWalkInEnquiry, setLastMonthWalkInEnquiry] = useState(0);
  const [currentMonthWalkInEnquiry, setCurrentMonthWalkInEnquiry] = useState(0);
  const [currentMonthAdEnquiry, setCurrentMonthAdEnquiry] = useState(0);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [data, setDate] = useState([]);
  const [currentMonthOrganicEnquiry, setCurrentMonthOrganicEnquiry] =
    useState(0);
  const [lastMonthAdEnquiry, setLastMonthAdEnquiry] = useState(0);
  const [lastMonthOrganicEnquiry, setLastMonthOrganicEnquiry] = useState(0);
  const [isFilter, setIsFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [handlers, setHandlers] = useState([]);
  const [isFormValidate, setFormValidate] = useState(false);
  const [activeTransfer, setActiveTransfer] = useState(false);
  const [transferEnquiry, setTransferEnquiry] = useState(false);
  const [transferRole, setTransferRole] = useState("");
  const [activeId, setActiveId] = useState("");
  const [Index, setIndex] = useState(0);
  const [search1, setSearch1] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const sno = recperpage * (activepage - 1);
  const [enquiry, setEnquiry] = useState({
    program: "",
    name: "",
    email: "",
    mobile_number: "",
    form_location: "",
    enquiry_type: "",
    source: "",
    reference: "",
    state: "",
    city: "",
  });
  const [isTelephonic, setIsTelephonic] = useState(false);

  // Convert the searchParams object to a regular JavaScript object
  const queryObject = parse(searchParams.toString());

  useEffect(() => {
    top_loader(true, 45);
    getAllEnquiry({ page: activepage, limit: recperpage });

    console.log(data);

    // eslint-disable-next-line

    const handleTransfer = async () => {
      const params = { page: 1, limit: 100 };

      try {
        const result = await UserAPI.getAllUsers(params);
        // console.log("---------data--------", result);

        // Filter users based on "counsellor" or "admission" roles
        const filteredUsers = result.data?.data?.filter(
          (user) =>
            user.role.includes("counsellor") || user.role.includes("admission")
        );

        setHandlers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    handleTransfer();
    return () => {
      if (
        activeData?.filter &&
        activeData.activepage &&
        activeData.recperpage
      ) {
        // window.alert(activeData.activepage+'!!')

        sessionStorage.setItem("activeData", JSON.stringify(activeData));
      }
    };
  }, []);

  const getAllEnquiry = (params) => {
    setLoading(true);
    OnlineEnquiryAPI.getAllCollegeDekhoOnlineEnquiry(params).then((result) => {
      if (result.data.status) {
        console.log(result.data);
        setDate(result.data.data);
        setTotalData(result.data.total);

        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
        // // console.log(data);
      } else {
        setDate([]);
        setLoading(false);
        top_loader(true, 100);
        top_loader(false, 0);
      }
    });
  };

  function handlePageChange(page) {
    SetActivePage(page);
    getAllEnquiry({ page: page, limit: recperpage });
  }

  function applyFilter(event) {
    SetRecPerPage(event.target.value);
    getAllEnquiry({ page: 1, limit: event.target.value });
    SetActivePage(1);
  }

  const filterPanelRef = useRef(null);

  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const handleAssignment = async (event, _id) => {
    event.stopPropagation();
    event.preventDefault();

    const userRoles = cookie.get("role") ? cookie.get("role").split(",") : [];

    try {
      if (!userRoles.includes("counsellor")) {
        notify_error("Only Counsellors have access to walk-in Enquiries");
        return;
      }

      if (userRoles.includes("counsellor")) {
        const result = await EnquiryAPI.updateAssignment({
          _id: _id,
          username: cookie.get("name"),
        });

        // console.log(result);
        if (result.data.status) {
          notify_success(result.data.message);
          // getAllEnquiry({ page: activepage, limit: recperpage });
        } else {
          notify_error("Failed to update");
        }
      }
    } catch (err) {
      notify_error("Unauthorized Access. Only For Counsellors.");
    }
  };

  useEffect(() => {
    // console.log(step);
    // console.log(activepage, "active", recperpage);
  }, [step]);

  const fetchData = () => {
    setLoading(true);

    OnlineEnquiryAPI.getAllCollegeDekhoOnlineEnquiry({
      page: activepage,
      limit: recperpage,
    })
      .then((result) => {
        if (result.data.status) {
          setDate(result.data.data);
          setTotalData(result.data.total);
          SetActivePage(1);
        } else {
          setDate([]);
          setTotalData(0);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle errors if needed
        setLoading(false);
      });
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      const searchTerm = e.target.value.trim();

      console.log(searchTerm);
      if (searchTerm === "") {
        fetchData();
      } else {
        try {
          setLoading(true);

          const res = await OnlineEnquiryAPI.searchQueryCollegeDekho(
            { search: searchTerm },
            activepage,
            recperpage
          );

          setDate(res.data.data);
          setTotalData(res.data.total);
          SetActivePage(1);

          setLoading(false);
        } catch (error) {
          console.error("Error in handleSearch:", error);
          setLoading(false);
        }
      }
    }
  };

  let timer;

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch1(value);

    clearTimeout(timer);

    timer = setTimeout(() => {
      if (value.trim() === "") {
        fetchData(); // You can define fetchData to handle empty search case
      } else {
        fetchDataWithSearch(value.trim());
      }
    }, 300);
  };

  const fetchDataWithSearch = async (searchTerm) => {
    try {
      setLoading(true);

      const res = await OnlineEnquiryAPI.searchQueryCollegeDekho(
        { search: searchTerm },
        activepage,
        recperpage
      );

      setDate(res.data.data);
      setTotalData(res.data.total);
      SetActivePage(1);

      setLoading(false);
    } catch (error) {
      console.error("Error in handleSearch:", error);
      setLoading(false);
    }
  };
  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  function handleStatus(event, id) {
    const userRole = cookie.get("role");

    if (userRole === "College Dekho") {
      notify_error("You are not allowed to be assigned as handler.");
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    OnlineEnquiryAPI.updateStatus({
      _id: id,
      username: cookie.get("name"),
    }).then((result) => {
      if (result.data.status) {
        notify_success(result.data.message);
        getAllEnquiry({ page: activepage, limit: recperpage });
      } else {
        notify_error("Failed to update");
      }
    });
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleType = (form_location) => {
    if (transferRole == "") {
      setTransferRole(form_location);
      return true;
    } else if (transferRole != "walk-in") {
      if (form_location == "walk-in") {
        notify_error("Select only Walk-in or non Walk-in at a time");
        return false;
      }
    } else if (transferRole == "walk-in") {
      if (form_location != "walk-in") {
        notify_error("Select only Walk-in or non Walk-in at a time");
        return false;
      }
    }
    return true;
  };

  const handleReassign = async (param) => {
    let role;
    if (transferRole == "walk-in") {
      role = "counsellor";
      if (param.role != "counsellor") {
        notify_error("Only Counsellor can handle these enquiry");
        return;
      }
    } else if (transferRole != "walk-in") {
      role = "admission";
    }

    const params = {
      _id: selectedRows,
      type: "multiple",
      newAssigned: `${param.firstname} ${param.lastname}`,
      user: cookie?.get("name"),
      role: role,
    };

    OnlineEnquiryAPI.assignHandlerOne(params)
      .then((res) => {
        notify_success("Enquiry Successfully Updated");
        setActiveTransfer(false);
        getAllEnquiry({ page: activepage, limit: recperpage });
        // window.location.reload();
      })
      .catch((err) => {
        notify_error(err.message);
      });
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    // console.log(enquiry);
    if (enquiry.form_location === "telephonic") {
      setIsTelephonic(true);
    }
    if (enquiry.form_location === "walk-in") {
      setIsTelephonic(false);
    }
  }, [enquiry]);

  useEffect(() => {
    if (selectedRows?.length == 0) {
      setTransferRole("");
    }
  }, [selectedRows]);

  useEffect(async () => {
    let hand;

    if (transferRole == "walk-in") {
      const params = { page: 1, limit: 100 };

      await UserAPI.getAllUsers(params).then((result) => {
        hand = result.data?.data.filter((item) => item.role === "counsellor");
        setHandlers(hand);
      });
    } else if (transferRole != "walk-in" && transferRole !== "") {
      const params = { page: 1, limit: 100 };

      await UserAPI.getAllUsers(params).then((result) => {
        hand = result.data?.data.filter((item) => item.role === "admission");
        setHandlers(hand);
      });
    } else {
      // This else block is not needed for a useEffect function. useEffect doesn't return anything.
      // If you don't want to make any changes in the last case, you can simply omit the else block.
      // If you want to do something in the else case, just add it without a return statement.
    }
  }, [transferRole]);

  const handleRowSelection = (event, rowId, form_location) => {
    event.stopPropagation();
    event.preventDefault();

    if (handleType(form_location)) {
      if (selectedRows.includes(rowId)) {
        setSelectedRows(selectedRows.filter((id) => id !== rowId));
      } else {
        setSelectedRows([...selectedRows, rowId]);
      }
    } else {
      return;
    }

    // console.log(selectedRows)
  };

  const handleEnquiryOpen = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  return (
    <>
      {step == 1 && (
        <>
          <Container fluid className="" style={{ fontFamily: "Inter" }}>
            <Row className=" my-3">
              <Col lg={12}>
                <div className="" style={{ marginTop: "80px" }}>
                  <div
                    lg={12}
                    className="bg-white mx-0 my-3 rounded"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* Search button */}
                    <div style={{ marginLeft: "12px", width: "25%" }}>
                      <InputGroup
                        className="my-2"
                        style={{
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                          borderTopLeftRadius: "4px",
                          borderBottomLeftRadius: "4px",
                          border: "0.1px solid",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#F8F9FB",
                            paddingLeft: "11px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          className="d-flex justify-content-center align-items-center  "
                        >
                          <img src="/assets/images/dashboard/search.png"></img>
                        </div>
                        <Form.Control
                          placeholder="Search by Name , Email and Mobile Number"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          name="search"
                          className=""
                          style={{
                            backgroundColor: "#F8F9FB",
                            border: "none",
                            fontFamily: "Inter",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                          }}
                          onChange={handleSearchChange}
                          value={search1}
                          onKeyPress={handleSearch}
                        />
                      </InputGroup>
                    </div>

                    <div
                      className="p-0"
                      style={{
                        padding: "0px",
                        right: "0px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "flex-end",
                        marginRight: "12px",
                      }}
                    >
                      <div
                        style={{
                          height: "100% ",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <div className="d-flex gap-3" style={{ padding: "0" }}>
                          <button
                            onClick={handleEnquiryOpen}
                            className="px-4 my-2 py-2 "
                            style={{
                              backgroundColor: "white",
                              fontFamily: "Inter",
                              color: "#2B73FF",
                              borderColor: "#2B73FF",
                              borderRadius: "4px",
                            }}
                          >
                            <span>Add Enquiry</span>
                          </button>
                          <div
                            style={{
                              width: "1px",
                              height: "full",
                              backgroundColor: "#E5E9EB",
                            }}
                          ></div>
                        </div> */}

                        <div
                          className="d-flex gap-3 "
                          style={{ padding: "0", marginLeft: "12px" }}
                        >
                          {/* 
                      <button
                        className="px-4 my-2 py-2 "
                        style={{
                          backgroundColor: "white",
                          fontFamily: "Inter",
                          color: "#2B73FF",
                          borderColor: "#2B73FF",
                          borderRadius: "4px",
                        }}
                      >
                        Transfer
                      </button>
                    */}
                          {/* <Dropdown>
                            <Dropdown.Toggle
                              variant="info"
                              className="px-4 my-2"
                              size="sm"
                              style={{
                                backgroundColor: "white",
                                color: "#2B73FF",
                                borderColor: "#2B73FF",
                                border: "2px solid",
                                fontSize: "15px",
                                fontWeight: "lighter",
                                paddingBlock: "10px",
                                fontFamily: "Inter",
                              }}
                            >
                              Transfer To
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {handlers &&
                                handlers.map((user) => (
                                  <Dropdown.Item
                                    onClick={() => handleReassign(user)}
                                    key={user.id}
                                    className="transferTo"
                                    style={{
                                      backgroundColor: "#fffff",
                                      color: "#000",
                                    }}
                                  >
                                    {`${user.firstname
                                      .charAt(0)
                                      .toUpperCase()}${user.firstname.slice(
                                      1
                                    )} ${user.lastname}`}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown> */}

                          <div
                            style={{
                              width: "1px",
                              height: "full",
                              backgroundColor: "#E5E9EB",
                            }}
                          ></div>
                        </div>

                        {/* <div
                          className="d-flex gap-3 "
                          style={{
                            padding: "0",
                            marginLeft: "12px",
                            height: "100%",
                          }}
                        >
                          <FilterAltOutlinedIcon
                            fontSize="large"
                            color="disabled"
                            className="my-auto"
                            cursor="pointer"
                            onClick={toggleFilterPanel}
                          />
                          <div
                            style={{
                              width: "1px",
                              height: "12/12",
                              backgroundColor: "#E5E9EB",
                            }}
                          ></div>
                        </div> */}

                        <div
                          className="d-flex gap-3 "
                          style={{
                            padding: "0px",
                            marginLeft: "12px",
                            height: "100%",
                          }}
                        >
                          <RefreshOutlinedIcon
                            fontSize="large"
                            color="disabled"
                            className="my-auto"
                            cursor="pointer"
                            onClick={handleRefresh}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginBottom: "25px", height: "100%" }}>
                    <div className="maintable p-0 " id="table-container">
                      <table className="col-md-12 enquiryTable">
                        <thead className="tableHeadAllEnquiry">
                          <tr className="tableHeading">
                            <th>Select</th>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Program</th>
                            <th>Origin</th>
                            <th>Location</th>
                            <th>Status</th>
                            <th>Handler</th>
                            <th>Created At</th>
                          </tr>
                        </thead>

                        <tbody className="mt-1">
                          {data?.length > 0 &&
                            data?.map((item, index) => {
                              const rowId = item._id;
                              return (
                                <>
                                  <tr className="tableRows" key={item._id}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={selectedRows.includes(rowId)}
                                        onChange={(e) =>
                                          handleRowSelection(
                                            e,
                                            rowId,
                                            item.form_location
                                          )
                                        }
                                      />
                                    </td>

                                    <td
                                      onClick={() => {
                                        setIndex(index);
                                        localStorage.setItem("index", index);
                                        window.open(
                                          `/dashboard/college-dekho/lead-profile/${item._id}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      {!item.repeat && (
                                        <span>{sno + index + 1}</span>
                                      )}
                                      {item.repeat && (
                                        <span
                                          style={{
                                            display: "flex",
                                            // position:'relative',
                                            justifyContent: "space-around",
                                            alignItems: "",
                                          }}
                                        >
                                          {sno + index + 1}
                                          <Badge.Ribbon
                                            style={{
                                              fontSize: "8px",
                                              right: "-20px",
                                              position: "absolute",
                                            }}
                                            color="#f50"
                                            placement="end"
                                            text="DUP"
                                          />
                                        </span>
                                      )}
                                    </td>

                                    <td
                                      onClick={() => {
                                        // setActiveId(item._id);
                                        setIndex(index);
                                        localStorage.setItem("index", index);
                                        window.open(
                                          `/dashboard/college-dekho/lead-profile/${item._id}`,
                                          "_blank"
                                        );
                                        // setStep(2);
                                      }}
                                    >
                                      {capitalizeWords(item.name)}
                                    </td>

                                    <td
                                      onClick={() => {
                                        setIndex(index);
                                        localStorage.setItem("index", index);
                                        // setActiveId(item._id);
                                        window.open(
                                          `/dashboard/college-dekho/lead-profile/${item._id}`,
                                          "_blank"
                                        );

                                        // setStep(2);
                                      }}
                                    >
                                      {item.mobile_number}
                                    </td>

                                    <td
                                      onClick={() => {
                                        setIndex(index);
                                        localStorage.setItem("index", index);
                                        // setActiveId(item._id);
                                        window.open(
                                          `/dashboard/college-dekho/lead-profile/${item._id}`,
                                          "_blank"
                                        );

                                        // setStep(2);
                                      }}
                                    >
                                      {item?.program?.toUpperCase()}
                                    </td>

                                    <td
                                      onClick={() => {
                                        // setActiveId(item._id);
                                        setIndex(index);
                                        localStorage.setItem("index", index);
                                        window.open(
                                          `/dashboard/college-dekho/lead-profile/${item._id}`,
                                          "_blank"
                                        );

                                        // setStep(2);
                                      }}
                                    >
                                      {item?.form_location?.toUpperCase()}
                                    </td>

                                    <td
                                      onClick={() => {
                                        // setActiveId(item._id);
                                        setIndex(index);
                                        localStorage.setItem("index", index);
                                        window.open(
                                          `/dashboard/college-dekho/lead-profile/${item._id}`,
                                          "_blank"
                                        );

                                        // setStep(2);
                                      }}
                                    >
                                      {item.state
                                        ? `${item?.state} ${
                                            item?.city || ""
                                          }`?.toUpperCase()
                                        : "N/A"}
                                    </td>

                                    {!item.admissionStatus && (
                                      <td
                                        className="border-0 "
                                        onClick={() =>
                                          window.open(
                                            `/dashboard/college-dekho/lead-profile/${item._id}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        N/A
                                      </td>
                                    )}
                                    {item.admissionStatus == "in-process" && (
                                      <td
                                        className=""
                                        onClick={() =>
                                          window.open(
                                            `/dashboard/college-dekho/lead-profile/${item._id}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <span
                                          className=""
                                          style={{
                                            color: "#FFB800",
                                            border: "none",
                                            backgroundColor: "#FFEDC0",
                                            zIndex: "1000",
                                            paddingTop: "3px",
                                            paddingBottom: "3px",
                                            paddingLeft: "13px",
                                            paddingRight: "13px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          {item?.admissionStatus
                                            ? item?.admissionStatus?.toUpperCase()
                                            : "N/A"}
                                        </span>
                                      </td>
                                    )}
                                    {item.admissionStatus ==
                                      "admission-taken" && (
                                      <td
                                        className=""
                                        onClick={() =>
                                          window.open(
                                            `/dashboard/college-dekho/lead-profile/${item._id}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <span
                                          className=""
                                          style={{
                                            color: "#46CF85",
                                            border: "none",
                                            backgroundColor: "#CAEEDB",
                                            paddingTop: "3px",
                                            paddingBottom: "3px",
                                            paddingLeft: "13px",
                                            paddingRight: "13px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          {item?.admissionStatus
                                            ? item?.admissionStatus?.toUpperCase()
                                            : "N/A"}
                                        </span>
                                      </td>
                                    )}
                                    {item.admissionStatus ==
                                      "not-interested" && (
                                      <td
                                        className=""
                                        onClick={() =>
                                          window.open(
                                            `/dashboard/college-dekho/lead-profile/${item._id}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <span
                                          className=" "
                                          style={{
                                            color: "#F7685B",
                                            border: "none",
                                            backgroundColor: "#FFE3E0",
                                            paddingTop: "3px",
                                            paddingBottom: "3px",
                                            paddingLeft: "13px",
                                            paddingRight: "13px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          {item?.admissionStatus
                                            ? item?.admissionStatus?.toUpperCase()
                                            : "N/A"}
                                        </span>
                                      </td>
                                    )}

                                    {item.admissionStatus == "not-eligible" && (
                                      <td
                                        className=""
                                        onClick={() =>
                                          window.open(
                                            `/dashboard/college-dekho/lead-profile/${item._id}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <span
                                          className=" "
                                          style={{
                                            color: "#F7685B",
                                            border: "none",
                                            backgroundColor: "#FFE3E0",
                                            paddingTop: "3px",
                                            paddingBottom: "3px",
                                            paddingLeft: "13px",
                                            paddingRight: "13px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          {item?.admissionStatus
                                            ? item?.admissionStatus?.toUpperCase()
                                            : "N/A"}
                                        </span>
                                      </td>
                                    )}
                                    {/* style={{
                              
                              backgroundColor: item?.admissionStatus === "Not interested"? "blue" :
                               item?.admissionStatus === "in-process"? "green" :
                               item?.admissionStatus === "admission-taken"? "red" :""
                            }} */}

                                    <td>
                                      {item.form_location === "walk-in" &&
                                        !item.assignedTo && (
                                          <>
                                            <a
                                              className="bg-transparent border-0"
                                              style={{
                                                color: "#2B73FF",
                                                fontWeight: "600",
                                                borderBottom:
                                                  "1px solid #2B73FF",
                                              }}
                                              onClick={(e) =>
                                                handleAssignment(e, item._id)
                                              }
                                            >
                                              Assign To Me
                                            </a>
                                          </>
                                        )}
                                      {(item.form_location ===
                                        "website-pop-up" ||
                                        item.form_location === "ad-page" ||
                                        item.form_location === "online-query" ||
                                        item.form_location === "telephonic" ||
                                        item.form_location ===
                                          "get-admission-walk-in" ||
                                        item.form_location ===
                                          "college-dekho-walk-in" ||
                                        item.form_location ===
                                          "other-consultant-walk-in" ||
                                        item.form_location ===
                                          "college-dekho") &&
                                        !item.handled_by && (
                                          <>
                                            <a
                                              className="bg-transparent border-0"
                                              style={{
                                                color: "#2B73FF",
                                                fontWeight: "600",
                                                borderBottom:
                                                  "1px solid #2B73FF",
                                              }}
                                              onClick={(e) =>
                                                handleStatus(e, item._id)
                                              }
                                            >
                                              Assign To Me
                                            </a>
                                          </>
                                        )}
                                      {item.form_location === "walk-in" &&
                                        item.assignedTo &&
                                        item.assignedTo.toUpperCase()}
                                      {(item.form_location ===
                                        "website-pop-up" ||
                                        item.form_location === "online-query" ||
                                        item.form_location === "telephonic" ||
                                        item.form_location === "ad-page" ||
                                        item.form_location ===
                                          "get-admission-walk-in" ||
                                        item.form_location ===
                                          "college-dekho-walk-in" ||
                                        item.form_location ===
                                          "other-consultant-walk-in" ||
                                        item.form_location ===
                                          "college-dekho") &&
                                        item.handled_by &&
                                        item.handled_by.toUpperCase()}
                                    </td>
                                    <td>
                                      {new Intl.DateTimeFormat("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      }).format(new Date(item.created_at))}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          {data.length == "0" && (
                            <>
                              <div className="d-flex align-items-center justify-content-center">
                                <h1 className="text-muted">NO DATA</h1>
                              </div>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="tableEnd"></div> */}

                    <div className="col-md-12 form-inline py-2 tableFooter">
                      <div className="row pageSet">
                        <div className="col-md-4 d-flex align-baseline gap-0">
                          <label
                            className="control-label col-md-4"
                            style={{
                              fontWeight: "700",
                              paddingTop: "5px",
                              paddingLeft: "25px",
                            }}
                          >
                            Rows per page :
                          </label>
                          {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/>                        */}
                          <Form.Select
                            className="form-control "
                            onChange={(event) => applyFilter(event)}
                            value={recperpage}
                            // onChange={(event) => applyFilter(event)}
                            
                            style={{
                              border: "solid",
                              borderWidth: "thin",
                              color: "#000",
                            }}
                          >
                            {/* <option value="5">5</option> */}
                            <option value="10" style={{}}>
                              10
                            </option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                          </Form.Select>
                        </div>

                        <div className="col-md-7 text-end">
                          <Pagination
                            total={totalData}
                            showSizeChanger={false}
                            current={activepage}
                            size="small"
                            // showQuickJumper
                            pageSize={recperpage}
                            onChange={(page, pageSize) => {
                              handlePageChange(page);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div
            ref={filterPanelRef}
            className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
            id="handler-container"
            // Style the slide-out panel as per your requirement
            style={{
              position: "fixed",
              top: "0",
              right: `${isFilterPanelOpen ? "0" : "-100%"}`,
              width: "430px",
              height: "100vh",
              overflowY: "auto",
              background: "#fff",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              transition: "right 0.3s ease-in-out",
            }}
          >
            {/* <FilterCollegeDekhoEnquiry /> */}
          </div>
          {open && <CreateEnquiry open={open} setOpen={setOpen} />}
        </>
      )}

      {step == 2 && (
        <>
          <LeadProfileCollegeDekho
            setStep={setStep}
            activepage={activepage}
            recperpage={recperpage}
            index={Index}
            id={activeId}
          />
        </>
      )}
    </>
  );
};

export default CollegeDekhoTable;
