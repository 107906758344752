// import React, { useEffect, useState } from "react";
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import { useParams } from "react-router-dom";
import cookie from "js-cookie";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DangerousIcon from '@mui/icons-material/Dangerous';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useEffect } from 'react';
import { useState } from 'react';
import EnquiryAPI from '../utils/api/EnquiryAPI';
import { notify_error, notify_success } from './Notify';
import { EnquiryContext } from '../state/context/EnquiryProvider';
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784AF4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784AF4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EAEAF0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#EAEAF0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784AF4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784AF4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));
function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}
QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(180,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(180,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EAEAF0',
    borderRadius: 1,
  },
}));
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));


function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1:<DangerousIcon />,
    2: <NotInterestedIcon />,
    3: <GroupAddIcon />,
    4: <VideoLabelIcon />,
  };
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};
const steps = ['Not Eligible','Not Interested', 'In Process', 'Admission Taken'];
export default function CustomizedSteppers(props) {
 
  const { id } = useParams();
  const [data, setData] = useState({});
  const [step, setStep] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [userRole, setUserRole] = useState("");

  const handleAdmissionStatus = (status) => {
    if (!initialRender && step > -1 && step <= 2 && step !== null) {
      EnquiryAPI.updateAdmissionStatus({ _id: data._id, status })
        .then((res) => {
          if (res.status) {
            notify_success('Admission status Updated');
          } else {
            notify_error("Admission Status could not be updated");
          }
        })
        .catch((err) => {
          notify_error(err.message);
        });
    }
  };

  useEffect(() => {
    const role = cookie.get("role");  // Assuming the role is stored in a cookie named "role"
    setUserRole(role);
  }, []);

  const handleStepChange = (newStep) => {

    if (userRole === 'College Dekho') {
      notify_error('You are not allowed to change the status');
      return;
    }
    
    setStep(newStep);

    let status = "";
    switch (newStep) {
      case 0:
        status = 'not-eligible';
        break;
      case 1:
        status = 'not-interested';
        break;
      case 2:
        status = 'in-process';
        break;
      case 3:
        status = 'admission-taken';
        break;
      default:
        break;
    }

    handleAdmissionStatus(status);
  };

  const fetchEnquiryData = () => {
    EnquiryAPI.getOneEnquiry(id)
      .then((res) => {
        setData(res.data.data[0]);
      })
      .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
  };

  useEffect(() => {
    fetchEnquiryData();
  }, [id]);

  useEffect(() => {
    if (data.admissionStatus) {
      switch (data.admissionStatus) {
        case 'not-eligible':
          setStep(0);
          break;
        case 'not-interested':
          setStep(1);
          break;
        case 'in-process':
          setStep(2);
          break;
        case 'admission-taken':
          setStep(3);
          break;
        default:
          setStep(-1);
      }
    } else {
      setStep(-1);
    }
  }, [data.admissionStatus]);

  useEffect(() => {
    setInitialRender(false);
  }, []);

  return (
    <>
      {data && data._id && (
        <div className='ProgressBar mx-3 '>
          <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
              {steps.map((label, index) => (
                <Step key={label} onClick={() => handleStepChange(index)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </div>
      )}
    </>
  );
}