import axios from "axios";
import { serverAddress } from "../../state/constants/content_types";
import cookie from 'js-cookie';
const IcdsAPI = {

    getAllIcds: (params) => {
        // let url = serverAddress + `/api/conference/get?page=${params?.page}&limit=${params?.limit}`;
        let url = serverAddress + `/conference/get?page=${params?.page}&limit=${params?.limit}`;

        return axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },

        //confirm payment status ICDS
        confirmPaymentICDS:(params)=>{
            // let url= `${serverAddress}/api/conference/success/?_id=${params}`;
            let url= `${serverAddress}/conference/success/?_id=${params}`;
            return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                }
            );
        },

        sendLinkIcds: (params) => {
            // let url = serverAddress + `/api/conference/get/${params}`;
            let url = serverAddress + `/conference/get/${params}`;
            return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                }
            );
        },

            //updateTransactionID
    updateTransactionId:(id,transactionId)=>{
        // let url= `${serverAddress}/api/conference/transaction?_id=${id}&transactionId=${transactionId}`;
        let url= `${serverAddress}/conference/transaction?_id=${id}&transactionId=${transactionId}`;

        return axios.post(url,
            {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    Authorization: `Bearer ${cookie.get('ajwt')}`
                },
            }
        );
    },


    approveAbstract:(id,email)=>{
        let url= `${serverAddress}/conference/status?_id=${id}&status=1&email=${email}`
        return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                })
    },
      rejectAbstract:(id,email)=>{
        let url= `${serverAddress}/conference/status?_id=${id}&status=2&email=${email}`
        return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                })
    },

    paymentEmailIcds:(id,email)=>{
        let url= `${serverAddress}/conference/status?_id=${id}&paymentstatus=1&email=${email}`
        return axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        Authorization: `Bearer ${cookie.get('ajwt')}`
                    },
                })
    },
    
    //conference dynamic filter
    dynamicFilter:(params,page,limit)=>{
        // let url=serverAddress+`/api/conference/dynamicFilters?page=${page}&limit=${limit}`;
        let url=serverAddress+`/conference/filter/dynamic?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
          },
        })
      },

      downloadDynamicFilter:(params,page,limit)=>{
        // let url=serverAddress+`/api/conference/ExportDynamic?page=${page}&limit=${limit}`;
        let url=serverAddress+`/conference/ExportDynamic?page=${page}&limit=${limit}`;
        return axios.post(url,params,{
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${cookie.get("ajwt")}`,
          },
        })
      },



// search in conference conference

searchQuery: (params,page,limit)=>{
    // let url=serverAddress+`/api/conference/search?page=${page}&limit=${limit}`;
    let url=serverAddress+`/conference/search?page=${page}&limit=${limit}`;
    return axios.post(url,params,{
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${cookie.get("ajwt")}`,
      },
    })
  },


    /* Forget Password */
};

export default IcdsAPI;