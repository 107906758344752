import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UllasStudentInfo from "./UllasStudentInfo";
import UllasStudentProfile from "./UllasStudentprofile";
import { notify_error, notify_success } from "../Notify";
import cookie from "js-cookie";
import UllasAPI from "../../utils/api/UllasAPI";

const LeadProfileUllas = () => {

    const { id } = useParams();
  const [data, setData] = useState({});


useEffect(()=>{
  console.log('hi');
},[])


  useEffect(() => {
    console.log(id);
    UllasAPI.getOneEnquiry(id).then((res) => {
      // console.log(res);
        setData(res.data.data);
        
      })
     .catch((err) => {
        notify_error(err.message || "Something Went Wrong");
      });
    // console.log(id);
  }, []);

  console.log(data);

    return(

        <div className="container-fluid page-body-wrapper" style={{fontFamily:"Inter"}}>
            <NavBar/>
            <UllasStudentProfile data={data}/>
            <UllasStudentInfo data={data}/>
        </div>

    )
}

export default LeadProfileUllas;